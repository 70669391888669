import React from "react";
import { useNavigate } from "react-router";

import { Avatar } from "@mui/material";
import { isEmpty, noop } from "lodash";

import { useAppState } from "../../context";
import { createFreshChatPreview } from "../../utils/common";
import { addCustomEllipsis, toastify } from "../../helper/helper";

import InitialAvatar from "../InitialAvatar/InitialAvatar";

import RecommendedNetworkCardStyles from "./RecommendedNetworkCardStyles";

const MAX_CHAR_MAJOR = 25;
const MAX_CHAR_NAME = 27;
const MAX_CHAR_NAME_WITH_TAG = 22;

const RecommendedNetworkCard = ({
  networkPeopleList = [],
  selectedCountry = { label: "United States", value: "us" },
}) => {
  const navigate = useNavigate();
  const {
    getChatContactListItem = noop,
    getChatPreviewByReceiverId = noop,
    chats = null,
    setChats = noop,
    setConversationInformation = noop,
    setCurrentConversationId = noop,
    createFreshChatRecord = noop,
  } = useAppState("chat");
  const { discoverNetworks = [] } = useAppState("network");

  const startChatWithNewUser = (item) => {
    try {
      const { id } = item;
      const user = getChatContactListItem(id, discoverNetworks);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };
  return (
    <RecommendedNetworkCardStyles>
      {networkPeopleList.length > 0 && (
        <div className="recommended-wrapper">
          <div className="recommended-network-dialog">
            <div className="recommended-header">
              <div className="main-title">
                Connect with your network from{" "}
                {selectedCountry ? selectedCountry.label : ""}
              </div>
            </div>
            {networkPeopleList.slice(0, 3).map((network, index) => (
              <div className="recommended-card-wrapper" key={index}>
                <div className="recommended-card row d-flex">
                  <div className="col-md-4">
                    {!network.user_profile || isEmpty(network.user_profile) ? (
                      <InitialAvatar title={network.full_name} index={index} />
                    ) : (
                      <Avatar alt="User" src={network.user_profile} />
                    )}
                  </div>
                  <div className="col-md-8">
                    <div className="user-title-container">
                      <div className={"user-title"}>
                        {addCustomEllipsis(
                          network.full_name || "",
                          network?.user_types?.includes("Alumni")
                            ? MAX_CHAR_NAME_WITH_TAG
                            : MAX_CHAR_NAME
                        )}
                      </div>
                      {network?.user_types?.includes("Alumni") ? (
                        <span className="tag-item cursor-pointer">Alumni</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row d-flex user-sub-title">
                      {addCustomEllipsis(network?.major || "", MAX_CHAR_MAJOR)}
                      {network.major && network.graduation_year && (
                        <span className="left-right-space-span">|</span>
                      )}
                      {network.graduation_year}
                    </div>
                    <div className="action-container">
                      <a
                        href="/#"
                        className="nlink-btn nbg-secondary"
                        aria-label={`Message ${network?.full_name}`}
                        onClick={(e) => {
                          e.preventDefault();
                          startChatWithNewUser(network);
                        }}
                      >
                        Message
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div style={{ marginTop: "20px" }}>
              <a
                href="/#"
                className="nlink-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/network/discover?country=${selectedCountry.value}`
                  );
                }}
                keepLabelCase={true}
              >
                Recommended connections
              </a>
            </div>
          </div>
        </div>
      )}
    </RecommendedNetworkCardStyles>
  );
};
export default RecommendedNetworkCard;
