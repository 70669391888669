import React, { useState, useEffect } from "react";
import { map, isNull, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { InfoPanel } from "../../InfoPanel/InfoPanel";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { routes } from "../../../routes";
import NotificationDialogStyles from "./NotificationDialogStyles";
import { Mixpanel } from "../../../mixpanel";
import { useAppState } from "../../../context";
import { notificationsListAPI } from "../../../services/notificationServices";
import { getNotificationConfig } from "../../../helper/helper";

import fArrowIcon from "../../../assets/svg/f-arrow.svg";
import NoNotifications from "../../../assets/svg/f-NoNotifications.svg";
import settingsIcon from "../../../assets/svg/notificationSettings.svg";

const NotificationDialog = ({
  notificationDialogOpen,
  setNotificationDialogOpen,
  readApiCall,
}) => {
  const navigate = useNavigate();
  const [displayNotifications, setDisplayNotifications] = useState([]);

  const displayNotificationsCount = 5; // Denotes the numbers of notifications to show

  const {
    past_notifications,
    new_notifications,
    unread_notifications,
    setNotifications,
  } = useAppState("notifications");

  // When notification dialog opens, call notification list API and update state
  useEffect(() => {
    if (!notificationDialogOpen) return;

    // Call notification list API and update app state
    (async () => {
      try {
        const response = await notificationsListAPI();
        if (response.success) {
          setNotifications(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationDialogOpen]);

  // Controls how many notifications to display
  useEffect(() => {
    if (unread_notifications?.length > displayNotificationsCount) {
      setDisplayNotifications(
        unread_notifications.slice(0, displayNotificationsCount)
      );
    } else {
      setDisplayNotifications(unread_notifications);
    }
  }, [unread_notifications]);

  const extractExtraPayload = (type, payloadId, options = {}) => {
    const payload = {
      notificationId: payloadId,
      ...options,
    };
    const allowed = [
      "webinar",
      "user_post",
      "deal",
      "video",
      "followed_topics",
      "created_topics",
      "appointment",
      "network_messages",
      "employer_job",
      "featured_job",
    ];
    if (allowed.includes(type)) {
      return payload;
    }
    return {};
  };

  const handleNotificationViewClick = (item, onlyMarkasRead = false) => {
    // Close Notification Dialog
    !onlyMarkasRead && setNotificationDialogOpen(false);

    let payload = null;

    if (item?.type === "followed_topics" || item?.type === "created_topics") {
      // Custom payload for followed_topics notifications
      payload = extractExtraPayload(item.type, item.type_id, {
        topicId: item.topic_id,
      });
    } else {
      payload = extractExtraPayload(item.type, item.type_id);
    }

    if (getNotificationConfig(item.type).url) {
      onlyMarkasRead
        ? readApiCall(item.id)
        : readApiCall(item.id, getNotificationConfig(item.type, payload).url);
    } else {
      Bugsnag.leaveBreadcrumb(
        "Unhandled notification type click",
        {
          type: item.type,
        },
        "error"
      );
      readApiCall(item.id, routes.NOTIFICATION.DASHBOARD);
    }

    Mixpanel.track("View Notification Clicked", {
      Type: item.type,
    });
  };

  const handleViewAllClick = () => {
    // Close Notification Dialog
    setNotificationDialogOpen(false);
    // Navigate to notification dashboard
    navigate(routes.NOTIFICATION.DASHBOARD);
    // Mixpannel Update
    Mixpanel.track("View All Notification Button Clicked");
  };

  return (
    <NotificationDialogStyles>
      <span
        className={`notification-overlay ${
          notificationDialogOpen ? "notification-overlay-open" : "d-none"
        }`}
        onClick={(e) => {
          e.preventDefault();
          setNotificationDialogOpen(false);
        }}
      ></span>
      <div
        className={`notifications-drawer ${
          notificationDialogOpen ? "notifications-drawer-open" : ""
        }`}
      >
        <div className="notification-dialog-header mb-15">
          <div className="notification-dialog-title-view-all-wrapper">
            <h1 className="title-primary">New notifications</h1>
          </div>
          <div className="notification-dialog-setting-close-icon-wrapper">
            <a
              href="/#"
              onClick={(e) => e.preventDefault()}
              className={
                notificationDialogOpen ? "notification-setting" : "d-none"
              }
              {...(notificationDialogOpen ? {} : { "aria-hidden": true })}
              onKeyDown={(e) => {
                if (e?.key === "Enter") {
                  navigate(routes.NOTIFICATION.SETTINGS);
                }
              }}
            >
              <img
                src={settingsIcon}
                height={22}
                width={22}
                className="cursor-pointer"
                onClick={() => navigate(routes.NOTIFICATION.SETTINGS)}
                alt="Notification settings  "
              />
            </a>
            {new_notifications?.length === 0 &&
            past_notifications?.length === 0 &&
            unread_notifications?.length === 0 ? (
              <></>
            ) : (
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewAllClick();
                }}
                className={
                  notificationDialogOpen ? "nlink-secondary" : "d-none"
                }
                {...(notificationDialogOpen ? {} : { "aria-hidden": true })}
              >
                View all
              </a>
            )}
          </div>
        </div>
        {displayNotifications.length > 0 ? (
          map(displayNotifications, (item, index) => {
            const notificationData = getNotificationConfig(item.type);
            return (
              <div
                className="notification-dialog-info-panel-custom"
                key={index}
              >
                <InfoPanel
                  type={notificationData?.category}
                  notiPopUp={true}
                  marginTop="0px"
                  marginBottom="20px"
                  displayStar={false}
                  avatar={notificationData?.icon}
                  avatarBackground={true}
                  avatarHeight="30px"
                  avatarWidth="30px"
                  tabIndex={0}
                  onPanelClick={() => handleNotificationViewClick(item)}
                  title={
                    isEmpty(item.message) || isNull(item.message)
                      ? item.title
                      : item.message
                  }
                  subTitle={[moment(item.created_at).fromNow()]}
                  action={
                    <>
                      {item.type !== "common" && (
                        <ButtonCustom
                          className="arrow-btn"
                          height={32}
                          width={30}
                          isDisabled={notificationDialogOpen ? false : true}
                        >
                          <img
                            className="ml-auto"
                            src={fArrowIcon}
                            alt="Arrow Icon"
                          />
                        </ButtonCustom>
                      )}
                    </>
                  }
                  ariaHidden={notificationDialogOpen ? false : true}
                />
              </div>
            );
          })
        ) : (
          <div className="no-notifications">
            <img src={NoNotifications} alt="No notifications" />
            You’re all caught up!
          </div>
        )}
        {unread_notifications &&
          unread_notifications.length > displayNotificationsCount && (
            <div className="notification-dialog-see-all mt-15">
              <span
                onClick={() => {
                  setNotificationDialogOpen(false);
                  navigate(routes.NOTIFICATION.DASHBOARD);
                  Mixpanel.track("View All Notification Button Clicked");
                }}
                className="nlink-secondary"
              >
                {unread_notifications.length - displayNotifications.length} more
              </span>
            </div>
          )}
      </div>
    </NotificationDialogStyles>
  );
};

export default NotificationDialog;
