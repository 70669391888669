import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .student-profile-card {
    width: 100%;
    display: flex;
    justify-content: center;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 380px;
      width: 100%;
      > div {
        + div {
          margin-top: 10px;
        }
      }
    }

    &__profile_wrapper {
      width: 70px;
      height: 70px;
      margin-right: 0;
      border-radius: 100px;
      overflow: hidden;
      div {
        width: 100% !important;
        height: 100% !important;
      }
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        border-radius: 0;
      }
      .initial-avatar {
        padding-top: 10px;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        font-size: 32px;
      }
    }

    &__name_wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        margin: 0;
        color: var(--primary-dark-color);
        font-size: 18px;
        font-weight: 500;
        line-height: 15px;
        text-align: center;
        margin-bottom: 0;
        font-family: "TTCommons-Medium";
        padding-top: 7px;
      }
    }
    &__pronouns {
      margin: 3px 0 0 0;
      color: var(--gray-text-color);
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      font-family: "TTCommons-Regular";
    }
    &__school_name {
      font-size: 15px;
      margin: 5px 0 0;
    }

    &__tags_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 6px;
      margin-top: 5px !important;
    }
    &__tag {
      margin: 0;
      background-color: #f1f4f7;
      color: #000c3d !important;
      height: 25px;
      border-radius: 12.5px;
      font-family: "TTCommons-Medium";
      font-size: 15px;
      line-height: 29px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0;
      padding-inline: 7.5px;
      display: block;
      text-transform: capitalize;
      &--user {
        color: var(--primary-dark-color) !important;
        background: #d7ddf0 !important;
      }
      &--admissions {
        background: rgba(247, 100, 95, 0.17) !important;
        color: #a80c06 !important;
      }
    }

    &__education_wrapper {
      max-width: 250px;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${PRIMARY_COLOR};
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        margin-bottom: 0;
        font-family: "TTCommons-Medium";
        > img {
          margin: -6px 8px 0 0;
        }
      }
    }

    &__qualification_wrapper {
      p {
        margin-top: 0;
        margin-bottom: 0px;
        color: var(--primary-dark-color);
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        font-family: "TTCommons-Medium";
      }
    }

    &__topic-intro-line {
      p {
        margin-top: 0;
        margin-bottom: 0px;
        color: var(--primary-dark-color);
        font-size: 15px;
        line-height: 17px;
        text-align: center;
        font-family: "TTCommons-Regular";
      }
    }

    &__location_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        img {
          margin: -4px 8px 0px 0;
          height: 15px;
          border-radius: 2px;
        }
        p {
          font-family: "TTCommons-Regular";
          color: var(--primary-dark-color);
          font-size: 15px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          margin: 0;
        }
      }
    }

    &__bio_wrapper {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          color: var(--primary-dark-color);
          font-size: 15px;
          line-height: 17px;
          text-align: center;
          margin: 0;
          font-family: "TTCommons-Regular";
        }
        span {
          font-size: 15px;
          line-height: 13px;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          text-decoration: none;
          border-bottom: 1px solid var(--primary-dark-color);
          cursor: pointer;
          padding-top: 3px;
          transition: all 0.3s;
        }
      }
    }

    &__linkedin_wrapper {
      a {
        cursor: pointer;
        color: var(--secondary-color);
        text-decoration: underline;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        font-family: "TTCommons-Regular";
        text-decoration: none;
        color: #607790;
        span {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          overflow: hidden;
          margin-bottom: 4px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 0;
          }
        }
      }
    }
    &__school-name {
      p {
        margin-top: 0;
        margin-bottom: 0px;
        color: var(--primary-dark-color);
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        font-family: "TTCommons-Medium";
      }
    }
  }
`;
