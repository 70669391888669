import { Field, Form, Formik } from "formik";
import { isEmpty, isUndefined } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import LinkedIn from "react-linkedin-login-oauth2/lib/LinkedIn";
import { CircleSpinner } from "react-spinners-kit";
import { Radio } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";

import EditableDropdown from "../../../components/DropdownCustom/EditableDropdown";
import Loader from "../../../components/Loader/Loader";
import { config } from "../../../constant/config";
import {
  NoUserImage,
  capitalizeFirstLetter,
  getCountryList,
  getYearList,
  getYearListForAlumni,
  getYearListForStudent,
  applyAccessibilityHelp,
  getDegreeOptions,
} from "../../../helper/helper";
import { PRIMARY_COLOR } from "../../../styles/variables";
import {
  createFriendlyTitle,
  getSecondaryEmailRequired,
  months,
  validateEmail,
  validateName,
  validateLinkedinURL,
} from "../../../utils/common";
import { accountTypeOptions, pronounOptions } from "../../../constant/mockdata";
import AuthGoogleProfile from "../../AuthGoogle/AuthGoogleProfile";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CustomUpload from "../../CustomUpload/CustomUpload";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import InitialAvatar from "../../InitialAvatar/InitialAvatar";
import TextboxCustom from "../../Textbox/TextboxCustom";
import CustomDialog from "../../CustomDialog/CustomDialog";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

import uploadFocusIcon from "../../../assets/FocusIcons/Common/Upload.svg";
import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";
import fLinkedinIcon from "../../../assets/svg/f-linkedin-icon.svg";
import uploadIcon from "../../../assets/svg/uploadIcon.svg";

const LINKEDIN_CLIENT_ID = config().LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = config().LINKEDIN_REDIRECT_URI;

const SSOForm = ({
  formRef,
  setCurrentStep,
  currentStep,
  country,
  handleFormSubmit,
  changeStep,
  formProps,
  handleSuccess,
  handleFailure,
  handleLinkedinDisconnect,
  updateUserDetails,
  setUpdateEmailPopUp,
  handleChangeToPrimary,
  callSignUpSSO,
  showLoader,
  mediaQuery,
  handleCallback,
  handleCountryChange,
  rerender,
  getPlacePredictions,
  setSearchedCities,
  searchedCities,
  handleDegreeChange,
  departments,
  handleDepartmentChange,
  majors,
  handleMajorChange,
  graduationMonth,
  handleMonthChange,
  getGraduationMonthError,
  getDefaultYear,
  handleYearChange,
  getGYError,
  graduationYear,
  ref,
  profilePhoto,
  accessibilityHelp,
  setAccessibilityHelp,
  linkedInURL,
  setLinkedInURL,
}) => {
  const { users = {}, users_local = {} } = useSelector(
    (store) => store.userStore
  );
  const [formErrors, setFormErrors] = useState({});
  const [defaultMajor, setDefaultMajor] = useState("");

  const [showAccessibilityPopup, setShowAccessibilityPopup] = useState(false);
  const isStaff = users_local?.account_type?.toLowerCase() === "staff";

  const countryList = useMemo(() => getCountryList(), []);

  const degrees = useMemo(() => getDegreeOptions(users?.school_id), [users]);

  const yearsList = useMemo(() => {
    const account_type = users_local?.account_type?.toLowerCase();
    if (account_type === "alumni") {
      return getYearListForAlumni();
    }
    if (account_type === "student") {
      return getYearListForStudent();
    }
    return getYearList().reverse();
  }, [users_local]);

  let defaultDepartment = null;
  if (departments) {
    defaultDepartment = departments.find(
      (option) => option?.name === users_local?.educations?.[0]?.department
    );
  }
  useEffect(() => {
    if (majors) {
      let selectedMajor = majors.find(
        (option) => option === users_local?.educations?.[0]?.major
      );
      if (!isUndefined(selectedMajor)) {
        setDefaultMajor(selectedMajor);
      } else {
        if (users_local?.educations?.[0]?.major)
          setDefaultMajor(users_local?.educations?.[0]?.major);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_local?.educations?.[0]]);

  const AccountTypeField = () => {
    return (
      <Field {...formProps} name="account_type" type="select">
        {() => (
          <DropdownCustom
            width="210px"
            marginBottom={0}
            name=""
            required={true}
            label="Account type"
            defaultValue={
              isStaff
                ? "Staff & Faculty"
                : users_local?.account_type
                ? capitalizeFirstLetter(users_local?.account_type)
                : "Other"
            }
            placeholder={"Select account type"}
            justifyContent="center"
            onChange={(val) => {
              updateUserDetails("account_type", val.value);
            }}
            options={accountTypeOptions}
          />
        )}
      </Field>
    );
  };

  const IsSecondaryEmailRequired = useMemo(() => {
    return getSecondaryEmailRequired(users?.school_id);
  }, [users]);

  const checkErrors = () => {
    let errors = {};
    if (isStaff) {
      if (!users_local.first_name || users_local.first_name.trim() === "") {
        errors.first_name = "First name required";
      } else if (!validateName(users_local.first_name)) {
        errors.first_name = "First name is not valid.";
      }
      if (!users_local.last_name || users_local.last_name.trim() === "") {
        errors.last_name = "Last name required";
      } else if (!validateName(users_local.last_name)) {
        errors.last_name = "Last name is not valid.";
      }
    } else {
      if (currentStep === 1) {
        if (
          IsSecondaryEmailRequired &&
          !users_local?.secondary_email &&
          !isStaff
        ) {
          errors.secondary_email = "Secondary email required";
        } else if (
          !isStaff &&
          users_local?.secondary_email &&
          !validateEmail(users_local?.secondary_email)
        ) {
          errors.secondary_email = "Enter valid email";
        }
        if (!users_local?.email || users_local?.email.trim() === "") {
          errors.email = "Email required";
        }
      } else if (currentStep === 2) {
        if (!users_local.first_name || users_local.first_name.trim() === "") {
          errors.first_name = "First name required";
        } else if (!validateName(users_local.first_name)) {
          errors.first_name = "First name is not valid.";
        }
        if (!users_local.last_name || users_local.last_name.trim() === "") {
          errors.last_name = "Last name required";
        } else if (!validateName(users_local.last_name)) {
          errors.last_name = "Last name is not valid.";
        }
        if (!isStaff) {
          if (!users_local.nationality && !country) {
            errors.nationality = "Nationality required";
          }

          if (!users_local.current_city) {
            errors.current_city = "City required";
          }
        }
      } else if (currentStep === 3) {
        if (!users_local?.educations?.[0]?.degree && degrees?.length > 0) {
          errors.degree = "Degree required";
        }
        if (!users_local?.educations?.[0]?.major) {
          errors.major = "Major required";
        }
        if (
          !users_local?.educations?.[0]?.department &&
          departments?.length > 0
        ) {
          errors.department = "Department required";
        }
        if (!graduationMonth) {
          errors.graduation_month = "Graduation month required";
        }
        if (!graduationYear) {
          errors.graduation_year = "Graduation year required";
        }
      }
    }
    setFormErrors(errors);
    return errors;
  };

  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_local]);

  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graduationMonth, graduationYear]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        first_name: users_local?.first_name,
        last_name: users_local?.last_name,
        account_type: users_local?.account_type,
        email: users_local?.email,
        nationality: users_local?.nationality,
        current_city: users_local?.current_city,
        department: users_local?.educations?.[0]?.department,
        degree: users_local?.educations?.[0]?.degree,
        major: users_local?.educations?.[0]?.major,
        graduation_month: graduationMonth,
        graduation_year: graduationYear,
        secondary_email: users_local?.secondary_email,
      }}
      isInitialValid={true}
      validate={() => {
        checkErrors();
      }}
      onSubmit={() => {
        isStaff
          ? handleFormSubmit()
          : currentStep === 3
          ? handleFormSubmit()
          : changeStep();
      }}
    >
      {({ ...props }) => {
        return (
          <Form>
            {isStaff ? (
              <>
                <div className="step1">
                  <div className="items">
                    {!(
                      Object.keys(users_local).length === 0 &&
                      users_local.constructor === Object
                    ) && (
                      <div className="field-wrap">
                        <AccountTypeField />
                      </div>
                    )}
                  </div>
                  <div className="items" item xs={12}>
                    <div className="field-wrap">
                      <Field {...formProps} name="email" type="input">
                        {({ field, form: meta }) => (
                          <div className="YourBG">
                            <TextboxCustom
                              {...field}
                              label="Email"
                              name="email"
                              placeholder="Email"
                              lableaignment="left"
                              firstLogin={true}
                              disabled
                              textcolor={PRIMARY_COLOR}
                              value={users_local.email}
                              onChange={(val) => {
                                updateUserDetails("email", val.target.value);
                              }}
                              required={true}
                              error={
                                meta.touched && !isEmpty(formErrors["email"])
                              }
                              errorText={meta.touched && meta.error}
                            />
                            <a
                              className="edit-link"
                              href="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                setUpdateEmailPopUp(true);
                              }}
                              aria-label="Edit"
                            >
                              Edit
                            </a>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="items" style={{ marginTop: "15px" }}>
                  <div className="field-wrap">
                    <Field {...formProps} name="first_name" type="input">
                      {({ field }) => (
                        <TextboxCustom
                          {...field}
                          label="First name"
                          placeholder="First name"
                          lableaignment="left"
                          textcolor={PRIMARY_COLOR}
                          value={users_local.first_name}
                          onChange={(e) => {
                            updateUserDetails(
                              "first_name",
                              capitalizeFirstLetter(e.target.value)
                            );
                          }}
                          required={true}
                          firstLogin={true}
                          error={!isEmpty(formErrors["first_name"])}
                          errorText={formErrors["first_name"]}
                          maxLength={20}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="items">
                  <div className="field-wrap">
                    <Field {...formProps} name="last_name" type="input">
                      {({ field }) => (
                        <>
                          <TextboxCustom
                            {...field}
                            label="Last name"
                            name="last_name"
                            placeholder="Last name"
                            lableaignment="left"
                            firstLogin={true}
                            textcolor={PRIMARY_COLOR}
                            value={users_local.last_name}
                            onChange={(e) => {
                              updateUserDetails("last_name", e.target.value);
                            }}
                            required={true}
                            error={
                              props.touched?.last_name &&
                              !isEmpty(formErrors["last_name"])
                            }
                            errorText={
                              props.touched?.last_name &&
                              formErrors["last_name"]
                            }
                            maxLength={20}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="action-btns-wrapper">
                  <ButtonCustom
                    isDisabled={Object.keys(formErrors).length ? true : false}
                    type="submit"
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.setFieldTouched("first_name", true);
                        formRef.current.handleSubmit();
                      }
                    }}
                  >
                    Go to dashboard
                  </ButtonCustom>
                </div>
              </>
            ) : (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="step1">
                      <div className="items">
                        {!(
                          Object.keys(users_local).length === 0 &&
                          users_local.constructor === Object
                        ) && (
                          <div className="field-wrap">
                            <AccountTypeField />
                          </div>
                        )}
                      </div>

                      <div className="items" item xs={12}>
                        <div className="field-wrap">
                          <Field {...formProps} name="email" type="input">
                            {({ field, form: meta }) => (
                              <div className="YourBG">
                                <TextboxCustom
                                  {...field}
                                  label="Email"
                                  name="email"
                                  placeholder="Email"
                                  lableaignment="left"
                                  firstLogin={true}
                                  disabled
                                  textcolor={PRIMARY_COLOR}
                                  value={users_local.email}
                                  onChange={(val) => {
                                    updateUserDetails(
                                      "email",
                                      val.target.value
                                    );
                                  }}
                                  required={true}
                                  error={
                                    meta.touched &&
                                    !isEmpty(formErrors["email"])
                                  }
                                  errorText={meta.touched && meta.error}
                                />
                                <a
                                  className="edit-link"
                                  href="/#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setUpdateEmailPopUp(true);
                                  }}
                                  aria-label="Edit"
                                >
                                  Edit
                                </a>
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      {users_local?.account_type?.toLowerCase() !== "staff" && (
                        <>
                          <div className="items" item xs={12}>
                            <div className="field-wrap">
                              <Field
                                {...formProps}
                                name="secondary_email"
                                type="input"
                              >
                                {({ field }) => (
                                  <div>
                                    <div className="YourBG">
                                      <label className="textbox-label text-primary">
                                        Secondary email
                                        {IsSecondaryEmailRequired ? "*" : ""}
                                        <InfoIconTooltip
                                          margin="0"
                                          placement="bottom"
                                          aria-label="Secondary email"
                                          content={
                                            "If you lose your .edu email address upon graduation, you may use your secondary email to sign in to this account."
                                          }
                                        />
                                      </label>
                                      <TextboxCustom
                                        {...field}
                                        label=""
                                        name="secondary_email"
                                        placeholder="Enter backup email"
                                        lableaignment="left"
                                        firstLogin={true}
                                        textcolor={PRIMARY_COLOR}
                                        value={
                                          users_local?.secondary_email || ""
                                        }
                                        onChange={(val) => {
                                          updateUserDetails(
                                            "secondary_email",
                                            val.target.value
                                          );
                                        }}
                                        required={isStaff ? false : true}
                                        error={
                                          props.touched?.secondary_email &&
                                          !isEmpty(
                                            formErrors["secondary_email"]
                                          )
                                        }
                                        errorText={
                                          props.touched?.secondary_email &&
                                          formErrors["secondary_email"]
                                        }
                                      />
                                      {users_local?.is_secondary_email_verified &&
                                        users_local?.secondary_email && (
                                          <a
                                            className="edit-link shift-right"
                                            href="/#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleChangeToPrimary();
                                            }}
                                            aria-label="Verify Secondary email or change to primary button"
                                          >
                                            <span style={{ fontSize: "15px" }}>
                                              {!users_local?.is_secondary_email_verified
                                                ? "Verify email"
                                                : "Change to primary"}
                                            </span>
                                          </a>
                                        )}
                                    </div>
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="items">
                            <div className="widgets-item">
                              <label
                                id="linkedin-label"
                                className="textbox-label text-primary"
                              >
                                Connect your LinkedIn account
                                <InfoIconTooltip
                                  margin="0"
                                  placement="bottom"
                                  aria-label="Connect your LinkedIn account"
                                  content={
                                    <>
                                      <h3>Connected accounts</h3>
                                      <span className="mb-2">
                                        Connect your account so you can sign-in
                                        via LinkedIn.
                                      </span>
                                    </>
                                  }
                                />
                              </label>
                              <div className="linked-in">
                                {users.is_linkedin_connected ? (
                                  <ButtonCustom
                                    onClick={handleLinkedinDisconnect}
                                    aria-labelledby="linkedin-label"
                                  >
                                    <div
                                      className="linkedin-button sso-form"
                                      style={{ paddingTop: "13px" }}
                                    >
                                      <img src={fLinkedinIcon} alt="linkedin" />
                                      <p>Disconnect LinkedIn</p>
                                      {callSignUpSSO.isFetching() && (
                                        <CircleSpinner
                                          size={20}
                                          color={"#607790"}
                                        />
                                      )}
                                    </div>
                                  </ButtonCustom>
                                ) : (
                                  <LinkedIn
                                    clientId={LINKEDIN_CLIENT_ID}
                                    redirectUri={LINKEDIN_REDIRECT_URI}
                                    scope="r_liteprofile,r_emailaddress"
                                    onFailure={handleFailure}
                                    onSuccess={handleSuccess}
                                    supportIE
                                    renderElement={({ onClick, disabled }) => (
                                      <button
                                        onClick={onClick}
                                        disabled={disabled}
                                        aria-label="Connect account"
                                        aria-labelledby="linkedin-label"
                                        style={{
                                          padding: "0px",
                                          background: "none",
                                          color: "inherit",
                                          border: "none",
                                          cursor: "pointer",
                                          font: "inherit",
                                          outline: "inherit",
                                        }}
                                      >
                                        <div className="linkedin-button sso-form">
                                          <img
                                            src={fLinkedinIcon}
                                            alt="linkedin"
                                          />
                                          <p>Connect account</p>
                                          {callSignUpSSO.isFetching() && (
                                            <CircleSpinner
                                              size={20}
                                              color={"#607790"}
                                            />
                                          )}
                                        </div>
                                      </button>
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          {/* Google Connect */}
                          <div className="items">
                            <div className="widgets-item">
                              <label
                                id="google-label"
                                className="textbox-label text-primary"
                              >
                                Connect your Google account
                                <InfoIconTooltip
                                  margin="0"
                                  placement="bottom"
                                  aria-label="Connect your Google account"
                                  content={
                                    <>
                                      <h3>Connected accounts</h3>
                                      <span className="mb-2">
                                        Connect your account so you can sign-in
                                        via Google.
                                      </span>
                                    </>
                                  }
                                />
                              </label>
                              <div className="google-connect-first-login-sso-wrapper">
                                <AuthGoogleProfile
                                  isGoogleConnected={users?.is_google_connected}
                                  ariaLabelledby="google-label"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    <div className="items">
                      <div className="profile-upload-wrapper">
                        <label className="form-label textbox-label text-primary">
                          Upload a profile picture
                        </label>
                        <div className="profile-upload">
                          {showLoader ? (
                            <Loader />
                          ) : (
                            <React.Fragment>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  width: "100%",
                                  marginTop: -15,
                                }}
                              >
                                <div
                                  style={{
                                    marginBottom: mediaQuery ? 10 : 0,
                                  }}
                                  className="profile-img-wrap"
                                >
                                  {profilePhoto && profilePhoto.length > 0 ? (
                                    <img
                                      className="profile-img"
                                      src={
                                        profilePhoto && profilePhoto.length > 0
                                          ? profilePhoto
                                          : NoUserImage
                                      }
                                      alt="Profile"
                                    />
                                  ) : (
                                    <InitialAvatar
                                      title={
                                        users_local && users_local.name
                                          ? users_local.name
                                          : ""
                                      }
                                      alt={
                                        users_local && users_local.name
                                          ? users_local.name
                                          : ""
                                      }
                                      index={-1}
                                    />
                                  )}
                                </div>

                                <div className="profile-upload-link">
                                  <div className="upload-icon">
                                    <CustomUpload
                                      width={
                                        profilePhoto && profilePhoto.length > 0
                                          ? 200
                                          : 115
                                      }
                                      revertIcon={true}
                                      icon={uploadIcon}
                                      focusIcon={uploadFocusIcon}
                                      text={
                                        profilePhoto && profilePhoto.length > 0
                                          ? "Upload another"
                                          : "Upload picture"
                                      }
                                      handleUploadFile={handleCallback}
                                      vallidFiles="image/*"
                                      isSsoModal={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="items">
                      <div className="field-wrap">
                        <Field {...formProps} name="first_name" type="input">
                          {({ field }) => (
                            <TextboxCustom
                              label="First name"
                              placeholder="First name"
                              lableaignment="left"
                              textcolor={PRIMARY_COLOR}
                              value={users_local.first_name}
                              onChange={(e) => {
                                updateUserDetails(
                                  "first_name",
                                  capitalizeFirstLetter(e.target.value)
                                );
                              }}
                              required={true}
                              firstLogin={true}
                              error={
                                props.touched?.first_name &&
                                !isEmpty(formErrors["first_name"])
                              }
                              errorText={
                                props.touched?.first_name &&
                                formErrors["first_name"]
                              }
                              maxLength={20}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="items">
                      <div className="field-wrap">
                        <Field {...formProps} name="last_name" type="input">
                          {({ field }) => (
                            <>
                              <TextboxCustom
                                {...field}
                                label="Last name"
                                name="last_name"
                                placeholder="Last name"
                                lableaignment="left"
                                firstLogin={true}
                                textcolor={PRIMARY_COLOR}
                                value={users_local.last_name}
                                onChange={(e) => {
                                  updateUserDetails(
                                    "last_name",
                                    capitalizeFirstLetter(e.target.value)
                                  );
                                }}
                                required={true}
                                error={
                                  props.touched?.last_name &&
                                  !isEmpty(formErrors["last_name"])
                                }
                                errorText={
                                  props.touched?.last_name &&
                                  formErrors["last_name"]
                                }
                                maxLength={20}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="items">
                      <div className="field-wrap">
                        <Field {...formProps} name="pronoun" type="select">
                          {({ field }) => (
                            <DropdownCustom
                              {...field}
                              name="pronoun"
                              label="Pronouns"
                              placeholder="Select a pronoun"
                              justifyContent="center"
                              options={pronounOptions}
                              value={pronounOptions?.find(
                                (pronoun) =>
                                  pronoun.label === users_local?.pronoun ||
                                  pronoun.value === users_local?.pronoun
                              )}
                              onChange={(val) => {
                                updateUserDetails("pronoun", val.value);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="items">
                      {!(
                        Object.keys(users_local).length === 0 &&
                        users_local.constructor === Object
                      ) && (
                        <div className="field-wrap">
                          <Field
                            {...formProps}
                            name="nationality"
                            type="select"
                          >
                            {({ field }) => (
                              <DropdownCustom
                                {...field}
                                name=""
                                label="Nationality"
                                placeholder="Select"
                                justifyContent="center"
                                options={countryList}
                                value={
                                  countryList && users_local?.nationality
                                    ? countryList.find(
                                        (option) =>
                                          option.value ===
                                          users_local?.nationality
                                      )
                                      ? countryList.find(
                                          (option) =>
                                            option.value ===
                                            users_local?.nationality
                                        )
                                      : {
                                          label: users_local?.nationality,
                                          value: users_local?.nationality,
                                        }
                                    : ""
                                }
                                required={true}
                                error={
                                  props.touched?.nationality &&
                                  !users_local?.nationality
                                    ? "Nationality required"
                                    : ""
                                }
                                touched={true}
                                errorText={
                                  props.touched?.nationality &&
                                  !users_local?.nationality
                                    ? "Nationality required"
                                    : ""
                                }
                                onFocus={() => {
                                  formRef.current.setFieldTouched(
                                    "nationality",
                                    true
                                  );
                                }}
                                onChange={(val) => {
                                  formRef.current.setFieldTouched(
                                    "nationality",
                                    true
                                  );
                                  handleCountryChange(val?.value);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                    </div>

                    <div className="items">
                      {!(
                        Object.keys(users_local).length === 0 &&
                        Object.getPrototypeOf(users_local) === Object.prototype
                      ) && rerender ? (
                        <>
                          <div className="field-wrap">
                            <Field
                              {...formProps}
                              name="current_city"
                              type="select"
                            >
                              {({ field }) => (
                                <EditableDropdown
                                  {...field}
                                  width="210px"
                                  label="Current city"
                                  required={true}
                                  marginBottom={0}
                                  name=""
                                  placeholder={"City"}
                                  justifyContent="center"
                                  value={{
                                    label: users_local?.current_city,
                                    value: users_local?.current_city,
                                  }}
                                  onInputChange={(val) => {
                                    formRef.current.setFieldTouched(
                                      "current_city",
                                      true
                                    );
                                    getPlacePredictions({ input: val });
                                  }}
                                  ref={ref}
                                  onChange={(val) => {
                                    updateUserDetails(
                                      "current_city",
                                      capitalizeFirstLetter(val.value)
                                    );

                                    formRef.current.setFieldTouched(
                                      "current_city",
                                      true
                                    );
                                  }}
                                  onBlur={() => {
                                    setSearchedCities([]);
                                    formRef.current.setFieldTouched(
                                      "current_city",
                                      true
                                    );
                                  }}
                                  options={searchedCities}
                                  noOptionsMessage="Type to search a city"
                                  error={
                                    props.touched?.current_city &&
                                    !users_local?.current_city
                                      ? "City required"
                                      : ""
                                  }
                                  touched={true}
                                  errorText={
                                    props.touched?.current_city &&
                                    !users_local?.current_city
                                      ? "City required"
                                      : ""
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    {degrees?.length > 0 && (
                      <div className="items">
                        <div className="field-wrap">
                          <Field {...formProps} name="degree" type="select">
                            {({ meta }) => {
                              return (
                                <DropdownCustom
                                  width="210px"
                                  label="Degree"
                                  required={true}
                                  marginBottom={0}
                                  name=""
                                  placeholder={"Choose degree"}
                                  justifyContent="center"
                                  defaultValue={
                                    users_local?.educations?.[0]?.degree || ""
                                  }
                                  onChange={(val) => {
                                    formRef.current.setFieldTouched(
                                      "degree",
                                      true
                                    );
                                    handleDegreeChange(val.value);
                                  }}
                                  onFocus={() =>
                                    formRef.current.setFieldTouched(
                                      "degree",
                                      true
                                    )
                                  }
                                  options={degrees}
                                  error={
                                    props.touched?.degree &&
                                    !users_local?.educations?.[0]?.degree
                                      ? "Degree required"
                                      : ""
                                  }
                                  touched={true}
                                  errorText={
                                    props.touched?.degree
                                      ? "Degree required"
                                      : ""
                                  }
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    )}

                    {departments?.length > 0 && (
                      <div className="items">
                        <div className="field-wrap">
                          <Field {...formProps} name="department" type="select">
                            {({ meta }) => (
                              <DropdownCustom
                                width="210px"
                                label="Department"
                                required={true}
                                marginBottom={0}
                                name=""
                                placeholder={"Choose department"}
                                justifyContent="center"
                                defaultValue={
                                  defaultDepartment
                                    ? defaultDepartment.name
                                    : ""
                                }
                                onChange={(val) => {
                                  formRef.current.setFieldTouched(
                                    "department",
                                    true
                                  );
                                  let selectDepartment = departments.find(
                                    (option) => option?.name === val.value
                                  );
                                  handleDepartmentChange(
                                    val.value,
                                    selectDepartment
                                  );
                                }}
                                onFocus={() =>
                                  formRef.current.setFieldTouched(
                                    "department",
                                    true
                                  )
                                }
                                options={
                                  departments?.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item.name,
                                    };
                                  }) || []
                                }
                                error={
                                  props.touched?.department &&
                                  (!users_local?.educations?.[0]?.department
                                    ? "Department required"
                                    : "")
                                }
                                touched={true}
                                errorText={
                                  props.touched?.department && meta.error
                                }
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    )}

                    <div className="items">
                      {!(
                        Object.keys(users_local).length === 0 &&
                        users_local.constructor === Object
                      ) && (
                        <div className="field-wrap">
                          <Field {...formProps} name="major" type="select">
                            {() => (
                              <EditableDropdown
                                name=""
                                label="Major"
                                placeholder="Select major"
                                addText="Add Major"
                                justifyContent="center"
                                options={
                                  majors?.map((sub) => {
                                    return {
                                      label: sub,
                                      value: sub,
                                    };
                                  }) || []
                                }
                                required={true}
                                error={
                                  props.touched?.major &&
                                  !users_local?.educations?.[0]?.major
                                    ? "Major required"
                                    : ""
                                }
                                onFocus={() =>
                                  formRef.current.setFieldTouched("major", true)
                                }
                                touched={true}
                                defaultValue={
                                  defaultMajor
                                    ? {
                                        value: defaultMajor,
                                        label: defaultMajor,
                                      }
                                    : null
                                }
                                onChange={(val) => {
                                  formRef.current.setFieldTouched(
                                    "major",
                                    true
                                  );
                                  handleMajorChange(
                                    val?.__isNew__
                                      ? createFriendlyTitle(val?.value)
                                      : val?.value
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                    </div>
                    <div className="items">
                      <label className="textbox-label text-primary">
                        Graduation year*
                      </label>
                      <div className="items2" role="group">
                        <div className="field-wrap">
                          <div className="graduation-year-group">
                            <Field
                              {...formProps}
                              name="graduation_month"
                              type="select"
                            >
                              {({ field }) => (
                                <DropdownCustom
                                  {...field}
                                  width="210px"
                                  marginBottom={0}
                                  name=""
                                  placeholder={graduationMonth || "Month"}
                                  justifyContent="center"
                                  required={true}
                                  defaultValue={graduationMonth || null}
                                  onChange={(val) => {
                                    if (
                                      Object.keys(formErrors).length === 1 &&
                                      formErrors?.graduation_month
                                    ) {
                                      setFormErrors({});
                                    }
                                    handleMonthChange(val);
                                  }}
                                  onFocus={() =>
                                    formRef.current.setFieldTouched(
                                      "graduation_month",
                                      true
                                    )
                                  }
                                  validationError={
                                    props.touched?.graduation_month &&
                                    getGraduationMonthError()
                                  }
                                  touched={true}
                                  options={months.map((item) => {
                                    return {
                                      label: item,
                                      value: item,
                                    };
                                  })}
                                  showLabelAtTop={
                                    graduationMonth ? false : true
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="field-wrap">
                          <div className="graduation-year-group">
                            <Field
                              {...formProps}
                              name="graduation_year"
                              type="select"
                            >
                              {({ field }) => (
                                <DropdownCustom
                                  {...field}
                                  width="210px"
                                  marginBottom={0}
                                  required={true}
                                  name=""
                                  showStarIcon={false}
                                  placeholder={getDefaultYear() || "Year"}
                                  justifyContent="center"
                                  defaultValue={getDefaultYear()}
                                  onChange={(val) => {
                                    if (
                                      Object.keys(formErrors).length === 1 &&
                                      formErrors?.graduation_year
                                    ) {
                                      setFormErrors({});
                                    }
                                    handleYearChange(val);
                                  }}
                                  options={yearsList}
                                  onFocus={() =>
                                    formRef.current.setFieldTouched(
                                      "graduation_year",
                                      true
                                    )
                                  }
                                  touched={true}
                                  validationError={
                                    props.touched?.graduation_year &&
                                    getGYError()
                                  }
                                  showLabelAtTop={
                                    getDefaultYear() ? false : true
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="items">
                      <label className="textbox-label text-primary">
                        Connect your LinkedIn profile
                      </label>
                      <div className="field-wrap">
                        <Field {...formProps} name="linkedin">
                          {() => (
                            <TextboxCustom
                              name={"LinkedIn profile"}
                              placeholder={"Enter LinkedIn URL"}
                              lableaignment="left"
                              value={linkedInURL}
                              onChange={(e) => setLinkedInURL(e.target.value)}
                              error={
                                linkedInURL && !validateLinkedinURL(linkedInURL)
                              }
                              errorText={
                                linkedInURL && !validateLinkedinURL(linkedInURL)
                                  ? "Enter valid LinkedIn URL"
                                  : ""
                              }
                            />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="items">
                      <div className="field-wrap">
                        <div className="keep-me-signin accessibility-text tnc-link">
                          <Radio
                            type="checkbox"
                            checked={accessibilityHelp}
                            checkedIcon={
                              <CheckCircle color={"primary"} fontSize="small" />
                            }
                            onClick={(e) => {
                              if (e.target.checked) {
                                setShowAccessibilityPopup(true);
                                setAccessibilityHelp(true);
                              } else {
                                setAccessibilityHelp(false);
                                localStorage.setItem(
                                  "accessibilityHelp",
                                  false
                                );
                                applyAccessibilityHelp(false);
                              }
                            }}
                            value={accessibilityHelp}
                            inputProps={{
                              "aria-label": "Enable accessibility mode",
                            }}
                            color="primary"
                            size="small"
                          />
                          Enable accessibility mode
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAccessibilityPopup(
                                !showAccessibilityPopup
                              );
                            }}
                            className="text-gray ml-auto link-focus"
                            aria-label="Accessibility mode explaination"
                          >
                            What is this?
                          </a>
                        </div>
                      </div>
                    </div>

                    {showAccessibilityPopup && (
                      <CustomDialog
                        open={showAccessibilityPopup}
                        title={"Accessibility help?"}
                        subTitle={
                          "It is our mission to be inclusive and offer equal opportunities to all. We offer a customized version of the platform for those with accessibility needs."
                        }
                        handleClose={() => setShowAccessibilityPopup(false)}
                        isDisplayCloseButton={false}
                        className="signup-accessibility-popup"
                        dialogTitleClass="modal-title"
                        dialogSubTitleClass="text-para"
                      >
                        <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
                          <div className="got-it-button">
                            <ButtonCustom
                              onClick={() => {
                                localStorage.setItem(
                                  "accessibilityHelp",
                                  accessibilityHelp
                                );
                                applyAccessibilityHelp(accessibilityHelp);
                                setShowAccessibilityPopup(false);
                              }}
                            >
                              Got It!
                            </ButtonCustom>
                          </div>
                        </div>
                      </CustomDialog>
                    )}
                  </>
                )}
                <div className="action-btns-wrapper">
                  {currentStep > 1 && (
                    <>
                      <ButtonCustom
                        isDisabled={
                          Object.keys(users_local).length === 0 &&
                          users_local.constructor === Object
                            ? true
                            : false
                        }
                        type="button"
                        onClick={() => {
                          let fieldsTouched = {
                            first_name: true,
                            last_name: true,
                          };

                          if (currentStep === 2) {
                            fieldsTouched = {
                              ...fieldsTouched,
                              secondary_email: true,
                            };
                          } else if (currentStep === 3) {
                            fieldsTouched = {
                              ...fieldsTouched,
                              secondary_email: true,
                              nationality: true,
                              current_city: true,
                            };
                          }
                          formRef.current.setTouched({ ...fieldsTouched });
                          setCurrentStep(currentStep - 1);
                          setFormErrors({});
                          //checkErrors();
                        }}
                        className="shadow-btn"
                      >
                        <img src={BackArrow} alt="back" />
                        Previous
                      </ButtonCustom>
                    </>
                  )}
                  {currentStep < 3 && (
                    <>
                      <ButtonCustom
                        isDisabled={
                          Object.keys(formErrors).length > 0 ? true : false
                        }
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          if (formRef.current) {
                            formRef.current.handleSubmit();
                          }
                        }}
                      >
                        Continue
                      </ButtonCustom>
                    </>
                  )}
                  {currentStep === 3 && (
                    <ButtonCustom
                      isDisabled={
                        Object.keys(formErrors).length === 0 ? false : true
                      }
                      type="submit"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.setFieldTouched("first_name", true);
                          formRef.current.handleSubmit();
                        }
                      }}
                    >
                      Go to dashboard
                    </ButtonCustom>
                  )}
                </div>
              </>
            )}
            <p className="mandatory-fields-description-text">
              All mandatory fields are marked with an asterisk (*)
            </p>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SSOForm;
