import React from "react";
import Select, { components } from "react-select";
import { PRIMARY_COLOR } from "../../styles/variables";
import cls from "classnames";
import chevArrowDownIcon from "../../assets/svg/chevArrowDown.svg";
import chevArrowUpIcon from "../../assets/svg/chevArrowUp.svg";

import { Scrollbars } from "react-custom-scrollbars";
import SortByStyles from "./SortByStyles";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        alt="Dropdown arrow"
        src={props.selectProps.menuIsOpen ? chevArrowUpIcon : chevArrowDownIcon}
      />
    </components.DropdownIndicator>
  );
};

const renderScrollbar = (props) => {
  return (
    <React.Fragment>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200} {...props}>
        {props.children}
      </Scrollbars>
    </React.Fragment>
  );
};

const DropdownCustom = ({
  onClick = () => {},
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {},
  onInputChange = () => {},
  className,
  name,
  value,
  placeholder,
  width = "100%",
  height = 60,
  label = "",
  showLabelAtTop = true,
  options = [],
  justifyContent = "center",
  isMulti = false,
  error = false,
  touched = false,
  required = false,
  isSearchable = true,
}) => {
  const colourStyles = {
    valueContainer: (styles) => ({
      ...styles,
      justifyContent: justifyContent,
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    menu: (styles) => ({
      ...styles,
      border: "2px solid var(--light-gray-color)",
      backgroundColor: "#FFFFFF",
      overflow: "hidden",
      boxShadow: "0 5px 14px 3px rgba(209,221,229,0.4)",
      margin: "0 !important",
    }),
    menuList: (styles) => ({
      ...styles,
      margin: "0 !important",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: "white",
      width: `${width}px`,
      minHeight: `${height}px`,
      height: "max-content",
      border: "2px solid var(--light-gray-color)",
      borderRadius: "45px",
      color: "var(--gray-text-color)",
      fontFamily: "TTCommons-Medium",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "0",
      lineHeight: "20px",
      // --Focus style
      boxShadow: isFocused ? "0 0 8px 5px #000c3d !important" : "",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "13px 20px 8px 20px",
        backgroundColor: isDisabled
          ? "#fff"
          : isSelected && !isFocused
          ? "#fff"
          : isFocused
          ? "var(--primary-dark-color)"
          : null,
        color: isDisabled
          ? "var(--primary-dark-color)"
          : isSelected && !isFocused
          ? "var(--primary-dark-color)"
          : isFocused
          ? "#fff"
          : "var(--primary-dark-color)",
        fontSize: "15px",
        fontFamily: "TTCommons-Regular",
        letterSpacing: 0,
        lineHeight: "15px",
        cursor: isDisabled ? "not-allowed" : "default",
        justifyContent: justifyContent,
        display: "flex",
        ":active": {
          ...styles[":active"],
        },
      };
    },
    input: (styles) => ({
      ...styles,
      textAlign: justifyContent,
      color: "var(--gray-text-color)",
      fontFamily: "TTCommons-Medium",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: !showLabelAtTop && PRIMARY_COLOR,
      fontFamily: "TTCommons-Medium",
    }),
    singleValue: (styles) => ({
      ...styles,
      justifyContent: justifyContent,
      color: PRIMARY_COLOR,
      fontFamily: "TTCommons-Regular",
      fontSize: "15px",
    }),
  };
  return (
    <SortByStyles>
      {label && showLabelAtTop && (
        <p
          className={cls("textbox-label", "text-primary")}
          style={{ justifyContent: justifyContent, margin: "0px 0px 6px 12px" }}
          id="sort-by"
        >
          {`${label}${required ? "*" : ""}`}
        </p>
      )}

      <Select
        aria-label={"Sort by"}
        aria-labelledby="sort-by"
        components={{ DropdownIndicator, MenuList: renderScrollbar }}
        captureMenuScroll={false}
        className={`react-custom-dropdown ${className}`}
        name={name}
        options={options}
        styles={colourStyles}
        onClick={onClick}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onInputChange={onInputChange}
        value={value}
        placeholder={placeholder ? placeholder : label}
        isMulti={isMulti}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: "#0030B5",
            primary25: "#0030B5",
            primary: "var(--light-gray-color)",
          },
        })}
        isSearchable={isSearchable}
        aria-live="polite"
      />

      <div
        aria-live="polite"
        aria-atomic="true"
        className="accessibility-hidden-element"
      >
        {isMulti
          ? `Selected: ${value?.length ?? 0} items`
          : value
          ? `Selected: ${value?.label ?? value}`
          : ""}
      </div>

      {!!error && touched && (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
          id="email-helper-text"
        >
          {error}
        </p>
      )}
    </SortByStyles>
  );
};

export default DropdownCustom;
