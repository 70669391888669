import React, { useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useSelector, useDispatch } from "react-redux";

import FilterComapanyStyles from "../FilterComapany/FilterComapanyStyles";

import usePromise from "../../../../hooks/usePromise/usePromise";
import { getMapDataAPI } from "../../../../services/JobServices";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import { loadGoogleMaps, numberFormatter } from "../../../../helper/helper";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";

import MarkerImg from "../../../../assets/svg/Marker.svg";
import MapModeIcon from "../../../../assets/svg/f-Maps.svg";
import MapModeAccessibilityIcon from "../../../../assets/svg/f-Maps-accessibility.svg";

export default function MyMap(props) {
  const dispatch = useDispatch();
  const {
    appliedVisaFilters = {},
    selectedCompany = null,
    companies = [],
  } = useSelector((store) => store.jobStore);

  const [callGetMapDataAPI, refreshGetMapDataAPI] = usePromise(getMapDataAPI);
  const [viewMode, setViewMode] = useState(false);
  const [loader, setLoader] = useState(false);

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const defaultCenter = { lat: 37.09024, lng: -95.712891 };

  useEffect(() => {
    loadGoogleMaps(() => {
      // Work to do after the library loads.
      getMapData();
    });
  }, []);

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany !== null &&
      selectedCompany.company_id
    ) {
      const {
        job_classification,
        job_title,
        visa,
        year,
        industry,
        city,
        state,
        experience,
        graduationYear,
      } = appliedVisaFilters;

      setLoader(true);
      refreshGetMapDataAPI({
        year,
        id: "search",
        search: {
          type: visa && visa !== null ? visa : "",
          company_ids: [selectedCompany.company_id],
          industry_ids: industry && industry !== null ? [industry] : [],
          state_ids: state && state !== null ? [state.value] : [],
          city_ids: city && city !== null ? [city.value] : [],
          job_title_ids: job_classification ?? [],
          soc_title_ids: job_title ?? [],
          graduation_year_ids:
            graduationYear && graduationYear !== null ? [graduationYear] : [],
          wage_levels: experience && experience !== null ? experience : [],
        },
      });
    }
  }, [selectedCompany]);

  const getMapData = () => {
    const {
      job_classification,
      job_title,
      visa,
      year,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
    } = appliedVisaFilters;

    setLoader(true);

    if (checkExtraFilters()) {
      refreshGetMapDataAPI({
        year,
        id: "search",
        search: {
          type: visa && visa !== null ? visa : "",
          company_ids: company && company !== null ? [company.value] : [],
          industry_ids: industry && industry !== null ? [industry] : [],
          state_ids: state && state !== null ? [state.value] : [],
          city_ids: city && city !== null ? [city.value] : [],
          job_title_ids: job_classification ?? [],
          soc_title_ids: job_title ?? [],
          graduation_year_ids:
            graduationYear && graduationYear !== null ? [graduationYear] : [],
          wage_level: experience && experience !== null ? experience : null,
        },
      });
    } else {
      dispatch(
        setSelectedCompany(
          companies && companies.length > 0 ? companies[0] : null
        )
      );
    }
  };

  const checkExtraFilters = () => {
    let extraFilter = false;

    const {
      job_classification,
      job_title,
      industry,
      company,
      city,
      state,
      experience,
      graduationYear,
    } = appliedVisaFilters;

    const { INITIAL_FILTERS = {} } = props;

    if (company && company !== null && company !== INITIAL_FILTERS.company) {
      extraFilter = true;
    }
    if (
      industry &&
      industry !== null &&
      industry !== INITIAL_FILTERS.industry
    ) {
      extraFilter = true;
    }
    if (state && state !== null && state !== INITIAL_FILTERS.state) {
      extraFilter = true;
    }
    if (city && city !== null && city !== INITIAL_FILTERS.city) {
      extraFilter = true;
    }
    if (
      job_classification &&
      job_classification !== null &&
      job_classification !== INITIAL_FILTERS.job_classification
    ) {
      extraFilter = true;
    }
    if (
      job_title &&
      job_title !== null &&
      job_title !== INITIAL_FILTERS.job_title
    ) {
      extraFilter = true;
    }
    if (
      graduationYear &&
      graduationYear !== null &&
      graduationYear !== INITIAL_FILTERS.graduationYear
    ) {
      extraFilter = true;
    }
    if (
      experience &&
      experience !== null &&
      experience !== INITIAL_FILTERS.experience
    ) {
      extraFilter = true;
    }

    return extraFilter;
  };

  const clusterStyles = [
    {
      textColor: "white",
      url: MarkerImg,
      height: 32,
      width: 32,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "11px",
    },
    {
      textColor: "white",
      url: MarkerImg,
      height: 34,
      width: 34,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "11px",
    },
    {
      textColor: "white",
      url: MarkerImg,
      height: 36,
      width: 36,
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "11px",
    },
  ];

  const renderMapData = (mapData) => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 4,
      center: defaultCenter,
    });

    var defaultIcon = {
      url: MarkerImg,
      scaledSize: new window.google.maps.Size(30, 30), // scaled size
    };

    var markersArray = [];

    const markers = mapData.map((location) => {
      const employers = location.employer_name.split("||");

      let employersList = "";
      if (employers.length > 1) {
        employersList = `<span class="infowindow-title  multiple-employers-infowindow">${employers.join(
          "<br/>"
        )}</span>`;
      } else {
        employersList = `<span class="infowindow-title">${employers[0]
          .replace(/\(\d\)/g, "")
          .trim()}</span>`;
      }

      const contentString = `<div class="infowindow" data-reactroot="">
          ${employersList}
        </div>`;

      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      const marker = new window.google.maps.Marker({
        position: { ...location },
        label: {
          text: numberFormatter(location.petition).toString(),
          color: "white",
          fontSize: "10px",
          petition: location.petition,
        },
        icon: defaultIcon,
        name: location.petition.toString(),
        infowindow: infowindow,
      });

      markersArray.push(marker);

      window.google.maps.event.addListener(marker, "click", function () {
        markersArray.forEach(function (marker) {
          marker.infowindow.close(map, marker);
        });

        this.infowindow.open(map, this);
      });

      return marker;
    });

    var mcOptions = {
      gridSize: 30,
      styles: clusterStyles,
      maxZoom: 15,
    };

    // Add a marker clusterer to manage the markers.
    let markerCLuster = new window.MarkerClusterer(map, markers, mcOptions, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    markerCLuster.setCalculator((markers, numStyles) => {
      let totalPetitions = markers
        .map((marker) => marker.label.petition)
        .reduce((prev, next) => prev + next);

      let index = 0;
      let count = markers.length;
      let dv = count;
      while (dv !== 0) {
        dv = parseInt(dv / 10, 10);
        index++;
      }

      index = Math.min(index, numStyles);

      return {
        text: numberFormatter(totalPetitions).toString(),
        index: index,
      };
    });

    setLoader(false);
  };

  useEffect(() => {
    if (
      callGetMapDataAPI.hasFetched() &&
      callGetMapDataAPI.hasErrors() === false
    ) {
      if (
        callGetMapDataAPI.data() &&
        callGetMapDataAPI.data().data &&
        callGetMapDataAPI.data().data.markers &&
        callGetMapDataAPI.data().data.markers.data
      ) {
        renderMapData(callGetMapDataAPI.data().data.markers.data);
      }
    }
  }, [callGetMapDataAPI.isFetching()]);

  const chageViewMode = () => {
    setViewMode(!viewMode);
    props.sendMode(!viewMode);
  };

  return (
    <FilterComapanyStyles>
      <div className="company-list">
        <div className="company-title mb-25">
          <div className="title-primary">Companies</div>
          <div className="mode">
            <span
              id="change-mode-description"
              className="accessibility-hidden-element"
            >
              This is a button to change the view mode. You can change the view
              mode to list by clicking on this button.
            </span>
            <ButtonCustom
              width="100"
              height="30"
              onClick={chageViewMode}
              color="primary"
              aria-label="Change view mode"
              aria-describedby="change-mode-description"
            >
              <img
                src={
                  accessibilityHelp == "true"
                    ? MapModeAccessibilityIcon
                    : MapModeIcon
                }
                alt="change mode"
              />
            </ButtonCustom>
          </div>
        </div>

        {loader && <ShimmerThumbnail height={"500"} />}

        {!callGetMapDataAPI.isFetching() && (
          <div className="company-map">
            <div
              style={{ width: 1000, height: 800 }}
              id="map"
              aria-label="Map with petition data"
            ></div>{" "}
          </div>
        )}
      </div>
    </FilterComapanyStyles>
  );
}
