import React, { memo, useState, useEffect } from "react";
import moment from "moment/moment";

import CustomDialog from "../../CustomDialog/CustomDialog";
import styles from "./ProfilePreview.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { useAppState } from "../../../context";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { isStaffAccountType, toCapital } from "../../../utils/common";
import TextTruncate from "../../atoms/TextTruncate/TextTruncate";
import { pronounOptions } from "../../../constant/mockdata";

import LocationIcon from "../../../assets/svg/LocationIcon";
import HouseIcon from "../../../assets/svg/HouseIcon";
import LinkedIn from "../../../assets/linkedin.png";
import JobIcon from "../../../assets/svg/JobIcon";

const cx = createModuleStyleExtractor(styles);

const ProfilePreview = ({ defaultState, handleClose, tab }) => {
  const [visible, setIsVisible] = useState(defaultState || false);
  const [showMore, setShowMore] = useState(true);

  const { personalForm, careerForm, experienceForm, educationsForm } =
    useAppState("profile");
  const { ambassadorBio } = useAppState("ambassadorProfile");
  const {
    profile_image_url,
    first_name,
    last_name,
    current_city,
    nationality,
    mentor,
    ambassador,
    account_type,
    user_type,
    intro,
    pronoun,
  } = personalForm || {};
  const { resume_bio, linkedin_public_url } = careerForm || {};
  const { experiences } = experienceForm || {};
  const education = educationsForm[0];
  const experience = experiences[0];

  const handleShow = () => {
    setShowMore(!showMore);
  };
  const getBio = () => {
    if (showMore) {
      return resume_bio.substring(0, 130);
    }
    return resume_bio;
  };

  useEffect(() => {
    setIsVisible(defaultState);
  }, [defaultState]);

  return (
    <CustomDialog
      open={visible}
      title={"Profile Preview"}
      handleClose={handleClose}
      className="step-detail-profile"
      dialogTitleClass="label-hidden"
      dialogSubTitleClass=""
      width={500}
    >
      <div className={cx("o-final-container__modal")}>
        <div className={cx("o-final-container__modal__inside")}>
          <div className={cx("o-final-container__modal__inside__image")}>
            <CircularAvatar
              name={`${first_name} ${last_name}`}
              round
              src={profile_image_url}
              size={70}
            />
          </div>
          <div className={cx("o-final-container__modal__inside__profile-name")}>
            <h2>
              {toCapital(`${first_name} ${last_name}`)
                .split(" ")
                .slice(0, 3)
                .join(" ")}
            </h2>
            {pronoun && (
              <p>{`(${
                pronounOptions?.find(
                  (pronoun) =>
                    pronoun?.value === pronoun || pronoun?.label === pronoun
                )?.label
              })`}</p>
            )}
          </div>
          <div className={cx("o-final-container__modal__inside__tags")}>
            {account_type && (
              <div
                className={cx("o-final-container__modal__inside__tags__item")}
              >
                <p>
                  {isStaffAccountType(account_type, user_type, true)
                    ? "Staff & Faculty"
                    : toCapital(account_type)}
                </p>
              </div>
            )}
            {ambassador && (
              <div
                className={cx("o-final-container__modal__inside__tags__item")}
              >
                <p>Ambassador</p>
              </div>
            )}
            {mentor && account_type !== "staff" && (
              <div
                className={cx("o-final-container__modal__inside__tags__item")}
              >
                <p>Mentor</p>
              </div>
            )}
          </div>

          {experience?.role && experience?.organization && (
            <div
              className={cx("o-final-container__modal__inside__profile-tittle")}
            >
              <span>
                <JobIcon />
              </span>
              <p>
                {experience?.role && `${experience?.role} at`}{" "}
                {experience?.organization && experience?.organization}
              </p>
            </div>
          )}

          {isStaffAccountType(account_type, user_type, false) && (
            <div
              className={cx("o-final-container__modal__inside__profile-detail")}
            >
              <p>
                {education?.major && education?.major}
                {education?.second_major && `, ${education?.second_major}`}
              </p>

              {education?.graduation_year && (
                <p>
                  Class of {moment(education?.graduation_year).format("YYYY")}
                </p>
              )}
            </div>
          )}
          {tab === "student" && intro ? (
            <div
              className={cx(
                "o-final-container__modal__inside__profile-summary"
              )}
            >
              <TextTruncate text={intro} />
            </div>
          ) : tab === "ambassador" && ambassador && ambassadorBio ? (
            <div
              className={cx(
                "o-final-container__modal__inside__profile-summary"
              )}
            >
              <TextTruncate text={ambassadorBio} />
            </div>
          ) : (
            <></>
          )}
          <div className={cx("o-final-container__modal__inside__residence")}>
            {nationality && (
              <div
                className={cx(
                  "o-final-container__modal__inside__residence__area"
                )}
              >
                <HouseIcon />

                <p>{nationality}</p>
              </div>
            )}

            {current_city && (
              <div
                className={cx(
                  "o-final-container__modal__inside__residence__area"
                )}
              >
                <LocationIcon />

                <p>{current_city}</p>
              </div>
            )}
          </div>
          {isStaffAccountType(account_type, user_type, false) && resume_bio && (
            <div
              className={cx("o-final-container__modal__inside__profile-about")}
            >
              <p>{resume_bio?.length > 130 ? `${getBio()}` : resume_bio}</p>
              {resume_bio?.length > 130 && (
                <span onClick={handleShow}>{`Show ${
                  showMore ? "more" : "less"
                }`}</span>
              )}
            </div>
          )}

          {linkedin_public_url && (
            <div
              className={cx(
                "o-final-container__modal__inside__profile-linkedin"
              )}
            >
              <div>
                <img
                  src={LinkedIn}
                  onClick={() => window.open(linkedin_public_url, "_blank")}
                  alt="LinkedIn"
                />
              </div>
              <div>LinkedIn</div>
            </div>
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default memo(ProfilePreview);
