import React, { memo, useState } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";

import { useAppState } from "../../context";
import { toastify } from "../../helper/helper";
import {
  authenticateGoogleSignin,
  disconnectAccountGoogle,
} from "../../services/profile.service";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import { CustomDialogBox } from "../Profile/ProfileInfo/ProfileInfoStyles";
import { updateUsersPersistState } from "../../redux/reducers/userReducer";

import googleDashboardIcon from "../../assets/svg/f-google-logo.svg";
import googleDashboardIconTick from "../../assets/svg/f-google-synced.svg";
import googleWithoutBgIcon from "../../assets/svg/googleIcon.svg";

const AuthGoogle = ({
  isGoogleConnected = false,
  isDashboardWidget = false,
  setIsUserConnected = () => {},
  isUserConnected = {},
  isDisabled = false,
  ariaLabelledby = "",
}) => {
  const dispatch = useDispatch();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const { updateIsGoogleConnected } = useAppState("profile");

  const url = new URL(window.location.href);

  const onSuccessLogin = async (response) => {
    const { code } = response ?? {};

    // Send code to backend
    try {
      const response = await authenticateGoogleSignin(code, url.origin);
      if (response.success) {
        toastify("success", "Successfully connected to google");
        setIsUserConnected({ ...isUserConnected, google: true });
        updateIsGoogleConnected(response.data.is_google_signed_in);
        dispatch(updateUsersPersistState({ is_google_connected: true }));
      }
    } catch (error) {
      console.error(error);
      setIsUserConnected({ ...isUserConnected, google: true });
      dispatch(updateUsersPersistState({ is_google_connected: true }));
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
      dispatch(updateUsersPersistState({ is_google_connected: false }));
    }
  };

  const onFailLogin = (response) => {
    const { error } = response ?? {};

    let message = "";
    switch (error) {
      case "popup_closed_by_user":
        message = "Authentication process rejected by user";
        break;
      case "access_denied":
        message = "User denied the permission to the scopes required";
        break;
      case "immediate_failed":
        message =
          "No user could be automatically selected without prompting the consent flow.";
        break;
      case "idpiframe_initialization_failed":
        message = response?.details
          ? response.details
          : "Oops! something went wrong";
        break;
      default:
        message = "Oops! something went wrong";
    }
    if (error) {
      toastify("error", message);
    }
  };

  const onSuccessLogout = async () => {
    try {
      const response = await disconnectAccountGoogle();
      if (response.success) {
        toastify("success", response.message);
        setShowDisconnectModal(false);
        updateIsGoogleConnected(false);
        dispatch(updateUsersPersistState({ is_google_connected: false }));
        googleLogout();
      }
    } catch (error) {
      setShowDisconnectModal(false);
      console.error(error);
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
      googleLogout();
    }
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: onSuccessLogin,
    onError: onFailLogin,
    scope: "openid profile email",
    redirect_uri: url.origin,
    use_fedcm_for_prompt: true,
  });

  return (
    <>
      {isDashboardWidget ? (
        isGoogleConnected ? (
          <>
            <div className="widgets-item">
              <button aria-labelledby={ariaLabelledby}>
                <div
                  style={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src={googleDashboardIconTick}
                    alt="Linked In"
                    className="icon"
                  />
                </div>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="widgets-item" onClick={login}>
              <button
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  <img
                    src={googleDashboardIcon}
                    alt="Linked In"
                    className="icon"
                  />
                </div>
              </button>
            </div>
          </>
        )
      ) : isGoogleConnected ? (
        <a
          href="/#"
          className="google-sso-connect-v1"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowDisconnectModal(true);
          }}
          aria-labelledby={ariaLabelledby}
        >
          <div className="google-sso-connect-v1-img">
            <img src={googleWithoutBgIcon} alt="Google" />
          </div>
          <span className="google-sso-connect-v1-text">Disconnect Google</span>
        </a>
      ) : (
        <a
          href="/#"
          className="google-sso-connect-v1"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            login();
          }}
          aria-labelledby={ariaLabelledby}
        >
          <div className="google-sso-connect-v1-img">
            <img src={googleWithoutBgIcon} alt="Google" />
          </div>
          <span className="google-sso-connect-v1-text">Connect Google</span>
        </a>
      )}
      <CustomDialogBox
        open={showDisconnectModal}
        title={"Are you sure you want to disconnect google account?"}
        handleClose={() => setShowDisconnectModal(false)}
        isDisplayCloseButton={true}
        className="profile-accessibility-popup"
        dialogTitleClass="modal-title"
        dialogSubTitleClass="text-para"
      >
        <div className="modal-disconnect-google-button-wrapper">
          <div className="modal-disconnect-google-button-wrapper__button">
            <ButtonCustom onClick={onSuccessLogout}>Disconnect</ButtonCustom>
          </div>
        </div>
      </CustomDialogBox>
    </>
  );
};

export default memo(AuthGoogle);
