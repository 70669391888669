import cls from "classnames";
import { isNull } from "lodash";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { Checkbox } from "@mui/material";

import { checkInvalidValue } from "../../helper/helper";
import {
  FIELD_LABEL_COLOR,
  GRAY_TEXT_COLOR,
  ICON_BACKGROUND,
  PRIMARY_DARK_COLOR,
  WHITE_COLOR,
} from "../../styles/variables";
import DropdownStyles from "./DropdownStyles";

import Clear from "../../assets/svg/Clear";
import chevArrowDownIcon from "../../assets/svg/chevArrowDown.svg";
import chevArrowUpIcon from "../../assets/svg/chevArrowUp.svg";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  const { value = null, placeholder = "Select" } = props.selectProps ?? {};

  const noValue =
    checkInvalidValue(value) ||
    (checkInvalidValue(value.label) && checkInvalidValue(value.value));

  return (
    <ValueContainer {...props}>
      {noValue && <Placeholder {...props}>{placeholder}</Placeholder>}

      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const MultiValueContainer = ({ children, ...props }) => {
  const childrenArray = React.Children.toArray(children);
  // This input children is mandatory to handle close/open event of dropdown
  const inputChildren = childrenArray.filter((child) =>
    [components.Input].includes(child.type)
  );
  const chipsChildren = childrenArray.filter(
    (child) => ![components.Input].includes(child.type)
  );

  const maxToShow = 1;
  const displayChips = chipsChildren.slice(0, maxToShow);
  const length = props.selectProps?.value?.length ?? 0;
  const shouldBadgeShow = length > maxToShow;
  const displayLength = length - maxToShow;
  const searchText = inputChildren?.[0]?.props?.value ?? "";

  return (
    <ValueContainer {...props}>
      {length === 0 && searchText.length === 0 ? (
        <Placeholder {...props}>
          {props.selectProps.placeholder ?? "Select"}
        </Placeholder>
      ) : (
        <>
          {displayChips}
          <div className="multi-select-count">
            {shouldBadgeShow && `+ ${displayLength}`}
          </div>
        </>
      )}
      {inputChildren}
    </ValueContainer>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {!props.isDisabled ? (
        <img
          alt="Dropdown arrow"
          src={
            props.selectProps.menuIsOpen
              ? chevArrowUpIcon
              : props.isFocused
              ? chevArrowDownIcon
              : chevArrowDownIcon
          }
        />
      ) : (
        <></>
      )}
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <Clear color={"rgb(204, 204, 204)"} height={20} width={20} />
    </div>
  );
};

const MultiSelectOption = (props) => {
  return (
    <div className="multi-select-option">
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          onChange={() => null}
          disableRipple={true}
          sx={{
            color: ICON_BACKGROUND,
            borderColor: ICON_BACKGROUND,
            "&.Mui-checked": {
              color: props.isFocused ? WHITE_COLOR : PRIMARY_DARK_COLOR,
              borderColor: WHITE_COLOR,
            },
          }}
        />
        <label
          className={`multi-select-label ${
            props.isFocused
              ? "multi-select-label-focused"
              : props.isSelected
              ? "multi-select-label-selected"
              : ""
          }`}
        >
          {props.label}
        </label>
      </components.Option>
    </div>
  );
};

const DropdownCustom = ({
  onClick = () => {},
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {},
  onInputChange = () => {},
  loadOptions = () => {},
  className,
  name,
  value,
  defaultValue = null,
  placeholder,
  width = "100%",
  height = 50,
  label = "",
  customLabelComponent = null,
  showLabelAtTop = true,
  options = [],
  justifyContent = "center",
  isMulti = false,
  isDisabled = false,
  error = false,
  errorText = "",
  validationError = "",
  touched = false,
  isAsync = false,
  required = false,
  inputId = null,
  autoFocus = false,
  noOptionsMessage = "No options",
  validationErrorStyles = {},
  showStarIcon = true,
  placeholderColor = false,
  ariaLabelledby,
  labelClass,
  isClearable = false,
  title = "",
  ...props
}) => {
  const colourStyles = {
    multiValue: (styles) => ({
      ...styles,
      background: "#E5EAF7",
      borderRadius: "10px",
      color: PRIMARY_DARK_COLOR,
      maxWidth: "78%",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: PRIMARY_DARK_COLOR,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: PRIMARY_DARK_COLOR,
      ":hover": {
        backgroundColor: "#E5EAF7",
        color: PRIMARY_DARK_COLOR,
        borderRadius: "10px",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      justifyContent: justifyContent,
    }),
    clearIndicator: (styles) => ({ ...styles, padding: "8px 0" }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    menu: (styles) => ({
      ...styles,
      border: "2px solid var(--gray-outline-color)",
      borderRadius: "10px",
      backgroundColor: "#FFFFFF",
      boxShadow: "var(--card-box-shadow)",
      padding: "0px 0px 0px 0px !important",
      overflow: "hidden",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "7px",
        boxSizing: "border-box",
      },
      "::-webkit-scrollbar-track": {
        background: "white",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        background: "rgba(0, 0, 0, 0.2)",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: "white",
      width: `${width}px`,
      minHeight: `${height}px`,
      height: "max-content",
      border: "2px solid var(--gray-outline-color)",
      borderRadius: "45px",
      color: "var(--gray-text-color)",
      fontFamily: "TTCommons-Regular",
      fontSize: "18px",
      fontWeight: "400",
      letterSpacing: "0",
      lineHeight: "20px",
      borderColor: isFocused ? "var(--field-label-color) !important" : "",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: isFocused ? "0px 0px 0px 0px" : "0px",
        padding: "12px 20px 8px 20px",
        backgroundColor: isDisabled
          ? "#fff"
          : isSelected && !isFocused
          ? "#fff"
          : isFocused
          ? "var(--primary-dark-color)"
          : null,
        color: isDisabled
          ? PRIMARY_DARK_COLOR
          : isSelected && !isFocused
          ? PRIMARY_DARK_COLOR
          : isFocused
          ? "#fff"
          : PRIMARY_DARK_COLOR,
        fontSize: "18px",
        fontFamily: "TTCommons-Regular",
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: "20px",
        cursor: isDisabled ? "not-allowed" : "default",
        justifyContent: justifyContent,
        textAlign: justifyContent,
        display: "flex",
        ":active": {
          ...styles[":active"],
        },
      };
    },
    input: (styles) => ({
      ...styles,
      textAlign: justifyContent,
      color: "var(--gray-text-color)",
      fontFamily: "TTCommons-Regular",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: !showLabelAtTop
        ? PRIMARY_DARK_COLOR
        : placeholderColor
        ? FIELD_LABEL_COLOR
        : "",
      fontFamily: "TTCommons-Regular",
      textAlign: justifyContent,
    }),
    singleValue: (styles, { isDisabled }) => ({
      ...styles,
      justifyContent: justifyContent,
      color: isDisabled ? GRAY_TEXT_COLOR : PRIMARY_DARK_COLOR,
      fontFamily: "TTCommons-Regular",
      opacity: isDisabled ? 0.6 : 1,
      minWidth: "calc(100% - 8px)",
    }),
  };

  const id = Math.random();

  useEffect(() => {
    let input = document.getElementById(`dropdown-input-${id}`);
    if (input) input.setAttribute("aria-required", required);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputChangeVal, setInputChangeVal] = useState("");
  const handleInputChange = (e) => {
    onInputChange(e);
    setInputChangeVal(e);
  };

  const CustomNoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {noOptionsMessage}
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className="field-group">
      <DropdownStyles>
        {customLabelComponent ? (
          customLabelComponent
        ) : label && showLabelAtTop ? (
          <label
            className={cls([
              "textbox-label",
              "text-primary",
              labelClass ? labelClass : "",
            ])}
          >
            {`${label}${required && showStarIcon ? "*" : ""}`}
          </label>
        ) : (
          <></>
        )}
        {isAsync ? (
          <AsyncSelect
            components={{
              DropdownIndicator,
              ClearIndicator,
              ...(isMulti
                ? {
                    Option: MultiSelectOption,
                    ValueContainer: MultiValueContainer,
                  }
                : {}),
            }}
            isClearable={isClearable}
            captureMenuScroll={false}
            cacheOptions={false}
            className={`react-custom-dropdown ${isMulti && "multi-select"}`}
            name={name}
            options={options}
            loadOptions={loadOptions}
            styles={colourStyles}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            placeholder={placeholder || label}
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: "var(--primary-dark-color)",
                primary25: "var(--primary-dark-color)",
                primary: "var(--primary-dark-color)",
              },
            })}
            aria-label={placeholder || label}
            inputId={`dropdown-input-${id}`}
            autoFocus={autoFocus}
            {...(isMulti
              ? {
                  closeMenuOnSelect: false,
                  hideSelectedOptions: false,
                }
              : {})}
            {...props}
          />
        ) : (
          <Select
            defaultMenuIsOpen={false}
            components={{
              DropdownIndicator,
              ClearIndicator,
              NoOptionsMessage: CustomNoOptionsMessage,
              ...(isMulti
                ? {
                    Option: MultiSelectOption,
                    ValueContainer: MultiValueContainer,
                  }
                : {
                    ValueContainer: CustomValueContainer,
                  }),
            }}
            isClearable={isClearable}
            captureMenuScroll={false}
            className={`react-custom-dropdown ${className} ${
              isMulti && "multi-select"
            }`}
            name={name}
            options={options}
            styles={colourStyles}
            onClick={onClick}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={isDisabled}
            onInputChange={(e) => handleInputChange(e)}
            value={value}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            placeholder={
              inputChangeVal ? "" : placeholder ? placeholder : label
            }
            isMulti={isMulti}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary50: "var(--primary-dark-color)",
                primary25: "var(--primary-dark-color)",
                primary: "var(--primary-dark-color)",
              },
            })}
            aria-label={placeholder ?? title ?? label}
            aria-labelledby={ariaLabelledby ? ariaLabelledby : ""}
            inputId={!isNull(inputId) ? inputId : `dropdown-input-${id}`}
            isOptionDisabled={(option) => option.isDisabled}
            autoFocus={autoFocus}
            title={title}
            {...(isMulti
              ? {
                  closeMenuOnSelect: false,
                  hideSelectedOptions: false,
                }
              : {})}
            aria-live="polite"
          />
        )}

        <div
          aria-live="polite"
          aria-atomic="true"
          className="accessibility-hidden-element"
        >
          {isMulti
            ? `Selected: ${value?.length ?? 0} items`
            : value
            ? `Selected: ${value?.label ?? value}`
            : ""}
        </div>

        {!!error && touched && (
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            id="email-helper-text"
            role="alert"
            aria-live="assertive"
          >
            {error}
          </p>
        )}
        {validationError !== "" && (
          <p
            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            id="email-helper-text"
            role="alert"
            aria-live="assertive"
            style={{ ...validationErrorStyles }}
          >
            {validationError}
          </p>
        )}
      </DropdownStyles>
    </div>
  );
};

export default DropdownCustom;
