import React from "react";
import Rating from "@mui/material/Rating";
import { StarBorder, Star } from "@mui/icons-material";
import CustomRatingStyles from "./CustomRatingStyles";

const renderPoint = ({ value, className }) => {
  return (
    <span
      className={`${className} point ${
        className.includes("MuiRating-iconFilled") && "active"
      } cursor-pointer`}
    >
      {value}
    </span>
  );
};

const CustomRating = ({
  start = 0,
  stop = 5,
  emptySymbol = <StarBorder fontSize="large" color="primary" />,
  fullSymbol = <Star fontSize="large" color="primary" />,
  fractions = 1,
  value,
  onClick = () => {},
  type = "stars", // "stars" or "points"
  highlightSelectedOnly = false,
  ...props
}) => {
  return (
    <CustomRatingStyles>
      <Rating
        defaultValue={start}
        precision={fractions}
        max={stop}
        icon={fullSymbol}
        emptyIcon={emptySymbol}
        value={value}
        onChange={(e, rate) => onClick(rate)}
        IconContainerComponent={type === "points" ? renderPoint : null}
        highlightSelectedOnly={highlightSelectedOnly}
        getLabelText={(value) => `${value} Star${value !== 1 ? "s" : ""}`}
        aria-labelledby="rating-label"
        {...props}
      />
      <div
        id="rating-label"
        aria-live="polite"
        className="accessibility-hidden-element"
      >
        Selected rating: {value} stars
      </div>
    </CustomRatingStyles>
  );
};
export default CustomRating;
