import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import SavedNetworkStyles from "./SavedNetworkStyles";
import MainLayout from "../../../components/Layouts/MainLayout/MainLayout";
import { routes } from "../../../routes";
import BreakpointMasonry from "../../../components/BreakpointMasonry/BreakpointMasonry";
import { useAppState } from "../../../context";
import usePromise from "../../../hooks/usePromise/usePromise";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import {
  getFavoriteNetworksAPI,
  favorite_Unfavorite_NetworksAPI,
} from "../../../services/networkServices";
import { InfoPanel } from "../../../components/InfoPanel/InfoPanel";
import { createFreshChatPreview } from "../../../utils/common";
import { toastify } from "../../../helper/helper";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";

import FallbackImage from "../../../assets/svg/saved-posts-fallback-image.svg";
import BackArrow from "../../../assets/svg/f-btn-arrow-gray.svg";

const SavedNetwork = () => {
  const navigate = useNavigate();
  const { favoriteUsers = [] } = useSelector((store) => store.networkStore);

  const {
    getChatContactListItem,
    getChatPreviewByReceiverId,
    chats,
    setChats,
    setConversationInformation,
    setCurrentConversationId,
    createFreshChatRecord,
    setFavoriteUsersCount,
  } = useAppState("chat");

  const [callingGetFavoriteNetworksAPI, refreshGetFavoriteNetworksAPI] =
    usePromise(getFavoriteNetworksAPI);
  const [showLoader, setLoader] = useState(false);
  const [favUsers, setFavUsers] = useState([]);
  const [
    callingFavorite_Unfavorite_NetworksAPI,
    refreshFavorite_Unfavorite_NetworksAPI,
  ] = usePromise(favorite_Unfavorite_NetworksAPI);
  useEffect(() => {
    setLoader(true);
    refreshGetFavoriteNetworksAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessage = (item) => {
    try {
      const { id } = item;
      const user = getChatContactListItem(id, favUsers);
      const chat_preview = getChatPreviewByReceiverId(id);
      if (chat_preview) {
        setCurrentConversationId(chat_preview.conversation_id);
        // redirect to chat
        navigate("/network/messenger", { state: { userid: id } });
        return;
      }

      const randomConversationId = new Date().getTime();
      const { preview, conversation } = createFreshChatPreview(
        user,
        randomConversationId
      );
      setChats([preview, ...chats]);
      setConversationInformation(randomConversationId, conversation);
      setCurrentConversationId(randomConversationId);
      createFreshChatRecord(randomConversationId); // we will track by this id
      //redirect to chat page
      navigate("/network/messenger", { state: { userid: id } });
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        "Something went wrong. Please try again later or contact us for support."
      );
    }
  };

  const handleFavorite = (e, userId) => {
    if (e) e.stopPropagation();
    refreshFavorite_Unfavorite_NetworksAPI(userId);
  };

  useEffect(() => {
    if (
      callingGetFavoriteNetworksAPI.hasFetched() &&
      callingGetFavoriteNetworksAPI.hasErrors() === false &&
      callingGetFavoriteNetworksAPI.data() &&
      callingGetFavoriteNetworksAPI.data().data
    ) {
      const { favourite_list = [] } = callingGetFavoriteNetworksAPI.data().data;
      setFavoriteUsersCount(favourite_list?.length);
      setFavUsers(favourite_list);
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetFavoriteNetworksAPI.isFetching()]);

  useEffect(() => {
    if (
      callingFavorite_Unfavorite_NetworksAPI.hasFetched() &&
      callingFavorite_Unfavorite_NetworksAPI.hasErrors() === false
    ) {
      toastify(
        "success",
        callingFavorite_Unfavorite_NetworksAPI.data().message
      );
      refreshGetFavoriteNetworksAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingFavorite_Unfavorite_NetworksAPI.isFetching()]);

  return (
    <SavedNetworkStyles>
      <MainLayout
        breadCrumb={[
          { title: "Home", path: routes.NETWORK },
          { title: "Saved Network", path: routes.NETWORK.SAVED_NETWORK },
        ]}
        viewOnlyNavbar={true}
      >
        <div className="custom-container">
          <div className="savedposts-content">
            <div className="f-main-title-with-button position-absolute mt-20 mb-20">
              <div className="back-btn-wrapper">
                <a
                  className="shadow-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(routes.NETWORK.DISCOVER);
                  }}
                  href="/#"
                  aria-label="Back to Discover"
                >
                  <img
                    src={BackArrow}
                    id="BackArrow"
                    width="18px"
                    alt="Back arrow"
                  />
                  <span>Back to Discover</span>
                </a>
              </div>
            </div>
            <div className="saved-posts-wrapper">
              <div className="title-boxs">
                <h1 className="title-primary mb-15">Saved network</h1>
              </div>
              {showLoader ? (
                <Grid spacing={2} container>
                  <JobsListingEffect count={4} />
                </Grid>
              ) : favUsers?.length > 0 ? (
                <BreakpointMasonry grid={1}>
                  {favUsers.map((item, index) => (
                    <InfoPanel
                      key={index}
                      index={index}
                      avatar={item.user_profile}
                      userAvatar={true}
                      title={item.full_name}
                      subTitle={
                        item && item.major && item.graduation_year
                          ? [
                              `${item.major}`,
                              `Class of ${item.graduation_year}`,
                            ]
                          : []
                      }
                      tags={item.user_type !== "" ? item.user_type : []}
                      isFavorites={
                        favoriteUsers.includes(item.id) ? true : false
                      }
                      onClick={(e) => handleFavorite(e, item.id)}
                      variant="discover-network"
                      action={
                        <>
                          <ButtonCustom
                            height={30}
                            width={100}
                            color="secondary"
                            onClick={(e) => {
                              e?.stopPropagation && e.stopPropagation();
                              e?.preventDefault && e.preventDefault();
                              handleMessage(item);
                            }}
                            aria-label={`Message ${item.full_name}`}
                          >
                            Message
                          </ButtonCustom>
                        </>
                      }
                    />
                  ))}
                </BreakpointMasonry>
              ) : (
                <EmptyListWithImage
                  Image={FallbackImage}
                  whoops
                  title="Looks like you don’t have any saved network here."
                  containerClassname={"empty-saved-posts"}
                />
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </SavedNetworkStyles>
  );
};

export default SavedNetwork;
