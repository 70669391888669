import React, { useState, memo, useEffect, useMemo } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Badge } from "../Badge/Badge";
import styles from "./Accordian.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { PRIMARY_COLOR } from "../../../styles/variables";
import { setTabIndexForElements } from "../../../helper/helper";

const cx = createModuleStyleExtractor(styles);

const Accordian = ({
  label,
  children,
  completed = false,
  state,
  handleOnChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(state || false);

  const section = useMemo(() => {
    return label === "Education"
      ? 1
      : label === "Career"
      ? 2
      : label === "Experience"
      ? 3
      : 0;
  }, [label]);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
    handleOnChange(section);
  };

  useEffect(() => {
    setIsExpanded(state);
  }, [state]);

  useEffect(() => {
    setTabIndexForElements(`accordion-details-${label}`, isExpanded);
  }, [isExpanded, label]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      id={`profile-${label}`}
      className={"profile-accordian-mui"}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: PRIMARY_COLOR }} />}
        aria-controls={`${label}-summary`}
        aria-label={`${label} ${completed ? "Completed" : "Incomplete"}`}
        classes={{
          focused: "focused-class",
        }}
      >
        <div className={cx("accordian-title-wrapper")}>
          <div className={cx("accordian-title-wrapper__inside")}>
            <h2 className="title-primary">{label}</h2>
            <Badge complete={completed} />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="profile-accordian-mui__details"
        aria-hidden={!isExpanded}
        tabIndex={isExpanded ? section : -1}
        id={`accordion-details-${label}`}
      >
        <div
          className="profile-accordian-mui__details-child"
          style={{ padding: "0 20px 0 20px" }}
        >
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default memo(Accordian);
