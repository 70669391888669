import { CheckCircleRounded } from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import EditableDropdown from "../../../../components/DropdownCustom/EditableDropdown";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { useAppState } from "../../../../context";
import {
  base64ToFile,
  checkIsBYUUser,
  NoUserImage,
  toastify,
  validateAttribute,
} from "../../../../helper/helper";
import { createModuleStyleExtractor } from "../../../../utils/css";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CropperPic from "../../../Cropper";
import CustomDialog from "../../../CustomDialog/CustomDialog";
import TextboxCustom from "../../../Textbox/TextboxCustom";
import { defaultState, reducer } from "../../state/reducer";
import ConnectLinkedin from "../ConnectLinkedin/ConnectLinkedin";
import EditEmail from "../EditEmail/EditEmail";
import UploadImage from "../UploadImage/UploadImage";
import styles from "./PersonalForm.module.scss";

import { config } from "../../../../constant/config";
import { capitalizeFirstLetter } from "../../../../helper/helper";
import { useUpdateProfile } from "../../../../hooks/Profile/useUpdateProfile";
import {
  removeUserProfilePic,
  updateUserProfilePic,
} from "../../../../services/authenticationServices";
import {
  changeSecondaryToPrimary,
  closeAccount,
  resendSecondaryEmailVerification,
  updateProfileImage,
} from "../../../../services/profile.service";
import { PRIMARY_COLOR } from "../../../../styles/variables";
import {
  validateName,
  cacheTimezone,
  countries,
  isStaffAccountType,
  signOutCall,
  validProfileImage,
  validateEmail,
  validatePhoneNumber,
  PROFILE_PREFERENCE_OPTIONS,
  getSecondaryEmailRequired,
} from "../../../../utils/common";
import AuthGoogleProfile from "../../../AuthGoogle/AuthGoogleProfile";
import { pronounOptions } from "../../../../constant/mockdata";

import { SecondaryEmailConfirmDialog } from "../../../SecondaryEmailConfirmDialog/SecondaryEmailConfirmDialog";
import { CoverHeader } from "../../../atoms/CoverHeader/CoverHeader";
import Textarea from "../../../atoms/Textarea/Textarea";
import { ProfileCheckbox } from "../../Checkbox/Checkbox";
import { LineDivider } from "../../Divider/Divider";
import EditPassword from "../EditPassword/EditPassword";
import PhoneNumberSelect from "../../../PhoneNumberSelect/PhoneNumberSelect";
import { resetOPTInitialState } from "../../../../redux/reducers/optReducer";
import { updatePersistState } from "../../../../redux/reducers/userReducer";
import InfoIconTooltip from "../../../InfoIconTooltip/InfoIconTooltip";

const cx = createModuleStyleExtractor(styles);

export const PersonalForm = () => {
  const dispatchRedux = useDispatch();
  const {
    essentials,
    setLocalPersonalForm,
    setLocalPersonalFormInfo,
    setLocalPersonalFormInfoArray,
    setLocalPersonalFormMultiInfo,
    personalForm,
    profile,
    setLocalEducationForm,
    educationsForm,
    updateProfileState,
    resetProfileState,
  } = useAppState("profile");

  const { users = {} } = useSelector((store) => store.userStore);

  const { id: userId, admission_user_type } = users || {};

  const { account_types: accountTypes } = essentials || {};
  const [currentCountryCode, setCurrentCountryCode] = useState("");

  const [state, dispatch] = useReducer(reducer, defaultState);
  // const { accessibility_help } = state || {};
  const [imageUpdating, setIsImageUpadating] = useState(false);
  const [switchEmail, setSwitchEmail] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const {
    isProfileCompleted,
    personal,
    accessibility_help,
    is_network_popup_shown,
  } = profile || {};
  const [profileVisibility, setProfileVisibility] = useState(
    is_network_popup_shown
  );
  const {
    first_name,
    last_name,
    email,
    nationality,
    current_city,
    account_type,
    user_type,
    provider,
    timezone_id,
    time_zone,
    secondary_email,
    is_secondary_email_verified,
    is_google_connected,
    intro,
    phone_number,
    country_code,
    student_view_preference,
    pronoun,
  } = personalForm || {};
  const { handleVisibilitySave } = useUpdateProfile();
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const {
    ref,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: config().GOOGLE_SERVICE_API_KEY,
    options: {
      types: ["(cities)"],
      componentRestrictions: {
        country: currentCountryCode?.toLowerCase(),
      },
    },
    defaultValue: current_city || "",
  });

  const IsBYUUser = useMemo(() => {
    return checkIsBYUUser(users?.school_id);
  }, [users]);

  const IsSecondaryEmailRequired = useMemo(() => {
    return getSecondaryEmailRequired(users?.school_id);
  }, [users]);

  useEffect(() => {
    if (!timezones?.length) {
      setTimezones(
        JSON.parse(sessionStorage.getItem("get_rails_timezone"))?.timezones
      );
    } else {
      cacheTimezone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof timezones !== "undefined") {
      timezones.find((i) => {
        if (i.id === timezone_id || i.timezone_name_label === time_zone) {
          setTimezoneLabel({
            label: i.timezone_name_label,
            value: i.timezone_name_label,
          });
        }
        return i.id === timezone_id || i.timezone_name_label === time_zone;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones]);

  const updateReducerState = (key, value, type = "state.update") => {
    dispatch({ type, key, value });
  };
  const updateFormReducer = (key, value) => {
    setLocalPersonalFormInfo(key, value);
  };

  const updateFormReducerArray = (info) => {
    setLocalPersonalFormInfoArray(info);
  };

  const updateImage = async (file, remove = false) => {
    //update via API
    try {
      setIsImageUpadating(true);
      if (remove) {
        let removeApiResponse = await removeUserProfilePic();
        if (removeApiResponse?.success) {
          updateProfileState({
            personalForm: {
              ...personalForm,
              profile_image_url: "",
            },
            personal: {
              ...personal,
              profile_image_url: "",
            },
          });
          dispatch(
            updatePersistState({ users: { ...users, profile_image_url: "" } })
          );
        }
        setIsImageUpadating(!true);
      } else {
        const formData = new FormData();
        dispatch({
          type: "state.bulk.update",
          payload: { isEditImage: false, cropperImage: "" },
        });
        formData.append("user_profile", base64ToFile(file, "hello.txt"));
        formData.append("remove", remove);

        //Todo: remove this once updated api is used acorss the whole portal
        const _formData = new FormData();
        _formData.append("user[user_profile]", base64ToFile(file, "hello.txt"));
        const [newApiResponse] = await Promise.all([
          updateProfileImage(formData),
          updateUserProfilePic(userId, _formData),
        ]);

        if (newApiResponse?.success) {
          updateProfileState({
            personalForm: {
              ...personalForm,
              profile_image_url: newApiResponse?.profile_image_url,
            },
            personal: {
              ...personal,
              profile_image_url: newApiResponse?.profile_image_url,
            },
          });
          dispatch(
            updatePersistState({
              users: {
                ...users,
                profile_image_url: newApiResponse?.profile_image_url,
              },
            })
          );
        }
        setIsImageUpadating(!true);
      }
    } catch (error) {
      setIsImageUpadating(false);
    }
  };
  const getCropData = async (file, url) => {
    updateReducerState("avatar", url);
    if (file && url) {
      await updateImage(url);
    }
  };

  const cities = useMemo(() => {
    const uniqueCities = placePredictions
      .map((city) => city.structured_formatting.main_text)
      .filter((item, ind, self) => {
        return self.indexOf(item) === ind;
      })
      .map((e) => ({ label: e, value: e }));
    if (uniqueCities.length > 0) return uniqueCities;
  }, [placePredictions]);

  const handleCallback = useCallback((files) => {
    const item = files[0];
    const response = validProfileImage(item);
    if (response !== 200) {
      toastify("error", response);
      return;
    }
    //Todo: update with reducer
    //setaccessibility_help(accessibility_help);
    if (accessibility_help === "true") {
      updateReducerState("profilePicURL", URL.createObjectURL(item));

      // setUserProfileAttachment(item);
    } else {
      updateReducerState("cropperImage", URL.createObjectURL(item));
    }
    dispatch({ type: "state.update", key: "isEditImage", value: !false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAccountType = (value) => {
    const interstrideEducation = educationsForm[0];
    updateFormReducer("account_type", value);

    const _educations = users?.educations.map((item) =>
      item?.id === interstrideEducation?.id
        ? {
            ...item,
            graduation_year: "",
            graduation_date: "",
          }
        : item
    );
    dispatch(
      updatePersistState({
        users: {
          ...users,
          graduation_date: "",
          graduation_year: "",
          educations: _educations,
        },
      })
    );
    setLocalEducationForm(_educations);
  };

  const handleAccountClose = async () => {
    try {
      const response = await closeAccount();
      const { success, message } = response || {};
      if (success) {
        toastify("success", message);
        // Logout the user
        if (users) {
          dispatchRedux(resetOPTInitialState());
          resetProfileState();
          signOutCall(users.school_id);
        }
      }
    } catch (error) {
      const { data } = error;
      toastify("error", data?.message ?? "Oops! Something went wrong");
    }
  };

  const handleEmailSwitch = async () => {
    setSwitchEmail(false);
    try {
      const response = await changeSecondaryToPrimary(secondary_email);
      const { success, message } = response || {};
      if (success) {
        toastify("error", message ?? "Oops! Something went wrong");
        // Logout the user
        if (users != null) {
          dispatchRedux(resetOPTInitialState());
          resetProfileState();
          signOutCall(users.school_id);
        }
      }
    } catch (error) {
      const { data } = error;
      toastify("error", data?.message ?? "Oops! Something went wrong");
    }
  };

  const onEmailSwitch = async () => {
    if (!secondary_email) {
      toastify("error", "Please enter secondary email first");
      return;
    }
    if (secondary_email && !is_secondary_email_verified) {
      toastify(
        "error",
        `Please verify your email first. Verification email already sent at ${secondary_email}`
      );
      return;
    }
    // Prompt user to set password first when switching emails for SSO users
    if (personal.is_sso_user && !personal.is_password_set) {
      dispatch({
        type: "state.update",
        key: "isPassword",
        value: true,
      });
      return;
    }
    setSwitchEmail(true);
  };

  const handleVerifySecondaryEmail = async () => {
    // Call ReVerify Email API
    try {
      const response = await resendSecondaryEmailVerification();
      if (response.success) {
        toastify("success", response.message ? response.message : "Success");
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
    }
  };

  const secondaryInputLabel = () => {
    if (!personal?.secondary_email) return "";
    if (!personal?.is_secondary_email_verified) return "Verify email";
    return "Make primary";
  };
  const LabelWithInfoIcon = () => {
    return (
      <div className={cx("custom-secondary-email")}>
        <span>Secondary email{IsSecondaryEmailRequired ? "*" : ""}</span>

        <span className={cx("secondary-email-check-circle")}>
          {is_secondary_email_verified ? (
            <CheckCircleRounded fontSize="1rem" />
          ) : (
            <InfoIconTooltip
              margin="0"
              placement="bottom"
              aria-label="Secondary email"
              content={
                personal?.secondary_email &&
                !personal?.is_secondary_email_verified
                  ? `Please verify your secondary email. Verification email is already sent at ${personal?.secondary_email}`
                  : "If you lose your .edu email address upon graduation, you may use your secondary email to sign in to this account."
              }
            />
          )}
        </span>
      </div>
    );
  };
  useEffect(() => {
    if (nationality) {
      const country = countries.find((item) => item.label === nationality);
      country?.value && setCurrentCountryCode(country.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationality]);

  let formattedTimezones = [];
  if (timezones) {
    formattedTimezones = (timezones ?? []).map((item) => {
      return {
        label: item.timezone_name_label,
        value: item.timezone_name_value,
        id: item.id,
        timezone_short: item.timezone_short,
      };
    });
  }

  const handleChangeVisibility = () => {
    setLocalPersonalForm(personalForm);
    setProfileVisibility((current) => !current);
    updateProfileState({ is_network_popup_shown: !is_network_popup_shown });
    handleVisibilitySave(
      !is_network_popup_shown,
      !profileVisibility,
      personalForm
    );
  };

  const notStaff = isStaffAccountType(account_type, user_type, false);
  const isStaff = isStaffAccountType(account_type, user_type, true);
  // Profile Details Form Elements
  const profileDetailsElements = (
    <div
      className={cx(
        isProfileCompleted
          ? "profile-details__complete"
          : "profile-details__incomplete"
      )}
    >
      <div>
        {/* Profile Image */}
        <UploadImage
          user={personalForm}
          handleCallback={handleCallback}
          onClear={() => updateImage("", true)}
          imageUpdating={imageUpdating}
        />
      </div>
      <div>
        {/* Account Type */}
        <DropdownCustom
          label="Account type"
          name="Account type"
          options={
            accountTypes?.map((item) => {
              return {
                label:
                  item === "staff"
                    ? "Staff & Faculty"
                    : capitalizeFirstLetter(item),
                value: item,
              };
            }) || []
          }
          onChange={(item) => handleAccountType(item.value)}
          isSearch
          required
          value={
            isStaff
              ? { label: "Staff & Faculty", value: "staff" }
              : account_type
              ? {
                  label: capitalizeFirstLetter(account_type),
                  value: account_type,
                }
              : ""
          }
          containerClassName={
            account_type ? "a-dropdown-container__options__valid" : ""
          }
          placeholder="Account type"
          error={!account_type && "Account type required"}
          className={`profile-react-custom-dropdown ${
            account_type ? "border-gray" : ""
          }`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
        />
      </div>
      <div className="form-field-group">
        {/* First Name */}
        <TextboxCustom
          label={"First name*"}
          name={"First name"}
          className={
            first_name
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          placeholder={"First name"}
          lableaignment="left"
          value={first_name}
          onChange={(ev) =>
            updateFormReducer(
              "first_name",
              capitalizeFirstLetter(ev.target.value)
            )
          }
          required
          error={!first_name || !validateName(first_name)}
          errorText={
            !first_name || !validateName(first_name)
              ? first_name && !validateName(first_name)
                ? "First name not valid"
                : "First name required"
              : ""
          }
        />
      </div>
      <div className="form-field-group">
        {/* Last Name */}
        <TextboxCustom
          label="Last name*"
          name="Last name"
          placeholder="Last name"
          required
          value={last_name}
          onChange={(ev) => updateFormReducer("last_name", ev.target.value)}
          className={
            last_name
              ? "a-input-profile__completed custom-textbox"
              : "a-input-profile custom-textbox"
          }
          lableaignment="left"
          error={!last_name || !validateName(last_name)}
          errorText={
            !last_name || !validateName(last_name)
              ? last_name && !validateName(last_name)
                ? "Last name not valid"
                : "Last name required"
              : ""
          }
        />
      </div>
      {notStaff && (
        <div>
          {/* Nationality */}
          <div style={{ marginBottom: "-16px" }}>
            <DropdownCustom
              className={nationality ? "react-custom-dropdown__valid" : ""}
              name="nationality"
              label="Nationality"
              placeholder="Nationality..."
              options={countries || []}
              isSearch
              required
              error={!nationality && "Nationality required"}
              touched={!nationality}
              value={
                countries
                  ? countries.find((option) => option.value === nationality)
                    ? countries.find((option) => option.value === nationality)
                    : { label: nationality, value: nationality }
                  : ""
              }
              onChange={(item) => {
                updateFormReducer("nationality", item?.label);
                setCurrentCountryCode(item?.value);
              }}
              labelClass="justify-left"
              justifyContent="left"
            />
          </div>
        </div>
      )}
      {!IsBYUUser && (
        <div>
          {/* Profile Preference View */}
          <div>
            <DropdownCustom
              className={
                student_view_preference ? "react-custom-dropdown__valid" : ""
              }
              name="student_view_preference"
              customLabelComponent={
                <div className="label-student-view-dropdown">
                  <span className="label-student-view-dropdown__label">
                    Profile preference*
                  </span>
                  <span className="label-student-view-dropdown__tooltip">
                    <InfoIconTooltip
                      margin="0"
                      placement="bottom"
                      aria-label="Profile preference"
                      content={
                        <Typography>
                          <p>
                            <b>International</b> - Select this if you are an
                            international student primarily looking for US
                            opportunities. This selection will also allow
                            international job search.
                          </p>
                          <p>
                            <b>Domestic</b> - Select this if you are an American
                            student looking at global opportunities. This
                            selection will allow US job search as well but will
                            remove terminology and information relevant to
                            foreign nationals.
                          </p>
                        </Typography>
                      }
                    />
                  </span>
                </div>
              }
              options={PROFILE_PREFERENCE_OPTIONS}
              value={PROFILE_PREFERENCE_OPTIONS.find(
                (option) => option.value === student_view_preference
              )}
              onChange={(item) => {
                updateFormReducer("student_view_preference", item?.value);
              }}
              labelClass="justify-left"
              justifyContent="left"
            />
          </div>
        </div>
      )}
      {notStaff && (
        <div>
          {/* Current City */}
          <EditableDropdown
            label="Current city"
            className={current_city ? "react-custom-dropdown__valid" : ""}
            addText="Add city"
            justifyContent="left"
            options={cities}
            required
            value={{ label: current_city, value: current_city }}
            placeholder={isPlacePredictionsLoading ? "Loading..." : "City"}
            ref={ref}
            onInputChange={(val) => {
              getPlacePredictions({ input: val });
            }}
            onChange={(e) => {
              if (e?.value) {
                updateFormReducer("current_city", e.value);
              }
            }}
            noOptionsMessage="Type to search a city"
            error={!current_city && "City required"}
          />
        </div>
      )}
      <div>
        {/* Time Zone */}
        <DropdownCustom
          label="Timezone"
          options={formattedTimezones || []}
          onChange={(item) => {
            updateFormReducerArray([
              { key: "timezone_id", value: item.id },
              { key: "time_zone", value: item.value },
            ]);
            setTimezoneLabel({ label: item.label, value: item.value });
          }}
          isSearch
          required
          value={timezoneLabel}
          containerClassName={
            timezone_id ? "a-dropdown-container__options__valid" : ""
          }
          placeholder="Enter timezone"
          allowInvalidOption
          noOptionsFoundLabel={"No timezone found..."}
          error={!timezone_id && "Timezone required"}
          isPlainArray={false}
          className={`profile-react-custom-dropdown ${
            timezoneLabel ? "border-gray" : ""
          }`}
          labelClass="justify-left"
          justifyContent="left"
          placeholderColor={true}
        />
      </div>
      {admission_user_type && (
        <div>
          {/* Phone number */}
          <div className="form-field-group">
            <PhoneNumberSelect
              name="phone_number"
              label="Phone number*"
              onChange={(phone_number, country_code) => {
                // separating phone_number and country_code for separate properties in API
                setLocalPersonalFormMultiInfo({
                  phone_number: String(phone_number).replace(country_code, ""),
                  country_code,
                });
              }}
              value={`${country_code?.toString()}${phone_number?.toString()}`}
              placeholder="Enter phone number"
              error={
                country_code &&
                validatePhoneNumber(phone_number, country_code) &&
                "Phone number required"
              }
            />
          </div>
        </div>
      )}

      {/* Pronouns */}
      <DropdownCustom
        className={pronoun ? "react-custom-dropdown__valid" : ""}
        name="Pronouns"
        label="Pronouns"
        placeholder="Select pronouns"
        options={pronounOptions}
        value={pronounOptions.find(
          (option) => option.value === pronoun || option.label === pronoun
        )}
        onChange={(item) => updateFormReducer("pronoun", item.value)}
        labelClass="justify-left"
        justifyContent="left"
      />

      <div
        className={cx([
          "profile-details-intro",
          nationality === "United States"
            ? "profile-details-intro--large"
            : "profile-details-intro--small",
        ])}
      >
        <label
          htmlFor={"Introduction*"}
          className={cx("profile-details-intro__label")}
        >
          Introduction*
        </label>
        <Textarea
          name="Introduction*"
          className={intro ? "a-textarea__valid" : ""}
          defaultValue={intro}
          placeholder="Give a short intro about who you are. This will be visible to students in the network portal."
          onChange={(text) => setLocalPersonalFormInfo("intro", text)}
          error={!intro && "Please enter an introduction"}
          maxLength={500}
        />
      </div>
    </div>
  );

  // Login Credentials Form Elements
  const loginCredentialsElements = (
    <div
      className={cx(
        isProfileCompleted
          ? "login-details__complete"
          : "login-details__incomplete"
      )}
    >
      {/* Email */}
      <div className="form-field-group">
        <TextboxCustom
          label="Email*"
          name="Email"
          placeholder="Enter email"
          required
          readonly
          value={email}
          className="a-input-profile__completed custom-textbox"
          lableaignment="space-between"
          labelClass="a-input--profile__label"
          isEdit="Edit"
          onEditClick={() =>
            dispatch({
              type: "state.update",
              key: "isEditEmail",
              value: true,
            })
          }
        />
      </div>
      {notStaff && (
        // Secondary Email
        <div className="form-field-group">
          <TextboxCustom
            label={<LabelWithInfoIcon />}
            name="Secondary Email"
            placeholder="Enter Secondary Email"
            required={isStaff ? false : true}
            readonly={!validateAttribute(users?.secondary_email)}
            value={secondary_email || ""}
            className="a-input-profile__completed custom-textbox"
            lableaignment="space-between"
            labelClass="a-input--profile__label"
            isEdit={secondaryInputLabel()}
            onEditClick={
              personal?.is_secondary_email_verified
                ? onEmailSwitch
                : handleVerifySecondaryEmail
            }
            isSecondEdit={
              !validateAttribute(users?.secondary_email) ? false : "Edit"
            }
            onSecondEditClick={() =>
              dispatch({
                type: "state.updateSecondaryEmail",
                key: "isEditSecondaryEmail",
                value: true,
              })
            }
            onChange={(ev) =>
              updateFormReducer("secondary_email", ev.target.value)
            }
            error={
              (!secondary_email && IsSecondaryEmailRequired) ||
              (!validateEmail(secondary_email) && secondary_email)
            }
            errorText={
              (!secondary_email && IsSecondaryEmailRequired) ||
              (!validateEmail(secondary_email) && secondary_email)
                ? !validateEmail(secondary_email) && secondary_email
                  ? "Invalid email"
                  : "Secondary email required"
                : ""
            }
          />
        </div>
      )}
      {!provider && (
        <div className="form-field-group">
          <TextboxCustom
            label="Password*"
            name="Password"
            placeholder="Enter password"
            required
            readonly
            value="*****"
            className="a-input-profile__password"
            lableaignment="space-between"
            labelClass="a-input--profile__label"
            isEdit="Edit"
            onEditClick={() =>
              dispatch({
                type: "state.update",
                key: "isPassword",
                value: true,
              })
            }
          />
        </div>
      )}
      {!isProfileCompleted && !personal.is_sso_user && <div />}
      {notStaff && (
        <>
          <div>
            <ConnectLinkedin />
          </div>
          <div className={cx("google-social-button")}>
            <div>
              <div className={cx("google-label")}>
                Connect your Google account
              </div>
              <AuthGoogleProfile isGoogleConnected={is_google_connected} />
            </div>
          </div>
        </>
      )}
      {!isProfileCompleted && (
        <div className={cx("network-visibility-check")}>
          <div className={cx("network-visibility-check__icon")}>
            <ProfileCheckbox
              defaultState={profileVisibility}
              text="Show profile while networking"
              handleClick={handleChangeVisibility}
            />
          </div>
          <div>
            <span
              className={cx("network-visibility-check__text")}
              style={{
                ...(profileVisibility && {
                  color: PRIMARY_COLOR,
                }),
              }}
            >
              Show profile while networking
            </span>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {isProfileCompleted ? (
        <div className={cx("personal-form-completed")}>
          <h3 className={cx("personal-form-completed__header__profile")}>
            Profile details
          </h3>
          {profileDetailsElements}
          <h3 className={cx("personal-form-completed__header__login")}>
            Log in details
          </h3>
          {loginCredentialsElements}
          <br />
          <LineDivider />
          <div className={cx("personal-form-completed__footer")}>
            <div
              className={cx("personal-form-completed__footer__close-account")}
            >
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowConfirmationPopup(true);
                }}
              >
                Close Account
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className={cx("personal-form-incomplete")}>
          <h1 className={cx("personal-form-incomplete__header")}>
            Profile details
          </h1>
          <CoverHeader>{profileDetailsElements}</CoverHeader>
          <h1 className={cx("personal-form-incomplete__header")}>
            Log in credentials
          </h1>
          <CoverHeader>{loginCredentialsElements}</CoverHeader>
        </div>
      )}
      <>
        {state.isEditImage && (
          <CustomDialog
            open={state.isEditImage}
            title="Upload a profile picture"
            handleClose={() => {
              dispatch({
                type: "state.update",
                key: "isEditImage",
                value: false,
              });
            }}
            isDisplayCloseButton={true}
            className={`${
              accessibility_help === "true" ? "accessibility" : ""
            } profile-pic-popup`}
            dialogTitleClass="modal-title"
            dialogSubTitleClass="text-para"
          >
            <div className="model-body d-flex justify-content-center align-items-center flex-flow-column profile-module-image-upload-modal">
              {accessibility_help === "true" ? (
                <>
                  <div className="accessibility profile-upload">
                    <div className="profile-img-wrap">
                      <img
                        className="profile-img"
                        src={state.profilePicURL}
                        alt="profile"
                        height={200}
                        width={200}
                      />
                    </div>
                  </div>
                  <div className="customized-button">
                    <ButtonCustom
                      isDisabled={false}
                      onClick={() => console.log("save")}
                    >
                      Save
                    </ButtonCustom>
                  </div>
                  <a
                    href="/#"
                    className="text-grey cursor-pointer link-focus"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({
                        type: "state.update",
                        key: "isEditImage",
                        value: false,
                      });
                      updateReducerState("avatar", NoUserImage);
                    }}
                    aria-label="cancel"
                  >
                    Cancel
                  </a>
                </>
              ) : (
                <>
                  {state.cropperImage && (
                    <>
                      <CropperPic
                        image={state.cropperImage}
                        getCropData={getCropData}
                        profileInfo={true}
                      />
                      <br />
                    </>
                  )}
                </>
              )}
            </div>
          </CustomDialog>
        )}
      </>

      {state.isEditEmail && (
        <EditEmail
          flag={state.isEditEmail}
          handleClose={() =>
            dispatch({ type: "state.update", key: "isEditEmail", value: false })
          }
          type="primary"
        />
      )}

      {state.isEditSecondaryEmail && (
        <EditEmail
          flag={state.isEditSecondaryEmail}
          handleClose={() =>
            dispatch({
              type: "state.updateSecondaryEmail",
              key: "isEditSecondaryEmail",
              value: false,
            })
          }
          type="secondary"
        />
      )}

      {/* change password */}
      {state.isPassword && (
        <EditPassword
          flag={state.isPassword}
          handleClose={() => {
            dispatch({ type: "state.update", key: "isPassword", value: false });
            setLocalPersonalFormInfo("password", null);
          }}
        />
      )}

      {showConfirmationPopup && (
        <CustomDialog
          open={showConfirmationPopup}
          title={"Are you sure you want to close your account?"}
          handleClose={() => {
            setShowConfirmationPopup(false);
          }}
          className="signup-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn">
              <ButtonCustom
                width="238"
                height={50}
                onClick={() => handleAccountClose()}
              >
                Confirm
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="text-grey cursor-pointer link-focus"
              style={{ marginTop: "30px" }}
              onClick={(e) => {
                e.preventDefault();
                setShowConfirmationPopup(false);
              }}
              aria-label="cancel"
            >
              Cancel
            </a>
          </div>
        </CustomDialog>
      )}
      <SecondaryEmailConfirmDialog
        isShow={switchEmail}
        setIsShow={setSwitchEmail}
        onSuccess={handleEmailSwitch}
      />
    </>
  );
};
