import React from "react";

import { focusFirstElement } from "../../helper/helper";

const SkipInfiniteContentLink = ({
  title = "skip to right section",
  focusContainerID = "right-section-container",
  variant,
}) => {
  return (
    <a
      className={`skip-content-link ${
        variant ? `skip-content-link--${variant}` : ""
      }`}
      aria-label={title}
      href="/skip"
      onClick={(e) => {
        e.preventDefault();
        focusFirstElement(focusContainerID);
      }}
    >
      {title}
    </a>
  );
};

export default SkipInfiniteContentLink;
