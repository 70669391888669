import React, { useMemo, useState, useEffect } from "react";
import { ProgressBar } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { isUndefined } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Accordian from "../../components/ProfileModule/Accordian/Accordian";
import { ProfileCareer } from "../../components/ProfileModule/Career";
import { CareerInformation } from "../../components/ProfileModule/Career/CareerInformation/CareerInformaion";
import { CareerIntro } from "../../components/ProfileModule/Career/CareerIntro/CareerIntro";
import { CareerVisa } from "../../components/ProfileModule/Career/CareerVisa/CareerVisa";
import Educations from "../../components/ProfileModule/Educations/Educations";
import { ProfileExperience } from "../../components/ProfileModule/Experience";
import { ExperienceLanguages } from "../../components/ProfileModule/Experience/Languages/Language";
import LeadershipExperience from "../../components/ProfileModule/Experience/LeadershipExperience/LeadershipExperience";
import { ProfessionalExperience } from "../../components/ProfileModule/Experience/ProfessionalExperience/ProfessionalExperience";
import ProfileFooter from "../../components/ProfileModule/ProfileFooter/ProfileFooter";
import { PersonalForm } from "../../components/ProfileModule/Personal/PersonalForm/PersonalForm";
import { useAppState } from "../../context";
import { createModuleStyleExtractor } from "../../utils/css";
import { MiniHeading } from "./MiniHeading";
import styles from "./ProfileModule.module.scss";
import { LineDivider } from "../../components/ProfileModule/Divider/Divider";
import { isStaffAccountType, validatePhoneNumber } from "../../utils/common";
import { Button } from "../../components/atoms/Button/Button";
import { routes } from "../../routes";
import AmbassadorProfile from "../../components/ProfileModule/Ambassador/AmbassadorProfile";
import { ACCESSIBILITY_GRAY_COLOR } from "../../styles/variables";

import blueCheckedIcon from "../../assets/svg/blue-check-mark.svg";

const cx = createModuleStyleExtractor(styles);

export const CollapseForm = () => {
  const navigate = useNavigate();
  const [progressPercentage, setProgressPercentage] = useState(25);
  const [currentExpandedSection, setCurrentExpandedSection] = useState(0); // Personal as default expanded
  const { profile, personalForm, departmentList, tab, updateProfileState } =
    useAppState("profile");
  const { users = {} } = useSelector((store) => store.userStore);
  const { personal, educations, career, experience } = profile || {};
  const {
    first_name,
    last_name,
    email,
    nationality,
    current_city,
    country_code,
    phone_number,
  } = personal || {};
  const { account_type, user_type } = personalForm || {};

  const isEducationCompleted = useMemo(() => {
    if (educations?.length > 0) {
      const education = educations[0];
      if (
        Object.keys(departmentList).length > 0 &&
        !isUndefined(departmentList[education.school_id]) &&
        departmentList[education.school_id].length > 0 &&
        !departmentList[education.school_id].some((item) => {
          return item.name === education.department;
        })
      ) {
        return false;
      }
      if (
        education?.school_name &&
        (departmentList[users?.school_id] === undefined ||
        departmentList[users?.school_id]?.length === 0
          ? true
          : education?.department) &&
        education?.degree &&
        education?.major &&
        education?.graduation_date
      ) {
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educations, departmentList]);

  const isCareerCompleted = useMemo(() => {
    return !!(
      career?.resume_bio &&
      career?.resume_file_name &&
      career?.linkedin_public_url
    );
  }, [career]);

  const isExperienceCompleted = useMemo(() => {
    if (experience?.experiences?.length > 0) {
      return Boolean(
        experience?.experiences[0]?.organization &&
          experience?.experiences[0]?.role &&
          experience?.experiences[0]?.start_date &&
          (experience?.experiences[0]?.end_date ||
            experience?.experiences[0]?.currently_working)
      );
    }
    return false;
  }, [experience]);

  const isPersonalCompleted = useMemo(() => {
    if (first_name && last_name && email) {
      if (
        isStaffAccountType(account_type, user_type, true) ||
        (nationality && current_city)
      )
        if (
          users?.admission_user_type &&
          validatePhoneNumber(phone_number, country_code)
        )
          return false;
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalForm]);

  // Tracks all completed states of profile sections and computes completed percentage
  useEffect(() => {
    let percentage = null;
    if (isStaffAccountType(account_type, user_type, false)) {
      percentage =
        ((isPersonalCompleted ? 1 : 0) +
          (isEducationCompleted ? 1 : 0) +
          (isCareerCompleted ? 1 : 0) +
          (isExperienceCompleted ? 1 : 0)) *
        (100 / 4);
    } else {
      percentage = (isPersonalCompleted ? 1 : 0) * 100;
    }
    setProgressPercentage(percentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account_type,
    isPersonalCompleted,
    isEducationCompleted,
    isCareerCompleted,
    isExperienceCompleted,
  ]);

  useEffect(() => {
    return () => {
      updateProfileState({ tab: "student" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      users?.admission_user_type &&
      window?.location?.search?.includes("ambassador")
    ) {
      updateProfileState({ tab: "ambassador" });
      navigate(routes.PROFILE.PATH);
    }
  }, [updateProfileState, users.admission_user_type, navigate]);

  return (
    <div className={cx("profile-module-collapse-form-container")}>
      {users?.admission_user_type && (
        <div className="ambassador-profile-tutorial-one">
          <div className={cx("profile-module-collapse-tab")}>
            <div
              className={cx([
                "profile-module-collapse-tab__student",
                tab === "student"
                  ? "profile-module-collapse-tab__student--active"
                  : "",
              ])}
            >
              <Button
                onClick={() => {
                  updateProfileState({ tab: "student" });
                }}
              >
                <span>Student</span>
              </Button>
            </div>
            <div
              className={cx([
                "profile-module-collapse-tab__ambassador",
                tab === "ambassador"
                  ? "profile-module-collapse-tab__ambassador--active"
                  : "",
              ])}
            >
              <Button onClick={() => updateProfileState({ tab: "ambassador" })}>
                <img src={blueCheckedIcon} alt="blueCheck" />
                <span>Ambassador</span>
              </Button>
            </div>
          </div>
        </div>
      )}
      {tab === "student" && (
        <>
          <div className={cx("profile-module-collapse-form-progress")}>
            <div
              className={cx("profile-module-collapse-form-progress__container")}
            >
              <ProgressBar
                height={25}
                width="100%"
                percent={progressPercentage}
                filledBackground="#0030B5"
                unfilledBackground={
                  localStorage.getItem("accessibilityHelp") === "true"
                    ? ACCESSIBILITY_GRAY_COLOR
                    : "#EAEEF3"
                }
                text={`${progressPercentage}% Completed`}
              />
            </div>
          </div>
        </>
      )}
      <div className="ac-profile-module-accordian-wrapper">
        {tab === "student" ? (
          <>
            <div>
              <Accordian
                label="Personal"
                completed={isPersonalCompleted}
                state={currentExpandedSection === 0}
                handleOnChange={(token) => setCurrentExpandedSection(token)}
              >
                <PersonalForm />
              </Accordian>
            </div>

            {isStaffAccountType(account_type, user_type, false) && (
              <>
                <div>
                  <Accordian
                    state={currentExpandedSection === 1}
                    label="Education"
                    completed={isEducationCompleted}
                    handleOnChange={(token) => setCurrentExpandedSection(token)}
                  >
                    <Educations />
                  </Accordian>
                </div>
                <ProfileCareer>
                  {({ career, student_view_preference = "international" }) => (
                    <div>
                      <Accordian
                        label="Career"
                        completed={isCareerCompleted}
                        state={currentExpandedSection === 2}
                        handleOnChange={(token) =>
                          setCurrentExpandedSection(token)
                        }
                      >
                        <CareerIntro data={career} />
                        <div>
                          <MiniHeading heading="Career information" />
                          <CareerInformation data={career} />
                        </div>
                        {student_view_preference === "international" && (
                          <div>
                            <MiniHeading
                              heading="Visa Status"
                              showIcon
                              iconContent={
                                "Knowing your immigration status helps us connect you to companies that actively hire international candidates in their hiring process. We will never share this data with third parties without your consent."
                              }
                            />
                            <CareerVisa data={career} />
                          </div>
                        )}
                      </Accordian>
                    </div>
                  )}
                </ProfileCareer>
                <ProfileExperience>
                  {({ experiences }) => (
                    <div>
                      <Accordian
                        label="Experience"
                        completed={isExperienceCompleted}
                        state={currentExpandedSection === 3}
                        handleOnChange={(token) =>
                          setCurrentExpandedSection(token)
                        }
                      >
                        <div>
                          <MiniHeading
                            heading="Professional experience"
                            showIcon
                            iconContent={
                              "This could be a job, internship, or freelance work that contributes directly to your career interests."
                            }
                          />
                          <ProfessionalExperience
                            experiences={experiences?.experiences || []}
                          />
                        </div>

                        <div>
                          <br />
                          <LineDivider />
                          <MiniHeading
                            heading="Extracurricular & leadership experience"
                            showIcon
                            iconContent={
                              "Think about your experience outside of the classroom and workplace that highlight your interests and abilities. Maybe you tutor students, lead a student organization, or organize events – think about experiences that demonstrate valuable and transferrable skills!"
                            }
                          />
                          <LeadershipExperience
                            experiences={experiences?.extra_experiences || []}
                          />
                        </div>

                        <div>
                          <br />
                          <LineDivider />
                          <MiniHeading heading="Languages" />
                          <ExperienceLanguages
                            languages={experiences?.languages || []}
                          />
                        </div>
                      </Accordian>
                    </div>
                  )}
                </ProfileExperience>
              </>
            )}
          </>
        ) : (
          <AmbassadorProfile />
        )}
        <ProfileFooter tab={tab} />
      </div>
    </div>
  );
};
