import React from "react";
import { map, isNull, isEmpty } from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { Mixpanel } from "../../../../mixpanel";
import { InfoPanel } from "../../../InfoPanel/InfoPanel";
import { routes } from "../../../../routes";
import NotificationRightsidebarStyles from "./NotificationRightsidebarStyles";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import EmptyMiddleBox from "../../../EmptyModdleBox/EmptyMiddleBox";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";
import { getNotificationConfig } from "../../../../helper/helper";

import deleteIcon from "../../../../assets/svg/f-delete-icon.svg";

const extractExtraPayload = (type, payloadId, options = {}) => {
  let payload;
  if (type === "appointment") {
    payload = {
      ...options,
    };
  } else {
    payload = {
      notificationId: payloadId,
      ...options,
    };
  }
  const allowed = ["webinar", "user_post", "deal", "video", "appointment"];
  if (allowed.includes(type)) {
    return payload;
  }
  return {};
};
const NotificationRightsidebar = ({
  title = "",
  clearTitle = "",
  notifications = [],
  handleDelete,
  handleClear,
  isFetching = false,
  noTitle,
  className = "",
  isNew = false,
  readApiCall,
}) => {
  return isFetching ? (
    <div className="notification-loader">
      <JobsListingEffect count={5} />
    </div>
  ) : (
    <NotificationRightsidebarStyles>
      <div className="main-noti">
        <h1 className={`title-primary mb-15 ${className}`}>{title}</h1>
        <div className="noti-clear">
          {clearTitle ? (
            <ButtonCustom
              isSubmitting={isFetching}
              onClick={() => {
                handleClear();
                Mixpanel.track("Clear All Notification Button Clicked");
              }}
              height={50}
              width={120}
              className="bg-gray"
            >
              {clearTitle}
            </ButtonCustom>
          ) : (
            ""
          )}
        </div>
      </div>
      {notifications.length > 0 ? (
        map(notifications, (item, index) => {
          const notificationData = getNotificationConfig(item.type);
          return (
            <InfoPanel
              type={notificationData?.category}
              key={index}
              classname="noti-icon"
              marginTop="0px"
              marginBottom="20px"
              displayStar={false}
              avatar={notificationData?.icon}
              avatarBackground={true}
              avatarHeight="30px"
              avatarWidth="30px"
              title={
                isEmpty(item.message) || isNull(item.message)
                  ? item.title
                  : item.message
              }
              subTitle={[moment(item.created_at).fromNow()]}
              action={
                <>
                  {item.type !== "common" && (
                    <ButtonCustom
                      height={30}
                      width={150}
                      onClick={() => {
                        if (notificationData?.url) {
                          const payload = extractExtraPayload(
                            item.type,
                            item.type_id
                          );
                          readApiCall(
                            item.id,
                            getNotificationConfig(item.type, payload).url
                          );
                        } else {
                          Bugsnag.leaveBreadcrumb(
                            "Unhandled notification type click",
                            {
                              type: item.type,
                            },
                            "error"
                          );
                          readApiCall(item.id, routes.NOTIFICATION.DASHBOARD);
                        }
                        Mixpanel.track("View Notification Clicked", {
                          Type: notificationData?.label.slice(5),
                        });
                      }}
                    >
                      {notificationData?.label}
                    </ButtonCustom>
                  )}
                  <ButtonCustom
                    isSubmitting={isFetching}
                    onClick={() => {
                      handleDelete(item.id, isNew);
                      Mixpanel.track("Delete Notification Clicked", {
                        Type: getNotificationConfig(item.type).label.slice(5),
                      });
                    }}
                    height={30}
                    width={75}
                    className="btn-delete"
                  >
                    <img src={deleteIcon} alt="" />
                  </ButtonCustom>
                </>
              }
            />
          );
        })
      ) : (
        <EmptyMiddleBox title={noTitle} />
      )}
    </NotificationRightsidebarStyles>
  );
};

export default NotificationRightsidebar;
