import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import isNull from "underscore/modules/isNull";
import Picker from "emoji-picker-react";
import {
  ClickAwayListener,
  Radio,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { CheckCircle } from "@mui/icons-material";

import CreatePostStyles from "./CreatePostStyles";
import AttachmentFocusIcon from "../../../assets/FocusIcons/Common/Attachment.svg";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import { toastify } from "../../../helper/helper";
import Loader from "../../Loader/Loader";
import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CustomDropzone from "../../CustomDropzone/CustomDropzone";
import {
  createNewPostAPI,
  getTopicPreview,
} from "../../../services/topicServices";

import attachmentIcon from "../../../assets/svg/f-attachment.svg";
import deleteIcon from "../../../assets/svg/f-delete-icon.svg";
import emojiIcon from "../../../assets/svg/f-emoji-icon.svg";

const MAX_LENGTH_TOPIC_POST = 1000;

const CreatePost = ({
  editTopicPostId = null,
  handleCloseTopicEditModal = () => {},
  fetchTopicDetailsAPI = () => {},
}) => {
  const [description, setDescription] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [postFile, setFiles] = useState(null);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [emojiContainer, setEmojiContainer] = useState(false);
  const [privatePost, setPrivatePost] = useState(false);

  const [callingCreateNewPostAPI, refreshCreateNewPostAPI] =
    usePromise(createNewPostAPI);

  const { activeTopic, topicPosts, activeTopicId, setPreApiCallSavedTopic } =
    useAppState("topic");

  const debouncedURLPreviewGenerate = useDebouncedCallback(
    async (text = "") => {
      setPreviewLoader(true);
      const urldata = text.match(/\bhttps?:\/\/\S+/gi);
      if (urldata?.length) {
        setPreviewUrl(urldata[0]);
        const response = await getTopicPreview(urldata[0]);
        if (response?.success) {
          setPreviewData(JSON.parse(response?.data));
          setPreviewLoader(false);
        } else {
          setPreviewUrl(null);
          setPreviewData(null);
          setPreviewLoader(false);
        }
      } else {
        setPreviewUrl(null);
        setPreviewData(null);
        setPreviewLoader(false);
      }
    },
    3000
  );

  const handleChange = async (text = "") => {
    setDescription(text);
    debouncedURLPreviewGenerate(text);
  };

  const handleCallback = (file) => {
    file.forEach((item) => {
      if (["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        if (item.size <= 50000000) {
          setFiles(item);
        } else {
          toastify("error", "We can't attach file > 50MB.");
        }
      } else {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const createUpdatePost = () => {
    let formData = new FormData();
    formData.append("topic_post[topic_id]", activeTopic.id);
    formData.append("topic_post[content]", description);
    if (postFile?.type) {
      formData.append("topic_post[image]", postFile);
    } else if (!postFile?.type && !postFile?.name && editTopicPostId) {
      formData.append("topic_post[topic_post_image_delete]", true);
    }

    if (previewUrl && !isNull(previewUrl)) {
      if (previewData?.images[0]?.src) {
        formData.append("topic_post[image_url]", previewData.images[0].src);
        formData.append("topic_post[image_type]", "image");
      } else if (previewData?.videos[0]?.src) {
        formData.append("topic_post[image_url]", previewData.videos[0].src);
        formData.append("topic_post[image_type]", "video");
      }
      formData.append("topic_post[url_title]", previewData.title);
      formData.append("topic_post[url_description]", previewData.description);
    }

    editTopicPostId &&
      formData.append("topic_post[topic_post_id]", editTopicPostId);

    formData.append("topic_post[anonymously]", privatePost);

    refreshCreateNewPostAPI(formData);
  };

  useEffect(() => {
    if (callingCreateNewPostAPI.hasFetched()) {
      if (callingCreateNewPostAPI.hasErrors() === false) {
        setPreApiCallSavedTopic("topic");
        fetchTopicDetailsAPI(activeTopicId);
        editTopicPostId && handleCloseTopicEditModal();
        setDescription("");
        setFiles(null);
        setPreviewUrl(null);
        toastify("success", callingCreateNewPostAPI?.data()?.message);
      }
      if (callingCreateNewPostAPI.hasErrors() === true) {
        toastify("error", callingCreateNewPostAPI?.error()?.data?.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCreateNewPostAPI.isFetching()]);

  useEffect(() => {
    if (editTopicPostId) {
      const topic = topicPosts.posts.filter(
        (post) => post.id === editTopicPostId
      )[0];
      if (topic) {
        handleChange(topic?.content);
        topic?.image_name &&
          setFiles({
            name: topic?.image_name,
          });
        setPrivatePost(topic?.anonymously === true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTopicPostId]);

  const removeUploadFile = () => {
    setFiles(null);
  };

  const onEmojiClick = (emojiObject) => {
    if (emojiObject?.emoji)
      setDescription((current) => current + emojiObject.emoji);
    setEmojiContainer(false);
  };

  const handleChangePrivatePost = (event) => {
    setPrivatePost(event.target.checked);
  };

  return (
    <CreatePostStyles>
      <CardCustom className="create-post">
        <div className="relative-div">
          <div className="text-area-wrapper">
            <TextareaAutosize
              maxRows={4}
              placeholder="Ask a question, share a resource, or just say hi"
              value={description}
              onChange={(e) => handleChange(e.target.value)}
              maxLength={MAX_LENGTH_TOPIC_POST}
            />
          </div>
          <div className="post-btn">
            <div className="post-btn__left">
              {!previewData && (
                <>
                  <div className="emoji-picker-wrapper">
                    <a
                      aria-label="Emojis"
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        setEmojiContainer((current) => !current);
                      }}
                    >
                      <img src={emojiIcon} alt="emoji" className="emoji-icon" />
                    </a>
                    {emojiContainer && (
                      <ClickAwayListener
                        onClickAway={() => setEmojiContainer(false)}
                      >
                        <div className="emoji-picker">
                          <Picker
                            onEmojiClick={onEmojiClick}
                            skinTonesDisabled
                            style={{ width: "100%" }}
                          />
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                  <div className="attachment-post">
                    <CustomDropzone
                      focusIcon={AttachmentFocusIcon}
                      icon={attachmentIcon}
                      handleUploadFile={handleCallback}
                    />
                    {postFile && (
                      <div className="attachment-post__file">
                        <span
                          className="attachment-post__file__icon"
                          onClick={removeUploadFile}
                        >
                          <img src={deleteIcon} alt="Delete Icon" />
                        </span>
                        <Tooltip title={postFile?.name}>
                          <span className="attachment-post__file__name">
                            {postFile?.name}
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="post-btn__right">
              <div className="post-btn__right__checkbox">
                <Radio
                  disableRipple={true}
                  type="checkbox"
                  checkedIcon={
                    <CheckCircle color={"primary"} fontSize="small" />
                  }
                  color="primary"
                  size="small"
                  checked={privatePost}
                  onChange={handleChangePrivatePost}
                />
                <span className="post-btn__right__checkbox__text">
                  Post anonymously
                </span>
              </div>
              <ButtonCustom
                isDisabled={description.trim() === ""}
                width="100"
                height="30"
                onClick={createUpdatePost}
              >
                Post
              </ButtonCustom>
            </div>
          </div>
        </div>

        {previewLoader && (
          <div className="mt-15">
            <Loader />
          </div>
        )}

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={previewData && previewData.url}
          className="preview-url-post"
          aria-label="Preview Box"
        >
          {previewUrl && !isNull(previewUrl) && (
            <div className="preview-nbox-wrapper">
              {previewData && previewData.title && (
                <h3>{previewData && previewData.title}</h3>
              )}
              {previewData && previewData.description && (
                <h4>{previewData && previewData.description}</h4>
              )}
              {previewData &&
              previewData.videos &&
              previewData.videos.length ? (
                <div className="topic-preview-video-section">
                  <ReactPlayer
                    url={previewData.videos[0].src}
                    controls={true}
                    playing={true}
                    width={"auto"}
                  />
                </div>
              ) : (
                <>
                  {previewData &&
                  previewData.images &&
                  previewData.images.length ? (
                    <div className="img-wrap title-align">
                      <img
                        src={
                          previewData &&
                          previewData.images &&
                          previewData.images[0].src
                        }
                        alt="Preview Images"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          )}
        </a>
      </CardCustom>
    </CreatePostStyles>
  );
};

export default CreatePost;
