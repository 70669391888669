import React from "react";
import { Grid, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import SaveCompaniesStyles from "./SaveCompaniesStyles";
import CardCustom from "../../../CardCustom/CardCustom";
import InitialAvatar from "../../../../components/InitialAvatar/InitialAvatar";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";
import { numberWithCommas } from "../../../../helper/helper";
import StarIcon from "../../../StarIcon/StarIcon";

import ArrowImg from "../../../../assets/svg/DownArrow-02.svg";

const EmptyCompanies = () => {
  return (
    <Grid item xs={12}>
      <div className="facelift-empty-boxwrap">
        <h3 className="header-primary">You haven't saved any company yet</h3>
        <p>You can save a company by clicking on the star.</p>
      </div>
    </Grid>
  );
};

const SaveCompanies = ({
  savedCompaniesList = [],
  loader = false,
  updateFavourites = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const openCompany = (data) => {
    dispatch(setSelectedCompany(data));
    navigate(`/jobs/visa-insights/company?saved=true`);
  };

  const renderSaveCompanies = (data, i) => {
    return (
      <CardCustom className="company-card mb-15">
        <div
          className="item"
          onClick={() => openCompany({ ...data, visa: data.tab_type })}
        >
          <div className="avtar">
            {data.logo ? (
              <Avatar
                variant="rounded"
                src={data.logo}
                alt={
                  data.company_name
                    ? `${data.company_name}'s logo`
                    : "default logo"
                }
                className="cmpImg"
              />
            ) : (
              <InitialAvatar
                variant="rounded"
                title={data.company_name}
                index={i}
                initialCount={1}
              />
            )}
          </div>
          <div className="content">
            <div className="header-primary">
              <p className="header-primary__title">{data.company_name}</p>
              <div className="header-primary__star">
                <StarIcon
                  aria-label={`Remove ${data.employer_name} from saved companies`}
                  variant="filled"
                  onClick={() =>
                    updateFavourites({
                      company_id: data?.company_id,
                      tab_type: data.tab_type,
                      employer_name: data?.company_name,
                      like: "unlike",
                    })
                  }
                />
              </div>
            </div>
            {data?.company_no_of_petitions > 0 && (
              <div className="sub-header-primary">
                {numberWithCommas(data.company_no_of_petitions)} petitions
              </div>
            )}
            {data?.company_avg_pay && (
              <div className="sub-header-primary">
                Average salary: ${numberWithCommas(data.company_avg_pay)}
              </div>
            )}
          </div>
        </div>
      </CardCustom>
    );
  };
  return (
    <SaveCompaniesStyles arrowImg={ArrowImg}>
      <div className="company-list">
        <div className="saved-company-title mb-15">
          <h1 className="title-primary">Saved Companies</h1>
          {savedCompaniesList.length > 0 && (
            <a
              href="/saved-companies"
              onClick={(e) => {
                e.preventDefault();
                openCompany({
                  ...savedCompaniesList[0],
                  visa: savedCompaniesList[0].tab_type,
                });
              }}
              className="nlink-secondary view-all link-focus"
              aria-label={"View all saved companies"}
            >
              View all{accessibilityHelp === "true" ? " saved companies" : ""} (
              {savedCompaniesList.length})
            </a>
          )}
        </div>

        {loader ? (
          <JobsListingEffect count={3} />
        ) : (
          <div container spacing={3}>
            {savedCompaniesList && savedCompaniesList.length > 0 ? (
              savedCompaniesList.map((data, i) => renderSaveCompanies(data, i))
            ) : (
              <EmptyCompanies />
            )}
          </div>
        )}
      </div>
    </SaveCompaniesStyles>
  );
};
export default SaveCompanies;
