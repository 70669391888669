import React, { useEffect, useState } from "react";
import { Grid, Tooltip, Typography, Avatar } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import { toString } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import SearchResultStyles from "./SearchListStyles";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import StarIcon from "../../StarIcon/StarIcon";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";
import CardCustom from "../../CardCustom/CardCustom";
import SortByCustom from "../../../components/SortByCustom/SortByCustom";
import {
  toastify,
  capitalizeFirstLetter,
  processJobLocationTime,
} from "../../../helper/helper";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../effects/JobsListingEffect/JobsListingEffect";
import { Mixpanel } from "../../../mixpanel";
import { updateFavouriteJobsAPI } from "../../../services/JobServices";
import CustomDialog from "../../CustomDialog/CustomDialog";
import { US_STATE_OPTIONS } from "../../../constant/mockdata";
import { useSavedJobs } from "../../../hooks/useSavedJobs";
import {
  setAppliedFromJobAlerts,
  setSelectedJob,
} from "../../../redux/reducers/jobReducer";
import { routes } from "../../../routes";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";
import SkipInfiniteContentLink from "../../SkipInfiniteContentLink/SkipInfiniteContentLink";

import CloseIcon from "../../../assets/svg/close.svg";
import RSS from "../../../assets/png/handshake_rss_logo.png";

const SearchList = ({
  fetchMoreData,
  currentPage,
  totalPages,
  source,
  sortBy = null,
  updateSortBy = () => {},
  showLoader = false,
  adminJobsList = [],
  adminJobsListGrouped = {},
  employerJobsList = [],
  employerJobsListGrouped = {},
  showPaginationLoader = false,
  showFeaturedJobs = true,
  isJobAlertList = false,
  showClearableFilters = false,
  appliedJobsFilters = {},
  showSaveSearch = false,
  handleSaveSearchClick = () => {},
  onClearFilterClick = () => {},
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { users = {} } = useSelector((store) => store.userStore);
  const { jobs, grouped_top_employer_jobs } = useAppState("job");
  const { id: userId, student_view_preference = "international" } = users || {};

  const sortByOptions = [
    { value: "relevance", label: "Relevance" },
    { value: "date", label: "Newest" },
  ];

  const [viewGroupJobs, setViewGroupJobs] = useState({
    open: false,
    parentId: null,
    variant: null,
  });

  const [callingUpdateFavouriteJobsAPI, refreshUpdateFavouriteJobsAPI] =
    usePromise(updateFavouriteJobsAPI);

  const { savedJobs = [], refreshSavedJobs = () => {} } = useSavedJobs();

  useEffect(() => {
    if (
      callingUpdateFavouriteJobsAPI.hasFetched() &&
      callingUpdateFavouriteJobsAPI.hasErrors() === false &&
      callingUpdateFavouriteJobsAPI.data() &&
      callingUpdateFavouriteJobsAPI.data().message
    ) {
      toastify("success", callingUpdateFavouriteJobsAPI.data().message);
      refreshSavedJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingUpdateFavouriteJobsAPI.isFetching()]);

  // This Effect ensures there is enought data due to grouped jobs
  // If we have less than 10 listing cards (can be grouped or not)
  // on initial load, then we fetch more data to populate the listing
  useEffect(() => {
    if (
      employerJobsList.length + adminJobsList.length + jobs.length < 10 &&
      !showLoader &&
      !showPaginationLoader &&
      currentPage !== totalPages &&
      totalPages !== 0 &&
      jobs.length !== 0
    ) {
      setTimeout(() => {
        fetchMoreData();
      }, [500]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerJobsList.length, adminJobsList.length, jobs.length]);

  const updateFavourites = (params) => {
    refreshUpdateFavouriteJobsAPI(params);
  };

  const favJobKeyList = savedJobs.map(({ job_id }) => job_id);
  const handleFeaturedEmployerJobClick = (data, isGroupJob, websiteUrl, e) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    if (isGroupJob) {
      setViewGroupJobs({
        open: true,
        parentId: data.title + data.name,
        variant: "employer",
      });
    } else {
      if (!data.applicant_status)
        Mixpanel.track("Job apply click", {
          job_source: "ep_featured",
          job_title: data.title,
          job_employer_name: data.name,
          job_country: data.country_name,
          job_state: data.state_name,
          job_city: data.city_name,
          job_full_location: `${
            data.city_name !== null && data.city_name !== "null"
              ? `${data.city_name}${
                  data.state_name !== null && data.state_name !== "null"
                    ? ","
                    : ""
                }`
              : ""
          } ${
            data.state_name !== null && data.state_name !== "null"
              ? data.state_name
              : ""
          }`,
        });

      if (data.details === "url") window.open(websiteUrl);
      else {
        dispatch(setSelectedJob(data));
        dispatch(setAppliedFromJobAlerts(isJobAlertList ? true : false));
        const { permalink } = data;
        if (permalink) navigate(`/jobs/job-details/${permalink}?type=featured`);
        else navigate("jobs/job-details");
      }
    }
  };

  const handleFeaturedAdminJobClick = (
    data = {},
    isGroupJob = false,
    websiteUrl = "",
    e = {}
  ) => {
    // No need to handle job card click on star icon click
    if (e?.target?.id?.includes("Star")) return;
    e?.preventDefault && e.preventDefault();

    if (isGroupJob) {
      setViewGroupJobs({
        open: true,
        parentId: data?.job_title + data?.company,
        variant: "admin",
      });
    } else {
      Mixpanel.track("Job apply click", {
        job_source: "ap_featured",
        job_title: data?.job_title,
        job_employer_name: data?.company,
        job_country: data.country,
        job_state: data?.state,
        job_city: data?.city,
        job_full_location: `${
          data?.city !== null && data?.city !== "null"
            ? `${capitalizeFirstLetter(data?.city)}${
                data?.state !== null && data?.state !== "null" ? "," : ""
              }`
            : ""
        } ${data?.state !== null && data?.state !== "null" ? data?.state : ""}`,
      });
    }
    if (websiteUrl) window.open(websiteUrl);
  };

  const handleCommonJobClick = (data = {}, isGroupJob = false, e = {}) => {
    // No need to handle job card click on star icon click & company title click
    if (
      e?.target?.id?.includes("Star") ||
      e?.target?.id?.includes("company-title")
    )
      return;
    e?.preventDefault && e.preventDefault();

    if (isGroupJob) {
      setViewGroupJobs({
        open: true,
        parentId: data.job_title + data.company,
        variant: "search",
      });
    } else {
      Mixpanel.track("Job apply click", {
        job_source: "third_party",
        job_title: data.job_title,
        job_employer_name: data.company,
        job_country: data.country,
        job_state: data.state,
        job_city: data.city,
        job_full_location: `${
          data.formatted_location
            ? data.formatted_location
            : data.location
            ? data.location.replace(", USA", "") // Temporary fix for location for zip-recruiter jobs - TBS
            : ""
        }`,
      });

      data?.id &&
        window.open(`/jobs/detail/${data.id}`, "_blank", "noopener,noreferrer");
    }
  };

  const openCompany = (data) => {
    // Open U.S. Visa insight company details page in new window
    window.open(
      `${routes.JOBS.COMPANY_DETAILS}?${
        data.company_id
          ? `company_id=${data.company_id}&employer_name=${encodeURIComponent(
              data.company
            )}`
          : `employer_name=${encodeURIComponent(data.company)}`
      }`
    );
  };

  const renderSearchResult = (data, i, isGroupJob = false) => {
    const numberOfPetitions = data?.no_of_petitions || 0;
    return (
      <Grid item xs={12} key={`job-${i}`}>
        <CardCustom onClick={(e) => handleCommonJobClick(data, isGroupJob, e)}>
          <Tooltip
            title={
              data?.ai_summary ||
              (student_view_preference ===
                STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
                numberOfPetitions > 0) ? (
                <div>
                  {data?.ai_summary && (
                    <p style={{ margin: "0 0 10px 0" }}>{data?.ai_summary}</p>
                  )}
                  {student_view_preference ===
                    STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
                    numberOfPetitions > 0 && (
                      <p
                        style={{ margin: "0 0 0 0" }}
                      >{`2024 H-1B Petitions: ${numberOfPetitions}`}</p>
                    )}
                </div>
              ) : (
                ""
              )
            }
            placement="bottom-start"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            }}
          >
            {(data?.sponsored_last_year ||
              isGroupJob ||
              data?.visa_sponsorship) && (
              <div className="search-item-tags">
                {data?.sponsored_last_year && (
                  <span className="tag-item cursor-pointer">
                    Sponsored last year
                  </span>
                )}
                {isGroupJob && (
                  <span className="tag-item cursor-pointer">
                    Multiple locations
                  </span>
                )}
                {data?.visa_sponsorship && (
                  <span className="tag-item cursor-pointer">
                    Sponsorship available
                  </span>
                )}
              </div>
            )}
            <div className="search-item">
              <div className="avtar">
                <InitialAvatar
                  variant="rounded"
                  title={data.company}
                  index={i}
                  initialCount={1}
                />
              </div>

              <div className="content">
                <div className="header-primary flex">
                  {data.job_title && (
                    <span className="inline-title">
                      {capitalizeFirstLetter(data.job_title)}
                    </span>
                  )}
                </div>

                <div
                  className="sub-header-primary"
                  onClick={() => openCompany(data)}
                >
                  {data.company}
                </div>

                {!isGroupJob && (
                  <div className="text-grey">
                    {data?.formatted_location &&
                    data?.formatted_relative_time ? (
                      <span>
                        {data?.formatted_location}
                        {" | "}
                        {data?.formatted_relative_time}
                      </span>
                    ) : (
                      <span>
                        {processJobLocationTime({
                          city: data?.city,
                          state: data?.state,
                          time: data?.date ?? data?.publish_date,
                        })}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="flex align-items-center">
                {isGroupJob ? (
                  <div
                    className={`card-actions-wrapper ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  >
                    <a
                      className="nlink-btn nbg-secondary"
                      href={"/#"}
                      aria-label={`View group jobs`}
                      onClick={(e) => e.preventDefault()}
                    >
                      View
                    </a>
                  </div>
                ) : (
                  <div
                    className={`card-actions-wrapper ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  >
                    <a
                      className="nlink-btn nbg-secondary"
                      href="/#"
                      aria-label={`Apply for ${data.job_title}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      View
                    </a>
                  </div>
                )}
                {!isGroupJob && favJobKeyList.includes(data?.job_key) ? (
                  <StarIcon
                    aria-label={`Remove ${data.job_title} from saved jobs`}
                    variant="filled"
                    onClick={() =>
                      updateFavourites({
                        ...data,
                        userId,
                        job_id: data.job_key,
                        like: "unlike",
                      })
                    }
                    className="pl-8"
                  />
                ) : !isGroupJob && !favJobKeyList.includes(data?.job_key) ? (
                  <StarIcon
                    aria-label={`Add ${data.job_title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        job_id: data.job_key,
                        like: "like",
                      });
                    }}
                    className={`pl-8 card-actions-wrapper ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Tooltip>
        </CardCustom>
      </Grid>
    );
  };

  const renderEmployerJobs = (data, i, isGroupJob = false) => {
    let websiteUrl = data.url;
    if (data.details === "url" && !/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`employer-job-${i}`}>
        <CardCustom
          onClick={(e) =>
            handleFeaturedEmployerJobClick(data, isGroupJob, websiteUrl, e)
          }
        >
          <div className="search-item-tags">
            <Tooltip
              title={
                "Featured opportunities are sourced directly from employers or Interstride partners"
              }
              placement="bottom"
            >
              <span className="tag-item cursor-pointer">Featured</span>
            </Tooltip>
            {isGroupJob && (
              <span className="tag-item cursor-pointer">
                Multiple locations
              </span>
            )}
            {data?.visa_sponsorship && (
              <span className="tag-item cursor-pointer">
                Sponsorship available
              </span>
            )}
          </div>
          <div className="search-item employer-job">
            <div className="avtar">
              {data.logo.url && data.logo.url !== null ? (
                <Avatar variant="rounded" alt="" src={data.logo.url} />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data.company}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary flex">
                {data.title && (
                  <span className="inline-title inline-title-flex">
                    {capitalizeFirstLetter(data.title)}
                  </span>
                )}
              </div>
              <div className="sub-header-primary">{data.name}</div>
              {!isGroupJob && (
                <div className="text-grey">
                  {processJobLocationTime({
                    city: data?.city_name,
                    state: data?.state_code,
                    country: !["United States", "USA", "US"].includes(
                      data?.country_name
                    )
                      ? data?.country_name
                      : null,
                    time: data?.postedon,
                  })}
                </div>
              )}
            </div>
            {isGroupJob ? (
              <div
                className={`card-actions-wrapper ${
                  accessibilityHelp === "true" ? "show-actions" : ""
                }`}
              >
                <a
                  href="/#"
                  className="nlink-btn nbg-secondary"
                  aria-label={`Apply for ${data.title}`}
                  onClick={(e) => e.preventDefault()}
                >
                  View
                </a>
              </div>
            ) : data.applicant_status && data.applicant_status !== null ? (
              <>
                <div className="apply-btn cursor-pointer">
                  <a
                    href
                    className="nlink-btn bg-gray"
                    aria-label="Already Applied"
                    onClick={(e) => e.preventDefault()}
                  >
                    Applied
                  </a>
                </div>
                {!isGroupJob &&
                favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            ) : (
              <>
                <div
                  className={`card-actions-wrapper ${
                    accessibilityHelp === "true" ? "show-actions" : ""
                  }`}
                >
                  <div>
                    <a
                      href={`jobs/job-details/${data?.permalink ?? ""}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nlink-btn nbg-secondary"
                      aria-label={`Apply for ${data.title}`}
                      onClick={(e) => e.preventDefault()}
                    >
                      Apply
                    </a>
                  </div>
                </div>
                {!isGroupJob &&
                favJobKeyList.includes(toString(data?.jobid)) ? (
                  <StarIcon
                    aria-label={`Remove ${data.title} from saved jobs`}
                    variant="filled"
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "unlike",
                        job_title: data.title,
                      });
                    }}
                    className="ml-8"
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${data.title} to saved jobs`}
                    onClick={() => {
                      updateFavourites({
                        ...data,
                        userId,
                        employer_job_id: data.jobid,
                        like: "like",
                        job_title: data.title,
                        city: data.city_name,
                        state: data.state_code,
                        country: !["United States", "USA", "US"].includes(
                          data?.country_name
                        )
                          ? data?.country_name
                          : null,
                        company: data.employer_name,
                        url: websiteUrl,
                      });
                    }}
                    className={`card-actions-wrapper ml-8 ${
                      accessibilityHelp === "true" ? "show-actions" : ""
                    }`}
                  />
                )}
              </>
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const renderAdminJobs = (data, i, isGroupJob = false) => {
    let websiteUrl = data.link;
    if (!/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`featured-job-${i}`}>
        {data?.rss_feed_id && data?.rss_feed_id != null ? (
          <img src={RSS} className="rss-icon-align" alt="Job-Rss" />
        ) : (
          ""
        )}
        <CardCustom
          onClick={(e) =>
            handleFeaturedAdminJobClick(data, isGroupJob, websiteUrl, e)
          }
        >
          <div className="search-item-tags">
            <Tooltip
              title={
                "Featured opportunities are sourced directly from employers or Interstride partners"
              }
              placement="bottom"
            >
              <span className="tag-item cursor-pointer">Featured</span>
            </Tooltip>
            {isGroupJob && (
              <span className="tag-item cursor-pointer">
                Multiple locations
              </span>
            )}
            {data?.visa_sponsorship && (
              <span className="tag-item cursor-pointer">
                Sponsorship available
              </span>
            )}
          </div>
          <div className="search-item featured-job">
            <div className="avtar">
              {data?.logo_url && data?.logo_url !== null ? (
                <Avatar variant="rounded" alt="" src={data?.logo_url} />
              ) : (
                <InitialAvatar
                  variant="rounded"
                  title={data?.company}
                  index={i}
                  initialCount={1}
                />
              )}
            </div>
            <div className="content">
              <div className="header-primary flex">
                {data?.job_title && (
                  <span className="inline-title inline-title-flex">
                    {capitalizeFirstLetter(data?.job_title)}
                  </span>
                )}
              </div>
              <div className="sub-header-primary">{data?.company}</div>
              {!isGroupJob && (
                <div className="text-grey">
                  {processJobLocationTime({
                    city: data.city,
                    state: data.state,
                    country:
                      data.country !== "United States" &&
                      data.country !== "USA" &&
                      data.country !== "US" &&
                      data.country,
                    time: data.created_at,
                  })}
                </div>
              )}
            </div>
            <div
              className={`card-actions-wrapper ${
                accessibilityHelp === "true" ? "show-actions" : ""
              }`}
            >
              {isGroupJob ? (
                <a
                  href={"/#"}
                  className="nlink-btn nbg-secondary"
                  aria-label={`Apply for ${data?.job_title}`}
                  onClick={(e) => e.preventDefault()}
                >
                  View
                </a>
              ) : (
                <a
                  href={"/#"}
                  onClick={(e) => e.preventDefault()}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nlink-btn nbg-secondary"
                  aria-label={`Apply for ${data?.job_title}`}
                >
                  Apply
                </a>
              )}
            </div>
            {favJobKeyList.includes(toString(data.id)) ? (
              <StarIcon
                aria-label={`Remove ${data.job_title} from saved jobs`}
                variant="filled"
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "unlike",
                    job_title: data.job_title,
                  });
                }}
                className="ml-8"
              />
            ) : (
              <StarIcon
                aria-label={`Add ${data.job_title} to saved jobs`}
                onClick={() => {
                  updateFavourites({
                    userId,
                    featured_job_id: data.id,
                    like: "like",
                    job_title: data.job_title,
                    city: data.city,
                    state: data.state,
                    company: data.company,
                    country:
                      data.country !== "United States" &&
                      data.country !== "USA" &&
                      data.country !== "US"
                        ? data.country
                        : null,
                    url: websiteUrl,
                    publish_date: data.created_at,
                    logo_url: data.logo_url,
                  });
                }}
                className={`card-actions-wrapper ml-8 ${
                  accessibilityHelp === "true" ? "show-actions" : ""
                }`}
              />
            )}
          </div>
        </CardCustom>
      </Grid>
    );
  };

  const renderAllJobs = (
    <Grid container spacing={3}>
      {employerJobsList.length > 0 &&
        showFeaturedJobs &&
        employerJobsList.map((data, i) => {
          // Check if job belongs in a group
          const isGroupJob = Object.keys(employerJobsListGrouped).includes(
            data.title + data.name
          );
          return renderEmployerJobs(data, i, isGroupJob);
        })}
      {adminJobsList.length > 0 &&
        showFeaturedJobs &&
        adminJobsList.map((data, i) => {
          // Check if job belongs in a group
          const isGroupJob = Object.keys(adminJobsListGrouped).includes(
            data.job_title + data.company
          );
          return renderAdminJobs(data, i, isGroupJob);
        })}
      {jobs.length > 0 &&
        jobs.map((data, i) => {
          // Check if job belongs in a group
          const isGroupJob = Object.keys(grouped_top_employer_jobs).includes(
            data.job_title + data.company
          );
          return renderSearchResult(
            data,
            employerJobsList.length + adminJobsList.length + i,
            isGroupJob
          );
        })}
    </Grid>
  );

  const ClearableFilters = () => {
    const { state, city } = appliedJobsFilters;
    const isFilterApplied = state || city;
    if (!isFilterApplied) return null;

    return (
      <div className="clearable-filter-container mb-15">
        <div className="tags-wrapper">
          {state && (
            <div key={"state"} className="tag">
              <span>
                {US_STATE_OPTIONS.find((rec) => rec.value === state)?.label ??
                  ""}
              </span>
              <img
                src={CloseIcon}
                alt="cross"
                onClick={() => onClearFilterClick({ state: null })}
              />
            </div>
          )}
          {city && (
            <div key={"city"} className="tag">
              <span>{city}</span>
              <img
                src={CloseIcon}
                alt="cross"
                onClick={() => onClearFilterClick({ city: null })}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <SearchResultStyles>
      <SkipInfiniteContentLink />
      <div className="search-list">
        <div className="search-top title-primary mb-15">
          <h1 className="title-primary relative mb-0 pt-0">
            {props.subTitle}
            <InfoIconTooltip
              variant="header"
              aria-label="Search List Jobs note"
              placement="bottom"
              margin="-1px 0px 0px 8px"
              content={
                <>
                  <Typography>
                    By clicking on the employment opportunity links [or job
                    postings] below, you are agreeing to share information,
                    including personal information with third party employers
                    and/or other recruiting platforms.
                  </Typography>
                  {student_view_preference ===
                    STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL && (
                    <>
                      <Typography className="mt-10">
                        International students must meet with their
                        International Student & Scholar Services (ISSS) office
                        and/or Designated School Official (DSO) before accepting
                        any job, internship, or volunteer position to confirm
                        compliance with visa regulations.
                      </Typography>
                      <Typography className="mt-10">
                        We utilize historical employment data, proprietary
                        algorithm, and AI to present results from H-1B
                        employers. However, visa sponsorship policies at
                        companies are subject to change, and some displayed
                        roles may not offer sponsorship.
                      </Typography>
                    </>
                  )}
                </>
              }
            />
          </h1>

          {showSaveSearch && (
            <div className="ml-auto">
              <Link
                to="/#"
                className="nlink-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveSearchClick();
                }}
              >
                Save search
              </Link>
            </div>
          )}

          <div className="sort-by">
            {props.showSortBy && (
              <div className="sort-by ml-auto">
                <span className="sortby">Sort by</span>
                <SortByCustom
                  name=""
                  label=""
                  showLabelAtTop={false}
                  justifyContent="left"
                  options={sortByOptions}
                  value={
                    sortBy !== null
                      ? sortByOptions.find((option) => option.value === sortBy)
                      : ""
                  }
                  onChange={(e) => updateSortBy(e.value)}
                />
              </div>
            )}
          </div>
        </div>

        {showClearableFilters && <ClearableFilters />}

        {showLoader ? (
          <JobsListingEffect count={3} />
        ) : (
          <React.Fragment>
            {((employerJobsList.length > 0 || adminJobsList.length > 0) &&
              showFeaturedJobs) ||
            jobs.length > 0 ? (
              <React.Fragment>
                {accessibilityHelp === "true" ? (
                  <div>
                    {renderAllJobs}
                    {currentPage !== totalPages &&
                      totalPages !== 0 &&
                      jobs.length !== 0 && (
                        <center>
                          <div style={{ margin: "20px 0 0 0" }}>
                            {showPaginationLoader ? (
                              <JobsListingEffect count={3} />
                            ) : (
                              <ButtonCustom onClick={fetchMoreData}>
                                Load More
                              </ButtonCustom>
                            )}
                          </div>
                        </center>
                      )}
                  </div>
                ) : (
                  <InfiniteScroll
                    dataLength={
                      employerJobsList.length +
                      adminJobsList.length +
                      jobs.length
                    }
                    next={fetchMoreData}
                    hasMore={
                      currentPage !== totalPages &&
                      totalPages !== 0 &&
                      jobs.length !== 0
                    }
                    loader={
                      <div style={{ margin: "20px 0 0 0" }}>
                        <JobsListingEffect count={3} />
                      </div>
                    }
                    style={{ overflow: "none" }}
                  >
                    {renderAllJobs}
                  </InfiniteScroll>
                )}
              </React.Fragment>
            ) : source && source === "alert" ? (
              <EmptyListWithImage
                title={`Looks like there are no jobs with this search.`}
                subTitle={`Please try with another filter again.`}
                className={"default-empty-cover"}
              />
            ) : (
              <EmptyListWithImage
                title={`Looks like there are no jobs with this search.`}
                subTitle={`Try removing some filters to expand your search and get more results.`}
                className={"default-empty-cover"}
              />
            )}
          </React.Fragment>
        )}
        {viewGroupJobs.open && viewGroupJobs.variant && (
          <CustomDialog
            open={viewGroupJobs.open}
            title="Multiple locations job"
            handleClose={() => {
              setViewGroupJobs({ open: false, parentId: null, variant: null });
            }}
          >
            <div className="group-jobs-listing">
              <div className="search-list">
                {viewGroupJobs?.variant === "search" ? (
                  grouped_top_employer_jobs[viewGroupJobs?.parentId]?.map(
                    (groupJob, i) =>
                      renderSearchResult(
                        groupJob,
                        employerJobsList.length + adminJobsList.length + i
                      )
                  )
                ) : viewGroupJobs?.variant === "employer" ? (
                  employerJobsListGrouped[viewGroupJobs?.parentId]?.map(
                    (groupJob, i) => renderEmployerJobs(groupJob, i)
                  )
                ) : viewGroupJobs?.variant === "admin" ? (
                  adminJobsListGrouped[viewGroupJobs?.parentId].map(
                    (groupJob, i) => renderAdminJobs(groupJob, i)
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </CustomDialog>
        )}
      </div>
    </SearchResultStyles>
  );
};
export default SearchList;
