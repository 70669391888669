import React from "react";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import { NoUserImage } from "../../helper/helper";
import { InitialAvatarStyles } from "./InitialAvatarStyles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#fff",
    fontSize: "26px",
    paddingTop: "5px",
  },
  accessibility: {
    backgroundColor: "#5C728A",
  },
  default: {
    backgroundColor: "#d1dde5",
  },
  color1: {
    backgroundColor: "#ABC1D",
  },
  color2: {
    backgroundColor: "#FFE69A",
  },
  color3: {
    backgroundColor: "#A3C3FD",
  },
  color4: {
    backgroundColor: "#FCA6A0",
  },
  color5: {
    backgroundColor: "#F7BE8D",
  },
}));

export default function InitialAvatar({
  title = "",
  initialCount = 2,
  variant = "circle",
  alt = null,
  index = null,
}) {
  const classes = useStyles();
  let classNameHolder = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
  ];

  const getInitials = () => {
    const matches = title && title?.match ? title.match(/\b(\w)/g) : [];
    if (matches && matches !== null) {
      return matches.splice(0, initialCount).join("").toLocaleUpperCase();
    }
    return "";
  };

  const getClassname = () => {
    if (localStorage.getItem("accessibilityHelp") === "true")
      return classes["accessibility"];
    else if (index === -1) {
      return classes["default"];
    } else
      return classes[
        classNameHolder[
          index !== null ? index % 5 : Math.floor(Math.random() * 5)
        ]
      ];
  };

  const initials = getInitials();

  return (
    <InitialAvatarStyles>
      <Avatar
        alt={alt === null ? title : alt}
        variant={variant}
        {...(isEmpty(initials)
          ? {
              className: `initial-avatar ${
                classes["avatar"]
              } ${getClassname()}`,
              src: NoUserImage,
              style: {
                paddingTop: 0,
              },
            }
          : {
              className: `initial-avatar ${
                classes["avatar"]
              } ${getClassname()}`,
            })}
      >
        {initials}
      </Avatar>
    </InitialAvatarStyles>
  );
}
