import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { isEmpty } from "lodash";

import CustomProgressBarStyles from "./CustomProgressBarStyles";
import { ACCESSIBILITY_GRAY_COLOR } from "../../styles/variables";

const CustomProgressBar = ({
  width = 470,
  height = 27,
  filledBackground = "#000485",
  unfilledBackground = "#EAEEF3",
  percent = 0,
  totalStep = 5,
  currentStep = 0,
  stepLabels = [],
  customClass = "",
}) => {
  const backgroundColor =
    localStorage.getItem("accessibilityHelp") === "true"
      ? ACCESSIBILITY_GRAY_COLOR
      : unfilledBackground;
  return (
    <CustomProgressBarStyles backgroundColor={backgroundColor}>
      <div className={`progressbar-wrapper ${customClass}`}>
        <ProgressBar
          filledBackground={filledBackground}
          unfilledBackground={backgroundColor}
          percent={percent}
          height={`${height}px`}
          width={`${width}px`}
        >
          {Array.from(Array(totalStep), (_, i) => (
            <Step transition="scale" key={i}>
              {({ accomplished, index }) => (
                <>
                  <div
                    className={`transitionStep ${
                      accomplished && index + 1 < currentStep
                        ? "completed"
                        : accomplished && index + 1 === currentStep
                        ? "active"
                        : currentStep === 0 && index === 0
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="progress-item"></div>
                  </div>
                  {!isEmpty(stepLabels) && stepLabels[i] ? (
                    <div className="progress-label">
                      {stepLabels[i] === "Career & Self-Development" ? (
                        <>
                          {"Career & "} <br /> {"self-development"}
                        </>
                      ) : stepLabels[i] === "Critical Thinking" ? (
                        <>
                          {"Critical "} <br /> {"Thinking"}
                        </>
                      ) : (
                        stepLabels[i]
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Step>
          ))}
        </ProgressBar>
      </div>
    </CustomProgressBarStyles>
  );
};

export default CustomProgressBar;
