import React, { useState, useEffect } from "react";
import {
  ShimmerBadge,
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import CareerGameplanDashboardStyles from "./CareerGameplanDashboardStyles";
import careerGameplan from "../../../../assets/svg/career-gameplan.svg";
import btnArrow from "../../../../assets/svg/f-btn-arrow.svg";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { routes } from "../../../../routes";
import ButtonCustom from "../../../../components/ButtonCustom/ButtonCustom";
import CareerGamePlanLeftSidebar from "../../../../components/Tools/CareerGamePlan/CareerGamePlanLeftSidebar/CareerGamePlanLeftSidebar";
import CareerGamePlanMiddle from "../../../../components/Tools/CareerGamePlan/CareerGamePlanMiddle/CareerGamePlanMiddle";
import { getGameplanDashboardDataAPI } from "../../../../services/careerGameplanServices";
import { resetTestState } from "../../../../redux/reducers/gameplanReducer";

const CareerGameplanDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isTestTaken, setIsTestTaken] = useState(false);
  const [userScore, setUserScore] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [
    callingGetGameplanDashboardDataAPI,
    refreshGetGameplanDashboardDataAPI,
  ] = usePromise(getGameplanDashboardDataAPI);

  useEffect(() => {
    dispatch(resetTestState());
    refreshGetGameplanDashboardDataAPI();
  }, []);

  useEffect(() => {
    if (
      callingGetGameplanDashboardDataAPI.hasFetched() &&
      callingGetGameplanDashboardDataAPI.hasErrors() === false &&
      callingGetGameplanDashboardDataAPI.data()
    ) {
      const { data = {} } = callingGetGameplanDashboardDataAPI.data();
      const { categories = [], total_score = 0 } = data;
      setIsTestTaken(total_score === 0 ? false : true);
      setUserScore(total_score);
      setCategoryList(categories);
    }
  }, [callingGetGameplanDashboardDataAPI.isFetching()]);

  return (
    <CareerGameplanDashboardStyles>
      <div className="CareerGameplanDashboard">
        <div className="f-main-title-with-button mb-30">
          <h1 className="f-main-title mb-0">
            Career Gameplan
            {isTestTaken && (
              <div className="btn-wrapper">
                <ButtonCustom
                  width={173}
                  className="shadow-btn"
                  onClick={() => {
                    dispatch(resetTestState());
                    navigate(routes.TOOLS.CAREER_TEST);
                  }}
                >
                  Retake Test
                  <img src={btnArrow} alt="btn-arrow" />
                </ButtonCustom>
              </div>
            )}
          </h1>
        </div>

        {callingGetGameplanDashboardDataAPI.isFetching() ? (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div style={{ marginTop: "35px" }}>
                <ShimmerThumbnail height={250} />
                {Array.from(Array(5).keys()).map((_, index) => (
                  <ShimmerText line={2} key={index} />
                ))}
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className="gameplan-loading-container">
                <br />
                {Array.from(Array(5).keys()).map((_, index) => (
                  <div className="gameplan-loading-container__item" key={index}>
                    <ShimmerTitle />
                    <ShimmerBadge />
                    <ShimmerText />
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        ) : (
          <>
            {isTestTaken ? (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <h2 className="title-primary mb-15">Your Score</h2>
                  <CareerGamePlanLeftSidebar
                    categoryList={categoryList}
                    userScore={userScore}
                  />
                </Grid>
                <Grid item xs={9}>
                  <CareerGamePlanMiddle categoryList={categoryList} />
                </Grid>
              </Grid>
            ) : (
              <div className="getstarted-wrapper mt-0">
                <div className="getstarted-image">
                  <img alt={"career gameplan"} src={careerGameplan} />
                </div>
                <div className="getstarted-description">
                  <h1>Take this 3 minute quiz to kickstart your future.</h1>
                  <p>
                    Unlock your full potential by getting your own personalized
                    career plan.
                  </p>
                  <ButtonCustom
                    width={205}
                    onClick={() => {
                      dispatch(resetTestState());
                      navigate(routes.TOOLS.CAREER_TEST);
                    }}
                  >
                    Get started
                  </ButtonCustom>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </CareerGameplanDashboardStyles>
  );
};

export default CareerGameplanDashboard;
