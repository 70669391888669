import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import { ShimmerThumbnail } from "react-shimmer-effects";
import moment from "moment";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import CardCustom from "../../../components/CardCustom/CardCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import { createModuleStyleExtractor } from "../../../utils/css";
import { INTERSTRIDE_EMBLEM, toastify } from "../../../helper/helper";
import {
  getVideoCategoriesListAPI,
  getResourcesListAPI,
  getSavedResourcesAPI,
  saveUnSaveResourceAPI,
} from "../../../services/VideoServices";
import styles from "./Resources.module.scss";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import { Mixpanel } from "../../../mixpanel";
import usePromise from "../../../hooks/usePromise/usePromise";
import { trackClickForAnalyticsAPI } from "../../../services/commonServices";

import SearchIcon from "../../../assets/svg/search-facelift.svg";
import backArrowV2 from "../../../assets/svg/back-arrow-v2.svg";
import savePinIcon from "../../../assets/svg/save-pin.svg";
import arrowRight from "../../../assets/svg/arrow-right.svg";
import StarIcon from "../../../components/StarIcon/StarIcon";
import NoCategoriesIcon from "../../../assets/svg/error404.svg";
import PlaceholderCategoryImage from "../../../assets/svg/placeholderCategoryImage.svg";
import xlsIcon from "../../../assets/svg/xlsIcon.svg";
import docIcon from "../../../assets/svg/docIcon.svg";
import BigPlayIcon from "../../../assets/png/BigPlayIcon.png";
import Missing_Video_Icon from "../../../assets/png/youtube_big.png";
import NewDocIcon from "../../../assets/svg/newDocIcon.svg";
import NewWebIcon from "../../../assets/svg/newWebIcon.svg";
import NewPlayIcon from "../../../assets/svg/newPlayIcon.svg";
import NoResourceIllustration from "../../../assets/svg/no-user-illustration.svg";

const cx = createModuleStyleExtractor(styles);
const pages = ["main", "resources", "saved"];
const resourcesSortOptions = [
  { value: "date", label: "Date added" },
  { value: "type", label: "Type" },
];
const defaultResourceFilterOptions = [
  { value: "pdf", label: "PDF" },
  { value: "website", label: "Website" },
  { value: "video", label: "Video" },
  { value: "podcast", label: "Podcast" },
  { value: "doc", label: "Document" },
  { value: "ppt", label: "PowerPoint" },
  { value: "image", label: "Image" },
];

// Parse API filter type array data to object with labels
const parseAPIFilters = (filters) => {
  if (!filters) return [];
  let parsedFilters = [];
  filters.forEach((filter) => {
    switch (filter) {
      case "pdf":
        parsedFilters.push({ value: "pdf", label: "PDF" });
        break;
      case "website":
        parsedFilters.push({ value: "website", label: "Website" });
        break;
      case "video":
        parsedFilters.push({ value: "video", label: "Video" });
        break;
      case "podcast":
        parsedFilters.push({ value: "podcast", label: "Podcast" });
        break;
      case "doc":
        parsedFilters.push({ value: "doc", label: "Document" });
        break;
      case "ppt":
        parsedFilters.push({ value: "ppt", label: "PowerPoint" });
        break;
      case "image":
        parsedFilters.push({ value: "image", label: "Image" });
        break;
      case "youtube":
        parsedFilters.push({ value: "youtube", label: "YouTube Video" });
        break;
      case "excel_sheet":
        parsedFilters.push({ value: "excel_sheet", label: "Excel Sheet" });
        break;
      default:
        parsedFilters.push({ value: filter, label: filter });
    }
  });
  return parsedFilters;
};

export default function Resources() {
  const { users = {} } = useSelector((store) => store.userStore);

  const [page, setPage] = useState(pages[0]);
  const [previousPage, setPreviousPage] = useState(pages[0]);
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesLoader, setCategoriesLoader] = useState(false);
  const [selectedCategoryResources, setSelectedCategoryResources] =
    useState(null);
  const [resourcesLoader, setResourcesLoader] = useState(false);
  const [resourceFilterOptions, setResourceFilterOptions] = useState(
    defaultResourceFilterOptions
  );
  const [savedResources, setSavedResources] = useState([]);
  const [savedResourcesFilterOptions, setSavedResourcesFilterOptions] =
    useState([]);
  const [savedResourcesFilter, setSavedResourcesFilter] = useState(null);
  const [savedResourcesLoader, setSavedResourcesLoader] = useState(false);
  const [resourceSort, setResourceSort] = useState(
    resourcesSortOptions[0].value
  );
  const [resourceFilter, setResourceFilter] = useState(null);
  const [state, setModel] = useState({
    showModel: false,
    link_type: null,
    image: null,
    link: null,
    title: "",
  });
  const [, refreshTrackClickForAnalyticsAPI] = usePromise(
    trackClickForAnalyticsAPI
  );

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  // on DOM Load
  useEffect(() => {
    // Call APIs
    categoriesAPICall(searchText);
    savedResourcesAPICall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoriesAPICall = async (_searchText) => {
    try {
      setCategoriesLoader(true);
      const response = await getVideoCategoriesListAPI(_searchText.trim());
      if (response && response.success) {
        setCategories(response.data.video_categories);
        setCategoriesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setCategoriesLoader(false);
    }
  };

  const savedResourcesAPICall = async (search, filter) => {
    try {
      setSavedResourcesLoader(true);
      const response = await getSavedResourcesAPI(search, filter);
      if (response && response.success) {
        setSavedResources(response?.data?.saved_resources_list || []);
        setSavedResourcesFilterOptions(
          parseAPIFilters(response?.data?.filter_resource_type || [])
        );
        setSavedResourcesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setSavedResourcesLoader(false);
    }
  };

  const resourcesAPICall = async (video_category_id, sort_by, group_by) => {
    try {
      setResourcesLoader(true);
      const response = await getResourcesListAPI({
        video_category_id,
        sort_by,
        group_by,
      });
      if (response && response.success) {
        if (response?.data?.filter_resource_type) {
          setResourceFilterOptions(
            parseAPIFilters(response.data.filter_resource_type)
          );
        }
        setSelectedCategoryResources(response.data.resources);
        setResourcesLoader(false);
      }
    } catch (error) {
      console.error(error);
      setResourcesLoader(false);
    }
  };

  const handleSearch = _debounce(() => {
    categoriesAPICall(searchText);
  }, 500);

  const handleSearchSavedResources = _debounce(() => {
    setSavedResourcesFilter(null);
    savedResourcesAPICall(searchText, null);
  }, 500);

  const handleClearSearch = () => {
    categoriesAPICall("");
    setSearchText("");
  };

  const navigationHandler = (_page, _selectedCategory = null) => {
    setResourceFilter(null);
    setSearchText("");
    if (_page === pages[0]) {
      categoriesAPICall("");
      savedResourcesAPICall();
      setResourceFilterOptions(defaultResourceFilterOptions);
      setPage(pages[0]);
    } else if (_page === pages[1]) {
      refreshTrackClickForAnalyticsAPI(
        "video_category",
        _selectedCategory.id,
        users.school_id
      );
      Mixpanel.track("Resources Category clicked", {
        id: _selectedCategory.id,
      });
      setSelectedCategory(_selectedCategory);
      resourcesAPICall(_selectedCategory.id, resourceSort);
      savedResourcesAPICall();
      setPreviousPage(pages[0]);
      setPage(pages[1]);
    } else if (_page === pages[2]) {
      setResourceFilterOptions(defaultResourceFilterOptions);
      setSavedResourcesFilter(null);
      savedResourcesAPICall();
      setPreviousPage(page);
      setPage(pages[2]);
    }
  };

  const handleFavorite = async (resource_id) => {
    try {
      const response = await saveUnSaveResourceAPI({ resource_id });
      if (response?.success) {
        if (response?.data === "") {
          if (page === pages[2]) {
            // Recall saved resources API if on saved resources page
            savedResourcesAPICall(searchText, savedResourcesFilter);
          } else {
            // Resource removed
            setSavedResources((current) =>
              current.filter((item) => item.id !== resource_id)
            );
          }
        } else {
          // resource added
          setSavedResources((current) => [...current, { id: resource_id }]);
        }
        toastify("success", response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const modelHandler = (
    modelStatus = false,
    link_type = null,
    image = null,
    link = null,
    title = ""
  ) => {
    setModel({
      showModel: modelStatus,
      link_type,
      image,
      link,
      title,
    });
  };

  const handleFilterType = (item) => {
    if (page === pages[1]) {
      setResourceFilter(item);
      resourcesAPICall(selectedCategory.id, resourceSort, item.value);
    } else {
      setSavedResourcesFilter(item);
      savedResourcesAPICall(searchText, item);
    }
  };

  const handleClearFilterType = (e) => {
    e.preventDefault();
    if (page === pages[1]) {
      setResourceFilter(null);
      setResourceSort(resourcesSortOptions[0].value); // Reset sort filter
      resourcesAPICall(selectedCategory.id, resourcesSortOptions[0].value);
    } else {
      setSearchText("");
      setSavedResourcesFilter(null);
      savedResourcesAPICall();
    }
  };

  // JSX Element for reusable resource card
  const resourceCard = (item, index) => {
    const isResourceFavorite =
      savedResources.findIndex((resource) => resource.id === item.id) === -1
        ? false
        : true;
    let categoryName = "";
    if (page === pages[0] || page === pages[2]) {
      const _index = categories.findIndex(
        (category) => category.id === item.video_category_id
      );
      if (_index !== -1) {
        categoryName = categories[_index].category_name;
      }
    }
    return (
      <div className={cx("resource_card")} key={index}>
        <a
          href="/#"
          className={cx("resource_card__wrapper")}
          onClick={(e) => {
            e.preventDefault();

            refreshTrackClickForAnalyticsAPI("video", item.id, users.school_id);

            Mixpanel.track("Resources clicked", {
              id: item.id,
            });

            ["Interstride Video", "YouTube Video", "PDF"].includes(
              item.link_type
            )
              ? modelHandler(
                  true,
                  item.link_type,
                  item.image,
                  item.link,
                  item.title
                )
              : window.open(
                  item.link_type === "Website Link" ? item.link : item.image
                );
          }}
        >
          <CardCustom>
            <div className={cx("resource_card__wrapper__content")}>
              <div className={cx("resource_card__wrapper__content__image")}>
                {item.link_type === "Interstride Video" ? (
                  <>
                    <img
                      className={cx(
                        "resource_card__wrapper__content__image__primary"
                      )}
                      src={item.interstride_video_thumb_image}
                      alt="Video thumbnail"
                    />
                    <div
                      className={cx(
                        "resource_card__wrapper__content__image__secondary"
                      )}
                    >
                      <img src={BigPlayIcon} alt="play-icon" />
                    </div>
                  </>
                ) : item.link_type === "YouTube Video" && item.image ? (
                  <img src={item.image} alt="play-icon" />
                ) : item.link_type === "YouTube Video" && !item.image ? (
                  <img
                    style={{
                      width: "72px",
                      height: "72px",
                    }}
                    src={NewPlayIcon}
                    alt="play-icon"
                  />
                ) : item.link_type === "Image" &&
                  item.image &&
                  item.image !== "" ? (
                  <img src={item.image} alt={item.link_type} />
                ) : item.link_type === "Website Link" && item.image ? (
                  <img src={item.image} alt={item.link_type} />
                ) : item.link_type === "Website Link" && !item.image ? (
                  <img
                    style={{
                      width: "72px",
                      height: "72px",
                    }}
                    src={NewWebIcon}
                    alt={item.link_type}
                  />
                ) : item?.link_type === "PDF" &&
                  item?.interstride_video_thumb_image ? (
                  <img
                    src={item?.interstride_video_thumb_image}
                    alt={item.link_type}
                  />
                ) : (
                  <img
                    style={{
                      width: "72px",
                      height: "92px",
                    }}
                    alt={item.link_type}
                    src={
                      item.link_type === "PDF"
                        ? NewDocIcon
                        : item.link_type === "Document"
                        ? docIcon
                        : item.link_type === "Excel Sheet"
                        ? xlsIcon
                        : Missing_Video_Icon
                    }
                  />
                )}
              </div>
              <div className={cx("resource_card__wrapper__content__title")}>
                <span>{item.title}</span>
              </div>
              <div
                className={cx("resource_card__wrapper__content__description")}
              >
                <span>{item.description}</span>
              </div>
              <div className={cx("resource_card__wrapper__content__school")}>
                {item?.posted_by_super_admin ? (
                  <div
                    className={cx(
                      "resource_card__wrapper__content__school__super-admin"
                    )}
                  >
                    <img src={INTERSTRIDE_EMBLEM} alt="Interstride" />
                    <span>Interstride upload</span>
                  </div>
                ) : (
                  <div
                    className={cx(
                      "resource_card__wrapper__content__school__school-admin"
                    )}
                  >
                    School upload
                  </div>
                )}
              </div>
              <div className={cx("resource_card__wrapper__content__footer")}>
                <div
                  className={cx(
                    "resource_card__wrapper__content__footer__date"
                  )}
                >
                  <span>{moment(item.date).format("MMMM DD, YYYY")}</span>
                </div>
                <div
                  className={cx(
                    "resource_card__wrapper__content__footer__type"
                  )}
                >
                  {item.link_type}
                </div>
              </div>
            </div>
          </CardCustom>
        </a>
        <div
          className={cx([
            "resource_card__star",
            isResourceFavorite ? "resource_card__star--active" : "",
            // eslint-disable-next-line eqeqeq
            accessibilityHelp == "true" ? "resource_card__showActions" : "",
          ])}
        >
          {isResourceFavorite ? (
            <StarIcon
              aria-label={`Remove ${item.title} from saved resources`}
              variant="filled"
              onClick={() => handleFavorite(item.id)}
            />
          ) : (
            <StarIcon
              aria-label={`Add ${item.title} to saved resources`}
              onClick={() => handleFavorite(item.id)}
            />
          )}
        </div>
        {categoryName && (
          <div className={cx("resource_card__label")}>
            <span>{categoryName}</span>
          </div>
        )}
      </div>
    );
  };

  // JSX Element for reusbale resource card loader
  const resourceCardLoaders = () =>
    [...Array(4)].map((_item, index) => (
      <ShimmerThumbnail key={index} width={255} height={297} rounded />
    ));

  // JSX Element for reusable placeholder card in case of of no saved resources
  const noSavedResourcePlaceholder = () => (
    <div className={cx("resources__no-saved-resources")}>
      <img src={NoResourceIllustration} alt="no Categories" />
      <h3 className={cx("resources__no-saved-resources__heading")}>
        You haven't saved any resources yet.
      </h3>
    </div>
  );

  // JSX Element for reusable placeholder card in case of of no resources
  const noResourcePlaceholder = () => (
    <div className={cx("resources__no-saved-resources")}>
      <CardCustom>
        <div className={cx("resources__no-saved-resources__heading")}>
          Oops, no resources found.
        </div>
      </CardCustom>
    </div>
  );

  const handleSavedResourcesFilterClick = (item) => {
    setSavedResourcesFilter(item);
    savedResourcesAPICall(searchText, item);
  };

  return (
    <div className={cx("resources")}>
      <div className={cx("resources__header")}>
        <h1>
          {page === pages[0]
            ? "Explore our Resources"
            : page === pages[1]
            ? selectedCategory.category_name
            : "Your Saved Resources"}
        </h1>
      </div>
      {page === pages[0] ? (
        <>
          <div className={cx("resources__search")}>
            <div className={cx("resources__search__input")}>
              <TextboxCustom
                onEnterKey={handleSearch}
                name="search"
                placeholder="Search resources"
                lableaignment="left"
                className="clearMe custom-textbox"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>
            <div className={cx("resources__search__controls")}>
              <a
                className="no-outline"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <img src={SearchIcon} alt="Search" />
              </a>
              {searchText && (
                <a
                  className={cx("resources__search__controls__clear")}
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClearSearch();
                  }}
                >
                  <span>Clear all</span>
                </a>
              )}
            </div>
          </div>
          <div className={cx("resources__body")}>
            <div className={cx("resources__body__categories")}>
              {categoriesLoader ? (
                <div className={cx("resources__body__categories__loader")}>
                  {[...Array(4)].map((_item, index) => (
                    <ShimmerThumbnail
                      key={index}
                      width={255}
                      height={223}
                      rounded
                    />
                  ))}
                </div>
              ) : categories.length === 0 ? (
                <div className={cx("resources__body__categories__placeholder")}>
                  <h3>We don't have any resource listed right now.</h3>
                  <img src={NoCategoriesIcon} alt="NoCategories" />
                </div>
              ) : (
                <div className={cx("resources__body__categories__content")}>
                  {categories.map((item, index) => (
                    <div
                      className={cx(
                        "resources__body__categories__content__card"
                      )}
                      key={index}
                    >
                      <CardCustom
                        onClick={() => navigationHandler(pages[1], item)}
                      >
                        <div
                          className={cx(
                            "resources__body__categories__content__card__item"
                          )}
                        >
                          <div
                            className={cx(
                              "resources__body__categories__content__card__item__image"
                            )}
                          >
                            <img
                              src={item.logo_url || PlaceholderCategoryImage}
                              alt="category"
                            />
                          </div>
                          <span>{item.category_name}</span>
                        </div>
                      </CardCustom>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {savedResources.length > 0 && (
              <div className={cx("resources__body__saved")}>
                <div className={cx("resources__body__saved__title")}>
                  <h2>Your saved resources</h2>
                  <div
                    className={cx("resources__body__saved__title__view-all")}
                  >
                    <span onClick={() => navigationHandler(pages[2], null)}>
                      View all (
                      {savedResourcesLoader ? "Loading" : savedResources.length}
                      )
                    </span>
                  </div>
                </div>
                <div className={cx("resources__body__saved__body")}>
                  {savedResourcesLoader ? (
                    resourceCardLoaders()
                  ) : savedResources.length > 0 ? (
                    <>
                      {savedResources.map((item, index) =>
                        resourceCard(item, index)
                      )}
                    </>
                  ) : (
                    noSavedResourcePlaceholder()
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={cx("resources__navigation")}>
            <a
              href="/#"
              className={cx([
                "resources__navigation__back-to-categories",
                page === pages[2]
                  ? "resources__navigation__back-to-categories--saved"
                  : "",
              ])}
              onClick={(e) => {
                e.preventDefault();
                if (previousPage === pages[0]) {
                  navigationHandler(pages[0]);
                } else {
                  navigationHandler(pages[1], selectedCategory);
                }
              }}
            >
              <img src={backArrowV2} alt="back-arrow" />
              <span>{page === pages[1] ? "Back to Resources" : "Back"}</span>
            </a>
            {page === pages[1] && savedResources?.length > 0 && (
              <a
                href="/#"
                className={cx("resources__navigation__to-saved-service")}
                onClick={(e) => {
                  e.preventDefault();
                  navigationHandler(pages[2], null);
                }}
              >
                <img src={savePinIcon} alt="save-pin" />
                <span>Your saved resources ({savedResources.length})</span>
                <img src={arrowRight} alt="arrow-right" />
              </a>
            )}
          </div>
          {(page === pages[1] || page === pages[2]) && (
            <div className={cx("resources__filters")}>
              {page === pages[2] && (
                <div className={cx("resources__filters__search")}>
                  <div className={cx("resources__filters__search__input")}>
                    <TextboxCustom
                      onEnterKey={handleSearchSavedResources}
                      name="search"
                      placeholder="Search saved resources"
                      lableaignment="left"
                      className="clearMe custom-textbox"
                      value={searchText}
                      onChange={(event) => setSearchText(event.target.value)}
                    />
                  </div>
                  <div className={cx("resources__filters__search__controls")}>
                    <a
                      href="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearchSavedResources();
                      }}
                    >
                      <img src={SearchIcon} alt="Search" />
                    </a>
                    {(searchText || savedResourcesFilter) && (
                      <div
                        className={cx(
                          "resources__filters__search__controls__clear"
                        )}
                      >
                        <a href="/#" onClick={handleClearFilterType}>
                          Clear all
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {page === pages[2] &&
                !savedResourcesLoader &&
                savedResourcesFilterOptions?.length > 0 && (
                  <div className={cx("resources__filters__type")}>
                    <div className={cx("resources__filters__type__items")}>
                      <div
                        className={cx(
                          "resources__filters__type__items__heading"
                        )}
                      >
                        <span>Filter</span>
                      </div>
                      {savedResourcesFilterOptions.map((item, index) => (
                        <a
                          href="/#"
                          className={cx([
                            "resources__filters__type__items__item",
                            savedResourcesFilter === item?.value
                              ? "resources__filters__type__items__item--active"
                              : "",
                          ])}
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSavedResourcesFilterClick(item?.value);
                          }}
                        >
                          <span>{item?.label}</span>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              {page === pages[1] &&
                !resourcesLoader &&
                selectedCategoryResources.length !== 0 && (
                  <>
                    <div className={cx("resources__filters__type")}>
                      <div className={cx("resources__filters__type__items")}>
                        <div
                          className={cx(
                            "resources__filters__type__items__heading"
                          )}
                        >
                          <span>Filter</span>
                        </div>
                        {resourceFilterOptions.map((item, index) => (
                          <a
                            href="/#"
                            className={cx([
                              "resources__filters__type__items__item",
                              resourceFilter &&
                              resourceFilter.value === item.value
                                ? "resources__filters__type__items__item--active"
                                : "",
                            ])}
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilterType(item);
                            }}
                          >
                            <span>{item.label}</span>
                          </a>
                        ))}
                        {(resourceFilter !== null || searchText) && (
                          <div
                            className={cx(
                              "resources__filters__type__items__clear-all"
                            )}
                          >
                            <span onClick={handleClearFilterType}>
                              Clear all
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={cx("resources__filters__sort")}>
                      <DropdownCustom
                        defaultValue={resourceSort}
                        name="resourcesSort"
                        options={resourcesSortOptions}
                        value={resourcesSortOptions.find(
                          (item) => item.value === resourceSort
                        )}
                        justifyContent="left"
                        onChange={(e) => {
                          setResourceSort(e.value);
                          resourcesAPICall(
                            selectedCategory.id,
                            e.value,
                            resourceFilter?.value
                          );
                        }}
                      />
                    </div>
                  </>
                )}
            </div>
          )}
          <div className={cx("resources__listing")}>
            {page === pages[1] && resourcesLoader ? (
              <div className={cx("resources__listing__loaders")}>
                {resourceCardLoaders()}
              </div>
            ) : page === pages[1] && !resourcesLoader ? (
              <div className={cx("resources__listing__cards")}>
                {selectedCategoryResources.length === 0
                  ? noResourcePlaceholder()
                  : selectedCategoryResources.map((item, index) =>
                      resourceCard(item, index)
                    )}
              </div>
            ) : (
              <div className={cx("resources__listing__cards")}>
                {savedResourcesLoader ? (
                  resourceCardLoaders()
                ) : savedResources.length === 0 ? (
                  <div>{noSavedResourcePlaceholder()}</div>
                ) : (
                  <>
                    {savedResources.map((item, index) =>
                      resourceCard(item, index)
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {/* Media Player/Viewer Modal Start */}
      {state.showModel && (
        <CustomDialog
          className="resource-dialog"
          marginTop={10}
          // width={450}
          open={state.showModel}
          title={
            state.title.length > 25
              ? state.title.slice(0, 25) + "..."
              : state.title
          }
          dialogTitleClass="modal-title"
          handleClose={() => modelHandler()}
        >
          <div className="model-body">
            {state.link_type === "PDF" ? (
              <div className="PDF-Wrap">
                {state.image !== "" ? (
                  <iframe
                    // height="530"
                    aria-label="PDF"
                    src={state.image}
                    title="none"
                  ></iframe>
                ) : (
                  <h3>Oops, PDF is not available</h3>
                )}
              </div>
            ) : null}

            {state.link_type === "YouTube Video" && (
              <div className="player-wrapper-yt">
                {state.link ? (
                  <ReactPlayer
                    className="react-player"
                    url={state.link && state.link}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <h3>{`Oops, ${state.link_type} is not available`}</h3>
                )}
              </div>
            )}

            {state.link_type === "Interstride Video" && (
              <div className="content interstride-video-Wrap">
                {state.image ? (
                  <video
                    controls
                    // eslint-disable-next-line eqeqeq
                    autoPlay={accessibilityHelp == "true" ? false : true}
                    muted
                    controlsList="nodownload"
                  >
                    <source src={state.image && state.image} />
                  </video>
                ) : (
                  <h3>{`Oops, ${state.link_type} is not available`}</h3>
                )}
              </div>
            )}
          </div>
        </CustomDialog>
      )}
      {/* Media Player/Viewer Modal End */}
    </div>
  );
}
