import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import HelpDashboardStyles from "./HelpDashboardStyles";
import BlueStarEmptyIcon from "../../../assets/svg/f-unclicked-star.svg";
import BlueStarFillIcon from "../../../assets/svg/f-clicked-star.svg";
import { FAQ } from "../../../constant/mockdata";
import usePromise from "../../../hooks/usePromise/usePromise";
import { toastify, validateAttribute } from "../../../helper/helper";
import HelpCategory from "../../../components/HelpCenter/Category/Category";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import CustomDropzone from "../../../components/CustomDropzone/CustomDropzone";
import Subscription from "../../../components/Subscription/Subscription";
import Feedback from "../../../components/HelpCenter/GettingStarted/Feedback";
import MiddlePage from "../../../components/HelpCenter/MiddlePage/MiddlePage";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import CustomRating from "../../../components/CustomRating/CustomRating";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import ContactCareerCenter from "./ContactCareerCenter/ContactCareerCenter";

import {
  sendFeedBackAPI,
  contactUsAPI,
  getContactCenterDetails,
} from "../../../services/helpCenterServices";
import { FormattedPhoneNumber } from "../../../components/FormattedPhoneNumber/FormattedPhoneNumber";

const INITIAL_STATE_CONTACT_CAREER = {
  apiLoading: false,
  showModel: false,
  inquiryText: "",
  uploadedImage: null,
  data: null,
};

const HelpDashboard = () => {
  const { permissions = {} } = useSelector((store) => store.userStore);
  const [selectedFAQ, setSelectedFQA] = useState(FAQ[0]);
  const [expandStaus, resetExapanded] = useState(false);
  const [showModel, SetModel] = useState(false);
  const [showModel2, SetModel2] = useState(false);
  const [showModel3, SetModel3] = useState(false);
  const [currentRate, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [point, setPoint] = useState(0);
  const [contactUs, setContactUs] = useState({
    issueType: null,
    issue: "",
  });
  const [issueImage, setIssueImage] = useState(null);
  const [stateContactCareer, setStateContactCareer] = useState(
    INITIAL_STATE_CONTACT_CAREER
  );

  const [callingSendFeedBackAPI, refreshSendFeedBackAPI] =
    usePromise(sendFeedBackAPI);
  const [callingContactUsAPI, refreshContactUsAPI] = usePromise(contactUsAPI);

  const filteredFAQs = FAQ.filter(
    ({ permission = true }) =>
      permission === true || permissions[permission] === true
  ).map((faq) => {
    const filteredQAs =
      faq.QA && faq.QA.length > 0
        ? faq.QA.filter(
            ({ permission = true }) =>
              permission === true || permissions[permission] === true
          )
        : [];

    if (faq.id === 8) {
      //Last category of misc. modules
      const toolsPermission =
        permissions["gameplan"] || permissions["checklist"];

      let category =
        (permissions["webinar"] &&
          permissions["pastOrUpcomingWebinarsAvailable"]) ||
        permissions["resources"] ||
        toolsPermission
          ? permissions["webinar"] &&
            permissions["pastOrUpcomingWebinarsAvailable"] &&
            permissions["resources"] &&
            toolsPermission
            ? "Webinar, resources, & tools"
            : permissions["webinar"] &&
              permissions["pastOrUpcomingWebinarsAvailable"] &&
              permissions["resources"]
            ? "Webinar, resources, & others"
            : permissions["webinar"] &&
              permissions["pastOrUpcomingWebinarsAvailable"] &&
              toolsPermission
            ? "Webinar & tools"
            : toolsPermission
            ? "Tools & other"
            : ""
          : "Learn & tools";

      return { ...faq, QA: filteredQAs, category };
    } else return { ...faq, QA: filteredQAs };
  });

  const ModelHandler = (status) => {
    SetModel(status);
    if (!status) {
      setRating(0);
      setPoint(0);
      setComment("");
    }
  };

  const handleRating = (value) => {
    setRating(value);
  };
  const hanldeChange = (e) => {
    setComment(e.target.value);
  };
  const handlePoint = (p) => {
    setPoint(p);
  };

  const handleSubmit = () => {
    const payload = {
      send_feedback: {
        rate: currentRate,
        comment: comment,
        recommended_rate: point,
      },
    };
    if (currentRate > 0 && point > 0 && comment !== "") {
      refreshSendFeedBackAPI(payload);
    } else {
      toastify("error", "Please complete all the fields.");
    }
  };

  const handleFAQ = (faq) => {
    setSelectedFQA({ ...faq });
    resetExapanded(false);
  };

  const contactDialog = () => {
    SetModel2(!showModel2);
    setIssueImage(null);
    setContactUs({
      issueType: null,
      issue: "",
    });
  };

  const handleChange = (value, name) => {
    setContactUs({
      ...contactUs,
      [name]: value,
    });
  };

  const handleCallback = (file) => {
    file.forEach((item) => {
      if (["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        setIssueImage(item);
      } else {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      }
    });
  };

  const contactCareerImageUpload = (file) => {
    file.forEach((item) => {
      if (["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        setStateContactCareer((current) => ({
          ...current,
          uploadedImage: item,
        }));
      } else {
        toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      }
    });
  };

  const sendReport = () => {
    const formData = new FormData();
    formData.append("contact_us[topic]", contactUs.issueType);
    formData.append("contact_us[comment]", contactUs.issue);
    issueImage && formData.append("contact_us[issue_image]", issueImage);
    refreshContactUsAPI(formData);
  };

  const handleSubmitContactCareer = async () => {
    // Validation
    if (!stateContactCareer.inquiryText) {
      toastify("error", "Please enter a comment");
      return;
    }
    setStateContactCareer((current) => ({
      ...current,
      apiLoading: true,
    }));
    const formData = new FormData();
    formData.append("contact_us[comment]", stateContactCareer.inquiryText);
    formData.append("contact_us[contact_career_center]", true);
    stateContactCareer.uploadedImage &&
      formData.append(
        "contact_us[issue_image]",
        stateContactCareer.uploadedImage
      );
    try {
      const response = await contactUsAPI(formData);
      if (response.success) {
        toastify("success", response.message);
        setStateContactCareer((current) => ({
          ...INITIAL_STATE_CONTACT_CAREER,
          data: current.data,
        }));
      } else {
        toastify("error", response.message);
        setStateContactCareer((current) => ({
          ...current,
          apiLoading: false,
        }));
      }
    } catch (error) {
      setStateContactCareer((current) => ({
        ...current,
        apiLoading: false,
      }));
    }
  };

  useEffect(() => {
    if (callingSendFeedBackAPI.hasFetched()) {
      if (callingSendFeedBackAPI.hasErrors()) {
        toastify("error", "Something went wrong. Please try again.");
      } else {
        SetModel(false);
        setRating(0);
        setPoint(0);
        setComment("");
        SetModel3(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingSendFeedBackAPI.isFetching()]);

  useEffect(() => {
    if (callingContactUsAPI.hasFetched()) {
      if (callingContactUsAPI.hasErrors()) {
        toastify("error", "Something went wrong. Please try again.");
      } else {
        toastify("success", callingContactUsAPI.data().message);
        contactDialog();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingContactUsAPI.isFetching()]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getContactCenterDetails();
        if (response.status === 200 && response.data) {
          setStateContactCareer((current) => ({
            ...current,
            data: response.data,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <HelpDashboardStyles>
      <div className="started-mainpage">
        <div className="resource-section">
          <h1 className="f-main-title mb-30">Help Center</h1>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <HelpCategory
                catList={filteredFAQs}
                ActiveFAQID={selectedFAQ.id}
                handleFAQ={handleFAQ}
              />
            </Grid>
            <Grid item xs={6}>
              <MiddlePage
                data={selectedFAQ.QA}
                ActiveFAQID={selectedFAQ.id}
                name={selectedFAQ.category}
                expandStaus={expandStaus}
              />
              {stateContactCareer.data && (
                <div className="ShowResponsiveDiv">
                  <ContactCareerCenter data={stateContactCareer.data} />
                </div>
              )}
              <div className="ShowResponsiveDiv">
                <Subscription
                  title={"Still got questions?"}
                  subTitle1={
                    "We’re here to clear up any questions you may have about the platform."
                  }
                  btnName={"Contact Interstride"}
                  subTitle2={""}
                  showCloseBtn={false}
                  contactUs={contactDialog}
                />
              </div>
              <div className="ShowResponsiveDiv">
                <Feedback handleModel={ModelHandler} />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="resource-section__right-section-top-spacer" />
              {stateContactCareer.data && (
                <ContactCareerCenter
                  data={stateContactCareer.data}
                  setStateContactCareer={setStateContactCareer}
                />
              )}
              <Subscription
                title={"Still got questions?"}
                subTitle1={
                  "We’re here to clear up any questions you may have about the platform."
                }
                btnName={"Contact Interstride"}
                subTitle2={""}
                showCloseBtn={false}
                contactUs={contactDialog}
              />
              <Feedback handleModel={ModelHandler} />
            </Grid>
            {showModel && (
              <div className="feedback-model">
                <CustomDialog
                  className="feedback-dialog"
                  open={showModel}
                  title="We’d love to hear your feedback"
                  dialogTitleClass="modal-title"
                  handleClose={() => ModelHandler(!showModel)}
                >
                  <div className="model-body">
                    <div className="rating-box">
                      <div className="sub-header-primary">
                        Give us a rating*
                      </div>
                      <div className="custom-rating">
                        <CustomRating
                          start={0}
                          stop={5}
                          emptySymbol={
                            <img src={BlueStarEmptyIcon} alt="Non-rated star" />
                          }
                          fullSymbol={
                            <img src={BlueStarFillIcon} alt="Rated star" />
                          }
                          fractions={1}
                          value={currentRate}
                          onClick={(rate) => handleRating(rate)}
                          type="stars"
                        />
                      </div>
                    </div>
                    <div className="about-box">
                      <TextboxCustom
                        name="about"
                        placeholder="We'd love to hear more from you. What did you like? What could use improvement? Any new features you'd like to see in the future? We're all ears!"
                        isMultiline={true}
                        value={comment}
                        onChange={hanldeChange}
                        label={"Tell us more*"}
                        lableAignMent="left"
                        className="custom-textarea placeholderColor"
                        maxLength={1000}
                      />
                    </div>
                    <div className="friend-refer">
                      <div className="sub-header-primary">
                        Would you recommend Interstride to a friend?*
                      </div>
                      <div className="points-rating-wrapper">
                        <CustomRating
                          start={0}
                          stop={10}
                          fractions={1}
                          value={point}
                          onClick={(rate) => handlePoint(rate)}
                          type="points"
                          highlightSelectedOnly
                          aria-describedby="ratingDescMin ratingDescMax"
                        />
                      </div>
                      <div className="like">
                        <div className="sub-header-primary" id="ratingDescMin">
                          Not Likely
                        </div>
                        <div
                          className="sub-header-primary ml-auto text-right"
                          id="ratingDescMax"
                        >
                          Extremely likely
                        </div>
                      </div>
                    </div>
                    <div className="btn">
                      <ButtonCustom
                        width="248"
                        onClick={() => handleSubmit()}
                        isDisabled={
                          currentRate < 1 || comment === "" || point < 1
                        }
                        isSubmitting={callingSendFeedBackAPI.isFetching()}
                      >
                        Submit Feedback
                      </ButtonCustom>
                    </div>
                    <div className="text-center">
                      <a
                        href="/#"
                        className="ngray-link"
                        onClick={(e) => {
                          e.preventDefault();
                          ModelHandler(!showModel);
                        }}
                        aria-label="cancel"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </CustomDialog>
              </div>
            )}

            {showModel3 && (
              <div className="feedback-model">
                <CustomDialog
                  className="feedback-dialog"
                  open={showModel3}
                  title="Thanks for sharing your feedback!"
                  handleClose={() => ModelHandler(!showModel3)}
                >
                  <div className="model-body">
                    <div className="feedback-reply">
                      <div className="sub-header-primary">
                        We value your opinion and will review your feedback
                        soon.
                      </div>
                    </div>
                    <div className="btn thankyou-btn">
                      <ButtonCustom
                        width="124"
                        onClick={() => SetModel3(!showModel3)}
                        className="got-it"
                      >
                        Got It!
                      </ButtonCustom>
                    </div>
                  </div>
                </CustomDialog>
              </div>
            )}

            {showModel2 && (
              <div className="conatct-us-model">
                <CustomDialog
                  className="feedback-dialog"
                  open={showModel2}
                  title="Contact us"
                  subTitle={
                    <div>
                      Describe the issue with all the details, or connect with
                      us at the{" "}
                      <a
                        href="mailto:support@interstride.com"
                        className="link-focus"
                      >
                        support@interstride.com
                      </a>{" "}
                      email address.
                    </div>
                  }
                  handleClose={() => contactDialog()}
                >
                  <div className="model-body">
                    <div className="issueType">
                      <DropdownCustom
                        name="issueType"
                        label="Select an issue"
                        placeholder="Select topic"
                        justifyContent="center"
                        placeholderColor="true"
                        options={[
                          { value: "1", label: "Technical support" },
                          { value: "2", label: "Interstride services" },
                          { value: "3", label: "Report a bug" },
                          { value: "4", label: "Other issues" },
                        ]}
                        onChange={(obj) => handleChange(obj.label, "issueType")}
                        required={true}
                        className={contactUs.issueType ? "showValueBorder" : ""}
                      />
                    </div>
                    <div className="about-box">
                      <TextboxCustom
                        name="about"
                        placeholder="Describe the problem here. Please share details about your device and browser to speed up the process."
                        isMultiline={true}
                        value={contactUs.issue}
                        onChange={(e) => handleChange(e.target.value, "issue")}
                        label={"Tell us more"}
                        lableAignMent="left"
                        className={`custom-textarea ${
                          contactUs.issue ? "showValueBorder" : ""
                        }`}
                        required={true}
                      />
                    </div>
                    <div className="file-attch">
                      <CustomDropzone
                        text="Upload an image"
                        handleUploadFile={handleCallback}
                        vallidFiles="image/*"
                        showDeleteIcon={issueImage ? true : false}
                        fileName={issueImage ? issueImage.name : ""}
                        removeUploadFile={() => setIssueImage(null)}
                      />
                    </div>
                    <div className="btn">
                      <ButtonCustom
                        onClick={() => sendReport()}
                        isDisabled={
                          contactUs.issueType === null || contactUs.issue === ""
                        }
                        isSubmitting={callingContactUsAPI.isFetching()}
                      >
                        Submit
                      </ButtonCustom>
                    </div>{" "}
                    <div className="text-center">
                      <a
                        href="/#"
                        className="ngray-link"
                        onClick={(e) => {
                          e.preventDefault();
                          contactDialog();
                        }}
                        aria-label="cancel"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </CustomDialog>
              </div>
            )}

            {stateContactCareer.showModel && (
              <div className="contact-career-model">
                <CustomDialog
                  width={480}
                  open={stateContactCareer.showModel}
                  title="Contact your Career Center"
                  handleClose={() =>
                    setStateContactCareer((current) => ({
                      ...INITIAL_STATE_CONTACT_CAREER,
                      data: current.data,
                    }))
                  }
                >
                  <div className="contact-career-model__body">
                    {validateAttribute(
                      stateContactCareer.data?.availability
                    ) && (
                      <>
                        <p className="contact-career-model__body__mt-1">
                          Availability
                        </p>
                        <p className="contact-career-model__body__mb-1">
                          {stateContactCareer.data?.availability}
                        </p>
                      </>
                    )}

                    {validateAttribute(
                      stateContactCareer.data?.phone_numbers
                    ) && (
                      <>
                        <p>Phone number:</p>
                        <p className="contact-career-model__body__mb-1">
                          <FormattedPhoneNumber
                            number={
                              stateContactCareer?.data?.country_code +
                              stateContactCareer?.data?.phone_numbers
                            }
                          />
                        </p>
                      </>
                    )}

                    {validateAttribute(
                      stateContactCareer.data?.career_center_email
                    ) && (
                      <>
                        <p>Email:</p>
                        <p className="contact-career-model__body__mb-1">
                          {stateContactCareer.data?.career_center_email}
                        </p>
                      </>
                    )}
                    <TextboxCustom
                      name="inquiry"
                      placeholder="Describe the inquiry here."
                      isMultiline={true}
                      value={stateContactCareer.inquiryText}
                      onChange={(e) =>
                        setStateContactCareer((current) => ({
                          ...current,
                          inquiryText: e.target.value,
                        }))
                      }
                      label="Send an email*"
                      lableAignMent="center"
                      className="custom-textarea"
                    />
                    <div className="contact-career-model__body__upload">
                      <CustomDropzone
                        text="Upload an image"
                        handleUploadFile={contactCareerImageUpload}
                        vallidFiles="image/*"
                        showDeleteIcon={
                          stateContactCareer.uploadedImage ? true : false
                        }
                        fileName={
                          stateContactCareer.uploadedImage
                            ? stateContactCareer.uploadedImage?.name
                            : ""
                        }
                        removeUploadFile={() =>
                          setStateContactCareer((current) => ({
                            ...current,
                            uploadedImage: null,
                          }))
                        }
                      />
                    </div>
                    <div className="contact-career-model__body__submit">
                      <ButtonCustom
                        width={238}
                        height={50}
                        onClick={handleSubmitContactCareer}
                        isSubmitting={stateContactCareer.apiLoading}
                      >
                        Submit
                      </ButtonCustom>
                    </div>
                    <div className="contact-career-model__body__cancel">
                      <p
                        onClick={() =>
                          setStateContactCareer((current) => ({
                            ...INITIAL_STATE_CONTACT_CAREER,
                            data: current.data,
                          }))
                        }
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </CustomDialog>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </HelpDashboardStyles>
  );
};

export default HelpDashboard;
