import React, { useState, useEffect } from "react";
import { Grid, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EmployerJobsListStyles from "./EmployerJobsListStyles";
import CardCustom from "../../CardCustom/CardCustom";
import { getEmployerJobsAPI } from "../../../services/EmployerJobServices";
import { allNotificationsCountAPI } from "../../../services/notificationServices";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  capitalizeFirstLetter,
  processJobLocationTime,
} from "../../../helper/helper";
import Loader from "../../Loader/Loader";
import EmptyList from "../../EmptyList/EmptyList";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import { setSelectedJob } from "../../../redux/reducers/jobReducer";
import { setAllNotifications } from "../../../redux/reducers/notificationReducer";

const EmployerJobsList = () => {
  const { users = {} } = useSelector((store) => store.userStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openJobDetails = (data) => {
    dispatch(setSelectedJob(data));
    navigate(`/jobs/job-details/${data?.permalink ?? ""}`);
  };

  const [employerJobsList, setEmployerJobsList] = useState([]);
  const [callEmployerJobsAPI, refreshEmployerJobsAPI] =
    usePromise(getEmployerJobsAPI);

  useEffect(() => {
    refreshEmployerJobsAPI(users?.id, "all");
    refreshAllNotificationsCount();
  }, []);

  useEffect(() => {
    if (
      callEmployerJobsAPI.hasFetched() &&
      callEmployerJobsAPI.hasErrors() === false
    ) {
      if (
        callEmployerJobsAPI.data() &&
        callEmployerJobsAPI.data().data &&
        callEmployerJobsAPI.data().data.employer_jobs
      ) {
        setEmployerJobsList(callEmployerJobsAPI.data().data.employer_jobs);
      }
    }
  }, [callEmployerJobsAPI.isFetching()]);

  const refreshAllNotificationsCount = () => {
    setTimeout(async () => {
      try {
        const response = await allNotificationsCountAPI();
        if (response?.success) {
          // Update Persist State
          dispatch(setAllNotifications(response?.data));
        }
      } catch (error) {
        console.error(error);
      }
    }, 2000);
  };

  const renderEmployerJobs = (data, i) => {
    let websiteUrl = data.url;
    if (data.details === "url" && !/^https?:\/\//i.test(websiteUrl)) {
      websiteUrl = "http://" + websiteUrl;
    }
    return (
      <Grid item xs={12} key={`employer-job-${i}`}>
        <CardCustom>
          <div className="search-item employer-job">
            <div className="avtar">
              {data.logo.url && data.logo.url !== null ? (
                <Avatar alt="Logo" src={data.logo.url} />
              ) : (
                <InitialAvatar title={data.name} index={i} initialCount={1} />
              )}
            </div>
            <div className="content">
              <div className="header-primary">
                <span>{capitalizeFirstLetter(data.title)}</span>
                {/* <span className="tag-item"> Featured Job </span> */}
              </div>
              <div className="sub-header-primary">{data.name}</div>
              <div className="text-grey">
                {processJobLocationTime({
                  city: data?.city_name,
                  state: data?.state_code,
                  country: !["United States", "USA", "US"].includes(
                    data?.country_name
                  )
                    ? data?.country_name
                    : null,
                  time: data?.postedon,
                })}
              </div>
            </div>
            {data.applicant_status && data.applicant_status !== null ? (
              <div className="apply-btn">
                <a className="nlink-btn bg-gray" aria-label="Already Applied">
                  Applied
                </a>
              </div>
            ) : (
              ""
            )}
            <div className="apply-btn">
              <a
                href={`jobs/job-details/${data?.permalink ?? ""}`}
                target="_blank"
                rel="noopener noreferrer"
                className="nlink-btn nbg-secondary"
                aria-label={`Apply for ${data.title}`}
                onClick={(e) => {
                  if (data.details !== "url") {
                    e.preventDefault();
                    openJobDetails({ ...data });
                  }
                }}
              >
                View Details
              </a>
            </div>
          </div>
        </CardCustom>
      </Grid>
    );
  };

  return (
    <EmployerJobsListStyles>
      <Grid
        container
        spacing={3}
        className="employer-jobs-list justify-content-center"
      >
        <Grid item xs={9}>
          <div>
            <div className="job-title mb-25">
              <h1 className="title-primary">Featured Jobs</h1>
            </div>
            {callEmployerJobsAPI.isFetching() ? (
              <Loader />
            ) : (
              <Grid container spacing={3}>
                {employerJobsList && employerJobsList.length > 0 ? (
                  employerJobsList.map((data, i) => renderEmployerJobs(data, i))
                ) : (
                  <EmptyList title={"No featured jobs are available"} />
                )}
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </EmployerJobsListStyles>
  );
};
export default EmployerJobsList;
