import React, { useState, useEffect } from "react";
import { includes } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import BookAppointmentStyles from "./BookMeetingStyles";
import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";

import usePromise from "../../../../hooks/usePromise/usePromise";
import { transformIntoLabels } from "../../../../helper/helper";
import { getMeetingPurposes } from "../../../../services/meetingPurposeServices";
import BookAppointmentSearch from "../../../../components/Tools/BookAppointment/BookAppointmentSearch/BookAppointmentSearch";
import {
  setAppointmentType,
  setMeetingPurposes,
} from "../../../../redux/reducers/meetReducer";

const BookMeeting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [callGetMeetingPurposes, refreshGetMeetingPurposes] =
    usePromise(getMeetingPurposes);
  const url = new URL(window.location.href);
  let bookingId = url.searchParams.get("bookingid");

  useEffect(() => {
    if (isRescheduled || bookingId) {
      setIsRescheduled(true);
    }
  }, [isRescheduled, bookingId]);

  useEffect(() => {
    refreshGetMeetingPurposes();
  }, []);

  useEffect(() => {
    if (
      callGetMeetingPurposes.hasFetched() &&
      !callGetMeetingPurposes.hasErrors()
    ) {
      let appointmentSettings =
        callGetMeetingPurposes.data().data.appointment_settings;
      let data = [];
      appointmentSettings.map((i) => {
        if (!includes(data, i.meeting_purpose_id)) {
          data.push(i.meeting_purpose_id);
        }
      });
      let meetingPurposes = transformIntoLabels(
        callGetMeetingPurposes.data().data.meeting_purposes,
        data
      );
      if (meetingPurposes.length === 1) {
        dispatch(setAppointmentType(meetingPurposes[0].value));
      }
      dispatch(setMeetingPurposes(meetingPurposes));
    }
  }, [callGetMeetingPurposes.isFetching()]);

  return (
    <BookAppointmentStyles>
      <div className="book-appointment-wrapper">
        <div className="f-main-title-with-button mb-30">
          <div className="back-btn-wrapper">
            <a
              className="shadow-btn"
              onClick={(e) => {
                e.preventDefault();
                navigate("/tools/meet");
              }}
              href="#"
              aria-label="Back"
            >
              <img
                src={BackArrow}
                id="BackArrow"
                width="18px"
                alt="Back arrow"
              />
              <span>Back</span>
            </a>
          </div>
          <h1 className="f-main-title mb-0 w-100">Book meeting</h1>
        </div>

        <BookAppointmentSearch
          sMode={!isRescheduled}
          bookingId={bookingId}
          setIsRescheduled={setIsRescheduled}
        />
      </div>
    </BookAppointmentStyles>
  );
};

export default BookMeeting;
