import React, { useEffect } from "react";
import {
  Grid,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MiddlePageStyles from "./MiddlePageStyles";
import CardCustom from "../../CardCustom/CardCustom";

const WelcomeVideoURL =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/welcome.mp4";

const WelcomeVideoCaptionURL =
  "https://interstride.s3.us-east-1.amazonaws.com/miscellaneous/assets/welcome.vtt";

// const StudentTrainingVideoURL = "https://interstride.s3.amazonaws.com/miscellaneous/assets/videos/Student+Training+Video+Final.mp4";
const StudentTrainingVideoURL =
  "https://interstride.s3.us-east-1.amazonaws.com/miscellaneous/assets/videos/Interstride-Student-Training-Help.mp4";

const MiddlePage = ({ data, ActiveFAQID, name, expandStatus }) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const [expanded, setExpanded] = React.useState(expandStatus);

  useEffect(() => {
    setExpanded(expandStatus);
  }, [ActiveFAQID]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderFQA = (item, i) => {
    return (
      <Accordion
        expanded={expanded === item.id}
        onChange={handleChange(item.id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`FAQ-${i}`}
          aria-label={item.question}
        >
          <Typography className="header-primary">{item.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <p
            className="text-justify text-color-gray"
            dangerouslySetInnerHTML={{ __html: item.answer }}
          />
          {ActiveFAQID === 2 && item.id === 1 && item.showVideo && (
            <div className="media">
              <video
                controls
                autoPlay={accessibilityHelp == "true" ? false : true}
                muted
              >
                <source src={WelcomeVideoURL} />
              </video>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <MiddlePageStyles>
      <div className="started-middle-page">
        <h1 className="title-primary mb-15">
          {name && name !== "Welcome Tutorial" ? `${name}` : "Welcome Tutorial"}
        </h1>
        <Grid item xs={12}>
          <div className={ActiveFAQID === 1 ? "faq-post" : "faq-section"}>
            {ActiveFAQID === 1 ? (
              <div>
                <CardCustom className="welcome-page">
                  <div className="media">
                    <video
                      controls
                      autoPlay={accessibilityHelp == "true" ? false : true}
                      muted
                    >
                      <source src={WelcomeVideoURL} />
                      <track
                        src={WelcomeVideoCaptionURL}
                        kind="captions"
                        srcLang="en"
                        label="English"
                        default
                      />
                    </video>
                  </div>
                </CardCustom>
                <CardCustom className="welcome-page">
                  <div className="header-primary">
                    Learn how to use the Interstride portal!
                  </div>
                  <div className="sub-header">
                    We host online trainings every month. To register for our
                    next online training, go to Learn {`> `}
                    <a href="https://student.interstride.com/learn/webinars">
                      Webinars
                    </a>
                  </div>
                  <div className="media">
                    <video controls muted>
                      <source src={StudentTrainingVideoURL} />
                    </video>
                  </div>
                </CardCustom>
              </div>
            ) : (
              data.map((item, i) => renderFQA(item, i))
            )}
          </div>
        </Grid>
      </div>
    </MiddlePageStyles>
  );
};

export default MiddlePage;
