import React, { useState, useMemo } from "react";
import { Grid, Tooltip, ClickAwayListener } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShimmerSocialPost } from "react-shimmer-effects";
import { useSelector } from "react-redux";

import { useAppState } from "../../../context";
import EmptyListWithImage from "../../EmptyListWithImage/EmptyListWithImage";
import TopicMiddleStyles from "./TopicMiddleStyles";
import HomeFeedEffect from "../../../components/effects/HomeFeedEffect/HomeFeedEffect";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CreatePost from "./CreatePost";
import PendingRequest from "./PendingRequest";
import TopicPost from "./TopicPost";
import SkipInfiniteContentLink from "../../SkipInfiniteContentLink/SkipInfiniteContentLink";

const TopicMiddle = ({
  acceptRejectRequest = () => {},
  setShowUserPopup = () => {},
  setActiveUser = () => {},
  callGetTopicFeedAPI = () => {},
  fetchTopicDetailsAPI = () => {},
  refreshGetAllSavedPosts = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const {
    activeTopicId,
    topicPosts,
    activeTopic,
    loadingTopicPosts,
    topicPendingRequest,
  } = useAppState("topic");
  const { users = {} } = useSelector((store) => store.userStore);
  const { id: userId } = users || {};

  const handlePendingReq = () => {
    setAnchorEl(!anchorEl);
  };

  const fetchMorePosts = () => {
    if (topicPosts?.type === "topic") {
      fetchTopicDetailsAPI(activeTopicId, topicPosts?.current_page + 1);
    } else if (topicPosts?.type === "feed") {
      callGetTopicFeedAPI(topicPosts?.current_page + 1);
    } else if (topicPosts.type === "all-saved") {
      refreshGetAllSavedPosts(null, topicPosts?.current_page + 1);
    } else if (topicPosts.type === "topic-saved") {
      refreshGetAllSavedPosts(activeTopicId, topicPosts?.current_page + 1);
    }
  };

  const LoadEffects = ({ count }) => {
    return Array.from(Array(count).keys()).map((item, index) => (
      <ShimmerSocialPost key={index} />
    ));
  };

  const topicName = useMemo(
    () =>
      topicPosts?.type === "feed"
        ? "Community feed"
        : topicPosts?.type === "topic"
        ? activeTopic?.topic_name
        : topicPosts?.type === "all-saved" || topicPosts?.type === "topic-saved"
        ? "Saved posts"
        : "",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topicPosts?.type]
  );

  return (
    <TopicMiddleStyles>
      <div className="middle-section">
        <div className="title-withbutton mb-15">
          {!loadingTopicPosts && (
            <>
              <Tooltip title={topicName} placement="bottom">
                <h1 className="title-primary d-flex justify-center align-center">
                  {topicName}
                </h1>
              </Tooltip>
              {activeTopicId &&
                topicPendingRequest?.length > 0 &&
                activeTopic?.created_by_id === userId && (
                  <div className="pending-request">
                    <ButtonCustom
                      width="175"
                      height="30"
                      onClick={handlePendingReq}
                    >{`${topicPendingRequest?.length} Pending requests`}</ButtonCustom>
                    {anchorEl && (
                      <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                        <PendingRequest
                          list={topicPendingRequest}
                          acceptRejectRequest={acceptRejectRequest}
                        />
                      </ClickAwayListener>
                    )}
                  </div>
                )}
            </>
          )}
        </div>
        <div className="posts">
          <Grid container spacing={3}>
            {!loadingTopicPosts &&
              activeTopicId &&
              topicPosts?.type === "topic" && (
                <Grid item xs={12}>
                  <CreatePost fetchTopicDetailsAPI={fetchTopicDetailsAPI} />
                </Grid>
              )}
            {loadingTopicPosts ? (
              <div style={{ padding: "10px", width: "100%" }}>
                <HomeFeedEffect />
              </div>
            ) : !loadingTopicPosts && topicPosts?.posts?.length === 0 ? (
              <Grid item xs={12}>
                <EmptyListWithImage
                  whoops={false}
                  title={"Looks like this Community does not have any posts"}
                />
              </Grid>
            ) : (
              <div className="topic-post-wrapper">
                <SkipInfiniteContentLink />
                <InfiniteScroll
                  dataLength={topicPosts?.posts?.length || 0}
                  next={fetchMorePosts}
                  hasMore={
                    topicPosts?.current_page !== topicPosts?.total_pages &&
                    topicPosts?.total_pages > 0
                  }
                  loader={
                    <Grid item xs={12}>
                      <LoadEffects count={2} />
                    </Grid>
                  }
                  style={{ overflow: "none" }}
                >
                  <>
                    {topicPosts?.posts?.map((topic, index) => {
                      return (
                        <Grid item xs={12} key={index} id={topic.id}>
                          <TopicPost
                            key={index}
                            topic={topic}
                            setShowUserPopup={setShowUserPopup}
                            setActiveUser={setActiveUser}
                            fetchTopicDetailsAPI={fetchTopicDetailsAPI}
                          />
                        </Grid>
                      );
                    })}
                  </>
                </InfiniteScroll>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </TopicMiddleStyles>
  );
};

export default TopicMiddle;
